//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// CheckoutPage - InteractLite  - Interact Menu
//                Version 1.001 - June 4, 2020
//                Version 1.981 - September 17, 2023 - Reformatted Cart Page and Payment Summary
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React,{ Component,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { withRouter } from './withRouter';
import { Popup } from '@progress/kendo-react-popup';
import { ListView,ListViewHeader,ListViewFooter } from '@progress/kendo-react-listview';
import { Form,Field,FormElement } from '@progress/kendo-react-form';
//import { SvgIcon } from '@progress/kendo-react-common';
import { Button } from '@progress/kendo-react-buttons';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { QRCode } from "@progress/kendo-react-barcodes";
import { Switch,MaskedTextBox } from "@progress/kendo-react-inputs";
import { BrowserMultiFormatReader } from '@zxing/library'; // Barcode Scanner
import GooglePayButton from '@google-pay/button-react';
import { SessionInfo } from './App';
import { CallOM,CallOMP,OMTrace,trace,displayMessage,displayWarning,displayError,SetNotify,GetFileExtension,CreateKeys,LogOffResetMsg,shortDesc,appendScript,getImageFileFromServer,xorEncode,isExpiryInvalid,getCardType,validateCard,UnmaskString,CTYP,TYP } from './CommonCode.js';
import { FormDatePicker,FormInput,FormMaskedTextBox,FormSwitch } from './FormComponents.js';
// https://github.com/medipass/react-credit-card-input?tab=readme-ov-file
//import CreditCardInput from 'react-credit-card-input';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
//import 'react-credit-cards/lib/styles.scss';
// https://github.com/medipass/react-payment-inputs?tab=readme-ov-file#requirements 
//import { PaymentInputsContainer } from 'react-payment-inputs';
//import images from 'react-payment-inputs/images'; // Note - see custom card images in https://github.com/medipass/react-payment-inputs?tab=readme-ov-file#requirements

//import {
//  FormNumericTextBox,
//  FormColorPicker,
//  FormSlider,
//  FormRangeSlider,
//  FormTextArea,
//  FormRating,
//} from "./form-components";
import { nameValidator,addressValidator,arrivalDateValidator,cardValidator,ccvValidator,expiryValidator,colorValidator,phoneValidator } from './FormValidators.js'
import { ENAppCnfg,ENLayoutCnfg } from './CommonInfo.js';

import useExternalScripts from "./useExternalScripts";
//import ScriptTag from 'react-script-tag';                  
import { ReactComponent as MenuBar } from "./images/bars.svg";
import { ReactComponent as ButtonDelete } from "./images/button-delete.svg";
//import { ReactComponent as ButtonClear } from "./images/button-clear.svg";
//import { ReactComponent as ButtonDelivered } from "./images/salad.svg";
import { ReactComponent as ButtonKitchen } from "./images/company-kitchen.svg";
import { ReactComponent as ButtonInProgress } from "./images/boiled-egg.svg"; // cooking
import { ReactComponent as ButtonDelivered } from "./images/wine-cups.svg"; // wineglass
import { ReactComponent as ButtonAmex } from "./images/american-express.svg";
import { ReactComponent as ButtonMC } from "./images/master-card.svg";
import { ReactComponent as ButtonVisa } from "./images/visa.svg";
import { ReactComponent as ButtonDiscover } from "./images/discover.svg";

import { ReactComponent as ButtonQRCode } from "./images/QRCode.svg";

//import * as customcheckout from 'https://libs.na.bambora.com/customcheckout/1/customcheckout.js';
//import { } from 'https://libs.na.bambora.com/customcheckout/1/customcheckout.js';
//import('https://libs.na.bambora.com/customcheckout/1/customcheckout.js').then(foo => trace(foo.default))
//<script src='https://libs.na.bambora.com/customcheckout/1/customcheckout.js'></script>

//------------------------- List Template ------------------------------------------------------------------ 

//------ Object Definition For CustomOrders -- Date: 2024-05-23-9:31 AM ------
const CustomOrdersOID = 545072;
const CustomOrdersO = [{ n: 'CustomOrderID',t: 195,p: 100 },{ n: 'StoreLocationID',t: 195,p: 100 },{ n: 'CustomerID',t: 195,p: 100 },{ n: 'CustomOrderParentID',t: 195,p: 100 },{ n: 'CustomOrderStatus',t: 201,p: 0 },{ n: 'CustomOrderType',t: 201,p: 0 },{ n: 'RequestDateTime',t: 35,p: 0 },{ n: 'ScheduledPrepDateTime',t: 35,p: 0 },{ n: 'PickupDeliveryDateTime',t: 35,p: 0 },{ n: 'TotalPrice',t: 28,p: 0 },{ n: 'AmountOwing',t: 28,p: 0 },{ n: 'TotalTaxAmount',t: 28,p: 0 },{ n: 'TotalTax2Amount',t: 28,p: 0 },{ n: 'TotalTax3Amount',t: 28,p: 0 },{ n: 'AmountPaid',t: 28,p: 0 },{ n: 'DiscountAmount',t: 28,p: 0 },{ n: 'CreditAmountUsed',t: 28,p: 0 },{ n: 'RefundAmount',t: 16,p: 0 },{ n: 'TipAmount',t: 28,p: 0 },{ n: 'AmountPaidWithTip',t: 28,p: 0 },{ n: 'AddressID',t: 195,p: 100 },{ n: 'PhoneNumber',t: 21,p: 0 },{ n: 'ItemCount',t: 16,p: 0 },{ n: 'OptionCount',t: 24,p: 0 },{ n: 'OptionsPrice',t: 28,p: 0 },{ n: 'TotalQuantity',t: 16,p: 0 },{ n: 'OrderCreationDate',t: 35,p: 0 },{ n: 'OrderPaidDate',t: 35,p: 0 },{ n: 'Rating',t: 16,p: 0 },{ n: 'PaidComment',t: 21,p: 0 },{ n: 'CustomOrderPaymentID',t: 195,p: 100 },{ n: 'OrderNotes',t: 21,p: 0 },{ n: 'RefundTaxAmount',t: 28,p: 0 },{ n: 'PaymentAuthCode',t: 21,p: 0 },{ n: 'TransactionID',t: 24,p: 0 },{ n: 'OrderNumber',t: 21,p: 0 },{ n: 'OrderSourceID',t: 195,p: 100 },{ n: 'POSCheckNumber',t: 21,p: 0 },{ n: 'ThirdPartyOrderNumber',t: 21,p: 0 },{ n: 'VerifiedDate',t: 35,p: 0 },{ n: 'VerifiedBy',t: 24,p: 0 },{ n: 'OrderPreparationTypeID',t: 195,p: 100 },{ n: 'ReturnCycle',t: 24,p: 0 },{ n: 'CompletedReturnCycle',t: 24,p: 0 },{ n: 'SublocationID',t: 195,p: 100 },{ n: 'SeatNumber',t: 24,p: 0 },{ n: 'SeatName',t: 21,p: 0 },{ n: 'IndividualItems',t: 22,p: 0 },{ n: 'RecordProcessed',t: 22,p: 0 },{ n: 'ActiveCounted',t: 22,p: 0 },{ n: 'PaymentInProcess',t: 22,p: 0 },{ n: 'PaymentComplete',t: 22,p: 0 }];
//       Object Value Definition For CustomOrders
const COV = { 'CustomOrderID': 0,'StoreLocationID': 0,'CustomerID': 0,'CustomOrderParentID': 0,'CustomOrderStatus': 0,'CustomOrderType': 0,'RequestDateTime': new Date(),'ScheduledPrepDateTime': new Date(),'PickupDeliveryDateTime': new Date(),'TotalPrice': 0,'AmountOwing': 0,'TotalTaxAmount': 0,'TotalTax2Amount': 0,'TotalTax3Amount': 0,'AmountPaid': 0,'DiscountAmount': 0,'CreditAmountUsed': 0,'RefundAmount': 0,'TipAmount': 0,'AmountPaidWithTip': 0,'AddressID': 0,'PhoneNumber': '','ItemCount': 0,'OptionCount': 0,'OptionsPrice': 0,'TotalQuantity': 0,'OrderCreationDate': new Date(),'OrderPaidDate': new Date(),'Rating': 0,'PaidComment': '','CustomOrderPaymentID': 0,'OrderNotes': '','RefundTaxAmount': 0,'PaymentAuthCode': '','TransactionID': 0,'OrderNumber': '','OrderSourceID': 0,'POSCheckNumber': '','ThirdPartyOrderNumber': '','VerifiedDate': new Date(),'VerifiedBy': 0,'OrderPreparationTypeID': 0,'ReturnCycle': 0,'CompletedReturnCycle': 0,'SublocationID': 0,'SeatNumber': 0,'SeatName': '','IndividualItems': false,'RecordProcessed': false,'ActiveCounted': false,'PaymentInProcess': false,'PaymentComplete': false };
//       Object Index Definition For CustomOrders
const COX = { 'CustomOrderID': 0,'StoreLocationID': 1,'CustomerID': 2,'CustomOrderParentID': 3,'CustomOrderStatus': 4,'CustomOrderType': 5,'RequestDateTime': 6,'ScheduledPrepDateTime': 7,'PickupDeliveryDateTime': 8,'TotalPrice': 9,'AmountOwing': 10,'TotalTaxAmount': 11,'TotalTax2Amount': 12,'TotalTax3Amount': 13,'AmountPaid': 14,'DiscountAmount': 15,'CreditAmountUsed': 16,'RefundAmount': 17,'TipAmount': 18,'AmountPaidWithTip': 19,'AddressID': 20,'PhoneNumber': 21,'ItemCount': 22,'OptionCount': 23,'OptionsPrice': 24,'TotalQuantity': 25,'OrderCreationDate': 26,'OrderPaidDate': 27,'Rating': 28,'PaidComment': 29,'CustomOrderPaymentID': 30,'OrderNotes': 31,'RefundTaxAmount': 32,'PaymentAuthCode': 33,'TransactionID': 34,'OrderNumber': 35,'OrderSourceID': 36,'POSCheckNumber': 37,'ThirdPartyOrderNumber': 38,'VerifiedDate': 39,'VerifiedBy': 40,'OrderPreparationTypeID': 41,'ReturnCycle': 42,'CompletedReturnCycle': 43,'SublocationID': 44,'SeatNumber': 45,'SeatName': 46,'IndividualItems': 47,'RecordProcessed': 48,'ActiveCounted': 49,'PaymentInProcess': 50,'PaymentComplete': 51 };

//------ Object Definition For Addresses -- Date: 2023-11-28-7:00 PM ------
const AddressesOID = 110191;
const AddressesO = [{ n: 'AddressID',t: 195,p: 100 },{ n: 'CompanyID',t: 195,p: 100 },{ n: 'CustomerID',t: 195,p: 100 },{ n: 'UnitNumber',t: 21,p: 0 },{ n: 'Address',t: 21,p: 0 },{ n: 'Address2',t: 21,p: 0 },{ n: 'City',t: 21,p: 0 },{ n: 'CityID',t: 195,p: 100 },{ n: 'ProvinceStateID',t: 195,p: 100 },{ n: 'PostalCode',t: 21,p: 0 },{ n: 'CountryID',t: 195,p: 100 },{ n: 'PhoneNumber',t: 21,p: 0 },{ n: 'IsPrime',t: 22,p: 0 }];
//       Object Value Definition For Addresses
const AV = { 'AddressID': 0,'CompanyID': 0,'CustomerID': 0,'UnitNumber': '','Address': '','Address2': '','City': '','CityID': 0,'ProvinceStateID': 0,'PostalCode': '','CountryID': 0,'PhoneNumber': '','IsPrime': false };
//       Object Index Definition For Addresses
const AX = { 'AddressID': 0,'CompanyID': 1,'CustomerID': 2,'UnitNumber': 3,'Address': 4,'Address2': 5,'City': 6,'CityID': 7,'ProvinceStateID': 8,'PostalCode': 9,'CountryID': 10,'PhoneNumber': 11,'IsPrime': 12 };

//const percentFormat = (value) => new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
const moneyFormat = (value) => new Intl.NumberFormat('en-US',{ style: 'currency',currency: 'USD' }).format(value);
//const dateFormat = (value) => new Intl.DateTimeFormat('en-CA',{ month:'long',day:'numeric',timeStyle:'short' }).format(Date.parse(value));
//const dateFormat = (value) => new Intl.DateTimeFormat('en-CA',{ month: 'short',day: 'numeric',timeStyle: 'short' }).format(Date.parse(value));
const dateFormat = (value) => new Intl.DateTimeFormat('en-CA',{ dateStyle: 'medium',timeStyle: 'short' }).format(Date.parse(value));
const scriptURL = "https://libs.na.bambora.com/customcheckout/1/customcheckout.js";

const codeReader = new BrowserMultiFormatReader();
//useExternalScripts(scriptURL);
//const InitScriptX = () => {
//  trace(`This is a testx`);
//};

class CheckoutPage extends Component {
  //------------------------------------------------------------------------------------------------------
  constructor(props) {
    super(props);
    trace("CheckoutPage");
    if (!SessionInfo || !SessionInfo.session)
      this.props.navigate("/AppMain");
    else {
      //trace(`session: ${SessionInfo.session}`); 
      this.createKeys = CreateKeys.bind(this); // This is interesting - you can bind an external function to this! - Note createKeys is not used
    }
    this.op0 = React.createRef();
    // Get Script and load it into main script - this is not working - see index.html
    //const head = document.querySelector("head");
    //const script = document.createElement("script");
    //---------------------------------------------------------
    // This is for testing script inclusion and execution
    //script.setAttribute("src", scriptURL);
    //script.setAttribute("src", './mainTest.js');
    //head.appendChild(script);
    //
    //this.InitScriptX();
    if (SessionInfo.InternalPaymentProcess == true) { // Any setup for internal payment processing  
      trace(`---> Internal Payment Process ONLY`);
    }
    else {
      if (SessionInfo.bamboraCodeLoaded === false) {
        SessionInfo.bamboraCodeLoaded = true;
        trace(`before load bambora script in checkoutpage`);
        appendScript("https://libs.na.bambora.com/customcheckout/1/customcheckout.js"); // https://dev.na.bambora.com/docs/guides/custom_checkout/setup/
        trace(`after load bambora script`);
      }
      SessionInfo.BamPayProc = this.makeTokenPayment;
    }
    //---------------------------------------------------------
  }
  //useEffect(() => {
  //  const head = document.querySelector("head");
  //  const script = document.createElement("script");

  //  script.setAttribute("src", scriptURL);
  //  head.appendChild(script);

  //  return () => {
  //    head.removeChild(script);
  //  };
  //}, [scriptURL]);
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  // State
  state = {
    CustomerID: 0,
    DeviceProfileID: 0,
    EmailReceipts: false,
    EmailPromotions: false,
    SMSReminders: false,
    SoundOn: false,
    AutoScanLocation: false,
    FullScreenReader: false,
    MaximumPurchaseAmount: 0,
    PersonPhotoID: 0,
    RoleID: 0,

    CustomOrderStatus: 0,
    OrderCreationDate: 0,
    OrderPaidDate: 0,
    ItemCount: 0,

    //--------------------------------
    CustomOrderID: 0,
    CustomOrderType: 0,
    RequestDateTime: new Date(),
    TotalAmount: 0,
    DiscountAmount: 0,
    CreditAmountUsed: 0,
    RefundAmount: 0,
    TotalTaxAmount: 0,
    TotalTax2Amount: 0,
    TotalTax3Amount: 0,
    TotalDiscountAmount: 0,
    TotalCost: 0,
    TotalTime: 0,
    StartDateTime: 0,
    EndDateTime: 0,
    PaymentMethodID: 0,
    IndividualItems: 0,
    PaymentVerifiedID: 0,
    //--------------------------------
    // CustomerAccounts
    Amount: 0,
    TransactionCount: 0,
    MinusTransactions: 0,
    PlusTransactions: 0,
    TotalMinusAmount: 0,
    TotalPlusAmount: 0,
    MaximumAmount: 0,
    LastUsedOn: 0,
    CustomerAccountStatus: 0,
    //---- New ----   
    TotalPriceWithTax: 0,
    total: 0,
    TableID: 0,
    TableName: '',
    SeatCount: 0,
    SeatName: '',
    showAllOrders: false,
    cancelAllowed: false,
    //--------------------------------

    CCFullName: '',

    StoreName: '',
    Address: '',
    FirstName: '',
    AdditionalName: '',
    LastName: '',
    EmailAddress: '',
    PhoneNumber: '',
    PhoneNumber2: '',
    //-------------------------------------------------------
    // From ReviewPurchasePage
    StoreLocationID: 0,
    CompanyID: 0,
    LocationID: 0,
    CurrentlyOpen: 0,
    AreaSize: 0.0,
    ContactID: 0,
    StoreGroupID: 0,
    TaxRate: 0.0,
    TaxRate2: 0.0,
    TaxRate3: 0.0,
    TipRate1: 0.0,
    TipRate2: 0.0,
    TipRate3: 0.0,
    StoreAmenities: 0,
    MaximumPurchaseAmount: 0.0,
    StandardMarkupPercent: 0.0,
    ExtendedMarkupPercent: 0.0,
    CurrentInStoreWaitTime: 0.0,

    LocationTypeID: 0,
    ProvinceStateID: 0,
    CountryID: 0,
    //-------------------------------- 
    StoreItemID: 0,
    StorePromotionID: 0,
    DiscountPercentage: 0.0,
    ProductItemCategoryID: 0,
    ProductItemGroupID: 0,
    ProductItemTypeID: 0,
    ShelfLifeDays: 0,
    ItemPrice: 0.0,
    ItemStatusID: 0,
    PackagingTypeID: 0,
    PricingTypeID: 0,
    RateTypeID: 0,
    PromotionType: 0,
    //--------------------------------
    ItemCount: 0,
    TotalQuantity: 0.0,
    TotalNetWeight: 0.0,
    TotalPrice: 0.0,
    TotalPriceWithTax: 0.0,
    AmountOwing: 0.0,
    AmountPaid: 0.0,
    TotalAmount: 0.0,
    DiscountAmount: 0.0,
    CreditAmountUsed: 0.0,
    RefundAmount: 0.0,
    TipAmount: 0.0,
    TipAmountOverD: 0.0,
    TipAmountOverDS: '',
    TipAmoutnOverP: 0.0,
    TipAmountOverPS: '',
    TotalTaxAmount: 0.0,
    TotalTax2Amount: 0.0,
    TotalTax3Amount: 0.0,
    AmountPaidWithTip: 0.0,
    AmountOwingWithTip: 0.0,
    ActualPaymentAmount: 0.0,
    ActualPaymentAmountS: '',
    TotalDiscountAmount: 0.0,
    Price: 0.0,
    PromotionPrice: 0.0,
    TotalCost: 0.0,
    TotalTime: 0,
    StartDateTime: 0,
    EndDateTime: 0,
    OrderDate: '',
    OrderCreationDate: 0,
    OrderPaidDate: 0,
    PaymentTypeID: 0,
    PaymentMethodID: 0,
    IndividualItems: 0,
    PaymentVerifiedID: 0,
    DaysForDiscount: 0,
    PromotionIsActive: 0,
    ImageID: 0,
    // End of ReviewPurchase
    //-----------------------------------
    selectedTopTab: 0,
    selectedBottomTab: 0,
    stateHasChanged: false,

    imgVideoVisible: false,
    showScan: true,
    result: 'No result',
    appDisplay: false,
    showPaymentFull: false,
    showPaymentPostal: false,
    showPaymentShort: false,
    showPaymentMinimal: false,
    showPaymentXMinimal: false,
    showPaymentBambora: false,
    notifyMessage: '',

    Address: '',
    Address2: '',
    UnitNumber: '',
    City: '',
    CityID: 0,
    PostalCode: '',
    PhoneNumber: '',
    ProvinceStateID: 0,
    CountryID: 0,
    AddressID: 0,
    IsPrime: false,

    showDeleteItem: false,
    DeleteItemName: '',
    DeleteItemDescription: '',
    DeleteItemCount: 1,

    PaymentMethods: [],

    isMainSearch: true,
    currentCustomerID: 0,
    PersonPhotoURL: undefined,
    isWalletUser: false,
    contractsVisible: false,
    StoreLocationID: 0,
    selectCustomerID: 0,

    CustomOrders: [],
    customOrdersSkip: 0,
    customOrdersTake: 8,
    receiptFilter: { logic: "and",filters: [] },
    receiptSort: [],

    isParentOrder: false,
    isReadyToPay: false,
    canCancel: true,
    voidItem: false,
    ScanTitle: '',
    hasTip: true,
    //-------------------------------------------------------------------------------------

    showSeat: [false,false,false,false,false,false,false,false,false,false],
    OrderItems: [[],[],[],[],[],[],[],[],[],[]],

    seatNames: ['','','','','','','','','',''],
    orderNames: ['','','','','','','','','',''],
    orderIDs: [0,0,0,0,0,0,0,0,0,0],
    tax: [0,0,0,0,0,0,0,0,0,0],
    price: [0,0,0,0,0,0,0,0,0,0],
    owing: [0,0,0,0,0,0,0,0,0,0],
    items: [0,0,0,0,0,0,0,0,0,0],
    paid: [0,0,0,0,0,0,0,0,0,0],
    tip: [0,0,0,0,0,0,0,0,0,0],
    payThisOrder: [false,false,false,false,false,false,false,false,false,false],

    //-------------------------------------------------------------------------------------

    cardNumber: '',
    expiry: '',
    cvc: '',
    focus: '',
    name: '',
    number: '',

    Customers: [],
    customersSkip: 0,
    customersTake: 18,

    Roles: [],
    CustomerAccountStatusInfo: [],
    CreditAmount: 0,

    showNotify: false,
    showCancelOrder: false,
    showVerifyCancel: false,
    showItemChangePopup: false,
    showVerifyItemDelete: false,
    showReviewPurchasePopup: false,
    showOrderQRCode: false,
    showItemQRCode: false,
    showPayCash: false,
    showScanQRCode: false,

    DocumentTypes: [{ ddName: "jpg",ddID: 1 },{ ddName: "jpeg",ddID: 2 },{ ddName: "png",ddID: 3 }],
  };
  vertSplitC = JSON.parse('[{"collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"min":"300px"},{"collapsible":true,"collapsed":true,"resizable":true,"scrollable":true,"size":"50%"}]');
  vertSplitO = JSON.parse('[{"collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"min":"300px"},{"collapsible":true,"collapsed":false,"resizable":true,"scrollable":true,"size":"50%"}]');

  compUpdate = true;
  cardNumber = '';
  numok = false;
  expiry = '';
  expok = false;
  expiryDate = '';
  cvc = '';
  cvcok = false;
  name = SessionInfo.FullName;
  leaddigit = '';
  blockPay = false;

  popupOffset = { left: 50,top: 70 };
  inputReference = React.createRef();
  createKeys;
  lastSelectedPID = 0;
  barcodeReader = undefined;
  selectedDeviceId = undefined;
  barcode = '';
  halfTakeLines = 8;
  activeColor = "#3e80ed";
  inProgressColor = "#ff4dd2";
  deliveredColor = "#248f24";
  maroon = "#800000";
  setShowNotify = 0;
  popupOffset = { left: 50,top: 30 };
  popupFormOffset = { left: 10,top: 20 };
  addPopupOffset = { left: 20,top: 10 };
  itemChangeOffset = { left: 20,top: 10 };
  reviewPurchaseOffset = { left: 2,top: 10 };
  deletePopupOffset = { left: 20,top: 10 };
  showQRPopupOffset = { left: 40,top: 100 };
  deleteCustomOrderItemID = 0;
  itemName = '';
  AmountOwing = 0;
  fetchCounter = 0;
  showScannerOffset = { left: 50,top: 50 };
  scanTypes = {
    GiftCard: 1,
  }
  scanType = 0;

  DisplaySeats = false;
  returnTo = 1;
  backPrompt = '< Back to Order';
  backCartPrompt = '< Back to Cart';
  OptionsPrice = 0;

  showSeat = [false,false,false,false,false,false,false,false,false,false];
  OrderItems = [[],[],[],[],[],[],[],[],[],[]];
  seatNames = ['','','','','','','','','',''];
  orderNames = ['','','','','','','','','',''];
  orderIDs = [0,0,0,0,0,0,0,0,0,0];
  customerIDs = [0,0,0,0,0,0,0,0,0,0];
  tax = [0,0,0,0,0,0,0,0,0,0];
  price = [0,0,0,0,0,0,0,0,0,0];
  owing = [0,0,0,0,0,0,0,0,0,0];
  items = [0,0,0,0,0,0,0,0,0,0];
  paid = [0,0,0,0,0,0,0,0,0,0];
  tip = [0,0,0,0,0,0,0,0,0,0];


  // Backup of current BamboraContent
  //BamboraContent2 = '<div className="container"><form id="checkout-form"><div id="card-number"></div><label for="card-number" id="card-number-error"></label><div id="card-cvv"></div><label for="card-cvv" id="card-cvv-error"></label><div id="card-expiry"></div><label for="card-expiry" id="card-expiry-error"></label><input id="pay-button" type="submit" class="btn disabled" value="Pay" disabled="true" /><div id="feedback"></div></form></div>';
  // Horizontal Payment Style
  //BamboraContent3 = '<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"><div class="container"><div class="row"><form id="checkout-form" class="form-inline text-center"><div class="form-group col-xs-6 has-feedback" id="card-number-bootstrap"><div id="card-number" class="form-control"></div><label class="help-block" for="card-number" id="card-number-error"></label></div><div class="form-group col-xs-2 has-feedback" id="card-cvv-bootstrap"><div id="card-cvv" class="form-control"></div><label class="help-block" for="card-cvv" id="card-cvv-error"></label></div><div class="form-group col-xs-2 has-feedback" id="card-expiry-bootstrap"><div id="card-expiry" class="form-control"></div><label class="help-block" for="card-expiry" id="card-expiry-error"></label></div><div class="col-xs-2 text-center"><button id="pay-button" type="submit" class="btn btn-primary disabled" disabled="true">Pay</button></div></form></div></div>';

  BamboraContent = '<div className="container"><form id="checkout-form"><div id="card-number"></div><label for="card-number" id="card-number-error"></label><div id="card-cvv"></div><label for="card-cvv" id="card-cvv-error"></label><div id="card-expiry"></div><label for="card-expiry" id="card-expiry-error"></label><input id="pay-button" type="submit" class="btn disabled" value="Pay" disabled="true" /><div id="feedback"></div></form></div>';

  op0 = undefined;

  async componentDidMount() {
    if (!SessionInfo.session || SessionInfo.session === '')
      return;
    let CD;
    if (SessionInfo.currentPage === "OrderPage") {
      this.backPrompt = '< Back to Order';
      this.returnTo = 1;
    } else {
      this.backPrompt = '< Back to Scanner';
      this.returnTo = 2;
    }
    SessionInfo.currentPage = "CheckoutPage";
    this.setState({ showNotify: false });
    this.setState({ selectCustomerID: SessionInfo.PersonInfo.CustomerID });
    trace(` mount checkoutpage - IsNew: ${SessionInfo.IsNewOrder}, Current CustID: ${SessionInfo.CurrentOrderCustomerID}, Session Cust ID: ${SessionInfo.PersonInfo.CustomerID}, OrderType: ${SessionInfo.OrderType}`);
    if (SessionInfo.CurrentOrderCustomerID === 0)
      SessionInfo.CurrentOrderCustomerID = SessionInfo.PersonInfo.CustomerID; 
    this.lastSelectedPID = SessionInfo.PersonInfo.CustomerID;
    if (SessionInfo.CurrentOrderCustomerID === SessionInfo.PersonInfo.CustomerID)
      this.setState({ showCancelOrder: true }); // The person who created the order is allowed to Cancel it
    else
      this.setState({ showCancelOrder: false });
    try {
      this.compUpdate = false;
      if (SessionInfo.IsNewOrder === true) { //--------- New Order Processing ------ 
        // Note: 26Aug24 - May want to change this - scan all orders and see if ALL NOT submitted - Right now will ONLY show on first time in Checkout Page
        SessionInfo.IsNewOrder = false; // Turn off new order flag 
        SessionInfo.ShowAllOrders = false;
        for (let i = 0; i < 10; i++) // Reset PayTheseOrders
          SessionInfo.PayTheseOrders[i] = 0;
        CD = await CallOM("GetOrderSetup",3,SessionInfo.CustomOrderID,0);
        if (CD.d.CustCount === 1) { // The First Element in the dictionary is the number of Orders
          SessionInfo.ShowAllOrders = false;
          SessionInfo.PayAllOrders = false;
        }
        trace(`CheckoutPage Mount - CustomerID: ${SessionInfo.PersonInfo.CustomerID}, CurrentOrderCustomerID: ${SessionInfo.CurrentOrderCustomerID}, ParentOrder: ${SessionInfo.ParentOrderID}, Setup: ${JSON.stringify(CD.d)}`);
      }
      this.setState({ StoreName: SessionInfo.StoreName });
      this.setState({ TableName: SessionInfo.CurrentTableName });
      this.setState({ SeatName: SessionInfo.SeatName });
      this.setState({ SeatCount: SessionInfo.SeatCount });
      this.setState({ showAllOrders: SessionInfo.ShowAllOrders });
      // Show the Cart(s) with items and totals for each seat
      await this.getOrderItemRecords(); // calls getcurrentTotal(); getSeatInfo() - This initializes payThisOrder
      this.setState({ SeatCount: SessionInfo.SeatCount });
      this.setTaxTips();
      trace(`AmountOwing: ${this.AmountOwing}, state: ${this.state.AmountOwing}, seatCount: ${SessionInfo.SeatCount}`);
      // Check if complete (after payment)
      if (this.AmountOwing <= 0) {
        SessionInfo.BillPaymentComplete = true; // Possible that not All orders have been paid
        SessionInfo.BillPaymentDone = true;
        SessionInfo.ResetOrder = true;
        trace(`Checkout Mount - Reset: ${SessionInfo.ResetOrder}, OrderType: ${SessionInfo.OrderType}`);
        if (SessionInfo.OrderType === 3) {
          this.props.navigate("/ScannerPage");
        } else {
          this.props.navigate("/OrderPage");
        }
      } else { // Handle Cart(s)
        if (SessionInfo.BillPaymentDone === false) {
          //await this.getPaymentMethods();
          //------------------------------------------------------------- 
          if (SessionInfo.CustomOrderID === SessionInfo.ParentOrderID && SessionInfo.SeatCount > 1) {
            this.setState({ isParentOrder: true });
          }
          else {
            this.setState({ isParentOrder: false });
          }
        }
      }
      if (SessionInfo.traceDebug) {
        if (SessionInfo.testScriptLoad === true) // Load Script - the InteractMenuInit.js is really just for test - need to bypass this for non-payment version
          TestScriptX();
      }
      if (SessionInfo.OrderType === 3) { // Scanner Page
        this.setState({ hasTip: false });
      } else {
        this.setState({ hasTip: true });
      }
      SessionInfo.BamToken = ''; // I believe that this should be removed - temporary for dev
      if (SessionInfo.FullName.startsWith("Temp")) {
        this.setState({ CCFullName: "" });
        this.setState({ name: "" });
      } else {
        this.setState({ CCFullName: SessionInfo.FullName });
        this.setState({ name: SessionInfo.FullName });
      }
      // Remove any OrderItems which are Pending (Status = 0) 
    } catch (ex) {
      console.log(`Failure in Checkout Load: ${ex}`);
    }
    this.compUpdate = true;
    //this.runMonitorProcess = true;
    // NOTE - 5DEC23 - Monitor MUST be running for Checkout to Work
    trace(`start Monitor - Reset: ${SessionInfo.ResetOrder}`);
    //this.monitorProcess(true); // Commented out for Testing
    trace(`showSeat Control: ${JSON.stringify(this.showSeat)}`);
  }
  componentWillUnmount() {
    trace(`--> CheckoutPage Unmount`);
    if (SessionInfo.session !== '')
      SessionInfo.CheckoutPage = this.state;
    this.runMonitorProcess = false;
    if (this.timeout)
      clearTimeout(this.timeout);
  }
  //----------------------------------  
  runMonitorProcess = false;
  setTimer() {
    if (SessionInfo.loggedOn === true && this.runMonitorProcess === true)
      this.timeout = setTimeout(this.monitorProcess.bind(this),200); // 200 microseconds  
  }
  //----- Timer Process - Application Control Logic -------------------------------------------------
  monitorProcess = async (force) => { // NOTE - 5DEC23 - Monitor MUST be running for Checkout to Work
    if (force)
      this.runMonitorProcess = true;
    //trace(`monitor logoff: ${SessionInfo.forceLogoff}, On: ${SessionInfo.loggedOn}`);
    if (SessionInfo.forceLogoff === true) {
      SessionInfo.forceLogoff = false;
      this.props.navigate("/Login");
    }
    if (this.runMonitorProcess === true && SessionInfo.loggedOn === true) {
      this.setTimer();
      if (1 === 1 || document.hasFocus() === true) { // Execute monitor process
        //---- Ping and Notification Processing -------------
        if (SessionInfo.setShowNotify > 0 && SessionInfo.notifyMessage.length > 0) {
          if (SessionInfo.setShowNotify == 11) { // Action      
            this.setState({ notifyMessage: "Action Notify: " + SessionInfo.notifyMessage });
            SessionInfo.notifyTimer = 100;
            this.setState({ showNotify: true }); // Show the Message
            if (SessionInfo.notifyMessage == "CashComplete") {
              this.setState({ showPayCash: false });
              this.onAddMore()
            }
          } else {
            this.setState({ notifyMessage: SessionInfo.notifyMessage });
            SessionInfo.notifyTimer = SessionInfo.notifyTime;
            this.setState({ showNotify: true }); // Show the Message 
            if (SessionInfo.notifyRing && (SessionInfo.SoundOn === true || SessionInfo.SoundOverride === true)) {
              //trace(`Play Notify Sound: ${SessionInfo.notifyRing}`);
              try {
                await SessionInfo.notifyRing.play();
              }
              catch (Ex) {
                trace(`Ring Ex: ${Ex}`);
              }
              SessionInfo.notifyRing = undefined;
              SessionInfo.SoundOverride = false;
            }
          }
        }
        SessionInfo.setShowNotify = 0;
        if (SessionInfo.notifyTimer > 0) {
          if (--SessionInfo.notifyTimer <= 0)
            this.setState({ showNotify: false });
        } else if (SessionInfo.showNotify === true)
          this.setState({ showNotify: false }); // Hide the Notify Message
        if (SessionInfo.pingCounter++ > 150) {
          SessionInfo.pingCounter = 0;
          let CD = await CallOM("PingApp",SessionInfo.StoreLocationID,SessionInfo.CustomOrderID,CTYP.STR,"");
          if (CD && CD.d && CD.d.rows) {
            //trace(`Return from PingApp - CD: ${JSON.stringify(CD)}`);
            let rows = CD.d.rows;
            for (let ix = 0; ix < rows.length; ix++) {
              let row = rows[ix];
              SetNotify(row["NotificationMessage"],row["NotificationTypeID"]);
              SessionInfo.AppNotificationID = row["AppNotificationID"];
            }
          }
        }
        if (this.fetchCounter++ > 25) {
          this.fetchCounter = 0;
          if (this.state.showReviewPurchasePopup === false) {
            await this.getOrderItemRecords(); // calls getcurrentTotal(); getSeatInfo() - This initializes payThisOrder
          }
        }
      }
      //---- End of Notification Processing -------------
    }
  }
  acknowledgeMessage = async () => {
    let CD = await CallOM("AcknowledgeNotification",0,SessionInfo.AppNotificationID);
    this.setState({ showNotify: false });
    SessionInfo.notifyTimer = 50;
    SessionInfo.acknowledge = false;
    this.forceUpdate();
  }
  goToPurchase = async () => { // Get the Order Item Records
    await this.getCustomOrder();
    let payHeight = 670;
    if (SessionInfo.AllowPartial === true)
      payHeight += 40;
    if (SessionInfo.AppCfg.HasGooglePay === true)
      payHeight += 50;
    if (SessionInfo.OrderType === 3)
      payHeight -= 300;
    let payTopCard = payHeight - 140;
    let payTopGift = payTopCard + 40;
    trace(`purchase Height: ${payHeight}`);
    document.documentElement.style.setProperty('--pay-height',`${payHeight}px`);
    document.documentElement.style.setProperty('--pay-top-card',`${payTopCard}px`);
    document.documentElement.style.setProperty('--pay-top-gift',`${payTopGift}px`);
    document.documentElement.style.setProperty('--pay-top-google',`${payTopCard + 90}px`);
    this.setState({ showReviewPurchasePopup: true });
  }
  shouldComponentUpdate() {
    //trace("Should Update?");
    if (this.compUpdate === true)
      return true;
    else
      return false;
  }
  // Test implementation
  //shouldComponentUpdate(nextProps, nextState) {
  //  return !equals(nextProps, this.props); // equals() is your implementation
  //}
  //handleScan(data) {
  //  this.setState({
  //    result: data,
  //  })
  //}
  //handleError(err) {
  //  console.error(err)
  //}
  setTaxTips = () => {
    this.setState({ TaxRate: SessionInfo.SLV.TaxRate });
    this.setState({ TaxRate2: SessionInfo.SLV.TaxRate2 });
    this.setState({ TaxRate3: SessionInfo.SLV.TaxRate3 });
    this.setState({ TipRate1: Math.round(parseFloat(SessionInfo.SLV.TipRate1) * 1000.0) / 10 });
    this.setState({ TipRate2: Math.round(parseFloat(SessionInfo.SLV.TipRate2) * 1000.0) / 10 });
    this.setState({ TipRate3: Math.round(parseFloat(SessionInfo.SLV.TipRate3) * 1000.0) / 10 });
  }
  showMessage = (msg) => {
    this.messageColor = 'blue';
    this.messageCounter = 25;
    this.setState({ NotifyMessage: msg });
    this.setState({ activateMessageBox: true });
  }
  messageClear = (msg) => {
    this.messageColor = 'blue';
    this.messageCounter = 0;
    this.setState({ NotifyMessage: '' });
    this.setState({ activateMessageBox: false });
  }
  showWarn = (msg) => {
    this.messageColor = 'orange';
    this.messageCounter = 30;
    this.setState({ NotifyMessage: msg });
    this.setState({ activateMessageBox: true });
  }
  showError = (msg) => {
    this.messageColor = 'red';
    this.messageCounter = 35;
    this.setState({ NotifyMessage: msg });
    this.setState({ activateMessageBox: true });
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  CommandCell;
  //-------------------- Order Items -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  getOrderItemRecords = async () => { // Get the Order Item Records to Display in Checkout - NOTE: This is called in MonitorProcess - in case of updates to other orders
    let CD;
    trace(`==> GetCustomOrderItems for OrderID: ${SessionInfo.CustomOrderID}`);
    //if (SessionInfo.CustomOrderID === 0) {
    //  await this.addOrder();
    //}
    let showAll = SessionInfo.ShowAllOrders;
    //showAll = true;
    trace(`getOrderItemRecords showAll: ${showAll}, OrderType: ${SessionInfo.OrderType} `);
    CD = await CallOM("GetAllCustomOrderItems",0,SessionInfo.CustomOrderID,0,'','',2); // Get fulldesc - no image
    //--- Init Arrays ---
    this.showSeat = [false,false,false,false,false,false,false,false,false,false];
    this.OrderItems = [[],[],[],[],[],[],[],[],[],[]];
    this.seatNames = ['','','','','','','','','',''];
    this.orderNames = ['','','','','','','','','',''];
    this.currentOrderIDs = [0,0,0,0,0,0,0,0,0,0];
    this.tax = [0,0,0,0,0,0,0,0,0,0];
    this.price = [0,0,0,0,0,0,0,0,0,0];
    this.owing = [0,0,0,0,0,0,0,0,0,0];
    this.items = [0,0,0,0,0,0,0,0,0,0];
    this.paid = [0,0,0,0,0,0,0,0,0,0];
    this.tip = [0,0,0,0,0,0,0,0,0,0];
    let CurrCustomerID = 0;
    //trace(`GetCustomOrderItems o: ${CD.x.o}, Order Items: ${JSON.stringify(CD.d)}`);
    if (CD.x.o < 9500) {
      try { // Take dataItems and prepend each row with 'selected'
        const AllOrderItems = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
        //trace(`All OrderItems: ${JSON.stringify(AllOrderItems)}`);
        let allDone = true;
        let canCancel = true;
        AllOrderItems.forEach(ord => {
          trace(`orderItem: ${ord.CustomOrderItemID}, Status: ${ord.OrderItemStatus}`);
          if (ord.OrderItemStatus < 2)
            allDone = false;
          else if (ord.OrderItemStatus > 2)
            canCancel = false;
        });                       
          trace(`canCancel: ${canCancel} - showCancelOrder: ${this.state.showCancelOrder} `);
        if (canCancel === false) {                  
          this.setState({ showCancelOrder: false });
        }
        if (SessionInfo.OrderType === 3) { // Scan in Store
          this.setState({ isReadyToPay: true });
        } else
          this.setState({ isReadyToPay: allDone });
        let startPos = 0;
        let currPos = 0;
        let seatNum = 0;
        let currOrder = '';
        let maxOrderNum = 0;
        let payOrderSelect = [false,false,false,false,false,false,false,false,false,false];
        for (let i = 1; i < 10; i++)
          this.showSeat[i] = false;
        if (showAll === false) { // In this case only the main Order will have been retrieved  - NOTE that if retrieved ALL and showAll=false - all orderitems shown under first order
          this.OrderItems[0] = AllOrderItems;
          this.showSeat[0] = true;
          //trace("OrderItems-0: " + JSON.stringify(OrderItems[0]));
          if (AllOrderItems[0]) {
            this.orderNames[0] = "#" + AllOrderItems[0].CustomOrderID;
            this.currentOrderIDs[0] = AllOrderItems[0].CustomOrderID;
            this.customerIDs[0] = SessionInfo.PersonInfo.CustomerID;
            payOrderSelect[0] = true;
          }
        } else { // Note - ALL of the OrderItems are included in order of the CustomOrder that they belong to 
          let curOx = SessionInfo.PayTheseOrders.findIndex(ordID => ordID === SessionInfo.CustomOrderID);
          if (curOx < 0) {
            for (let i = 0; i < 10; i++) { // Find the first empty
              if (SessionInfo.PayTheseOrders[i] === 0) {
                SessionInfo.PayTheseOrders[i] = SessionInfo.CustomOrderID;
                break;
              }
            }
          }
        }
        //-------------------------------
        let payPos = 0;
        trace(`Process items - cnt: ${AllOrderItems.length}`);
        for (let ix = 0; ix < AllOrderItems.length; ix++) {
          let rw = AllOrderItems[ix];
          //AllOrderItems.forEach((rw) => { // Note that these are the OrderItems - usually multiple per order - and if mulitple Orders associated with Parent - Multiple Order per Parent Order
          //trace(`start: ${startPos}, ix: ${ix}, curr: ${currPos}, order: ${rw.OrderName}, order-num: ${rw.CustomOrderID}, seatNum: ${seatNum}, customerID: ${rw.CustomerID}, optionCnt: ${rw.OptionCount}`);
          //if (rw.OrderName !== order) { // Switch to next Order 
          if (rw.CustomOrderID !== currOrder) { // Switch to first or next Order
            if (SessionInfo.PayAllOrders === true) {
              SessionInfo.PayTheseOrders[payPos++] = rw.CustomOrderID;
            }
            if (currOrder === '') { // First Order
              //order = rw.OrderName;
              currOrder = rw.CustomOrderID;
              this.orderNames[0] = rw.OrderName;
              this.currentOrderIDs[0] = rw.CustomOrderID;
              this.customerIDs[0] = rw.CustomerID;
              if (CurrCustomerID === 0) {
                CurrCustomerID = rw.CurrCustomerID;
              } else if (CurrCustomerID != rw.CurrCustomerID)
                multipleCustomers = true;
              let curIx = SessionInfo.PayTheseOrders.findIndex(ordID => ordID === rw.CustomOrderID);
              if (curIx >= 0 || SessionInfo.PayAllOrders === true)
                payOrderSelect[seatNum] = true;
              currPos++;
              maxOrderNum++;
            }
            else { // New order + finish up last order
              this.OrderItems[seatNum] = AllOrderItems.slice(startPos,currPos); // Copy Items for Last
              this.showSeat[seatNum] = true;
              startPos = currPos;
              currPos++;
              seatNum++;
              //order = rw.OrderName;
              currOrder = rw.CustomOrderID;
              this.orderNames[seatNum] = rw.OrderName;
              this.currentOrderIDs[seatNum] = rw.CustomOrderID;
              this.customerIDs[seatNum] = rw.CustomerID;
              let curIx = SessionInfo.PayTheseOrders.findIndex(ordID => ordID === rw.CustomOrderID);
              if (curIx >= 0) {
                payOrderSelect[seatNum] = true;
              }
              maxOrderNum++;
            }
          } else {
            currPos++;
          }
          //  
        }
        if (currPos > startPos) { // Finish up the last order
          this.OrderItems[seatNum] = AllOrderItems.slice(startPos,currPos);
          this.showSeat[seatNum] = true;
          //trace(`start: ${start}, curr: ${curr}, seatNum: ${seatNum}, OrderItems: ${JSON.stringify(this.OrderItems[seatNum])}`);
        }
        //--------------------------------------------------------------------
        for (let ord = 0; ord < maxOrderNum; ord++) {
          // Get options for Current Item
          for (let itm = 0; itm < this.OrderItems[ord].length; itm++) {
            let rw = this.OrderItems[ord][itm];
            trace(`optionCnt: ${rw.OptionCount}`);
            if (rw.OptionCount > 0) {
              CD = await CallOM("GetOrderItemOptionsListStr",0,rw.CustomOrderItemID,0);
              //console.log(`Options for Item: ${rw.CustomOrderItemID}: ${JSON.stringify(CD.d)}`);
              try {
                let lastCat = '';
                let options = CD.d.rows;
                for (let ix = 0; ix < options.length; ix++) {
                  if (options[ix].GrpName === lastCat)
                    options[ix].GrpName = '';
                  else
                    lastCat = options[ix].GrpName;
                }
                //console.log(`Options for Item: ${rw.CustomOrderItemID}: ${JSON.stringify(options)}`);
                rw.options = options;
                //console.log(`OptionList: ${rw.options.length}`);
              } catch (error) {
                console.log(`Failure in GetOrderItemOptions - Error: ${error.message}`);
              }
            } else
              rw.options = [];
          }
        }
        //--------------------------------------------------------------------
        // Causing totalling bug not adding base item? 
        if (SessionInfo.PayAllOrders === true) {
          trace(`==> Show all and Set Selected ON for all of the orders: ${JSON.stringify(this.currentOrderIDs)}`);
          //await this.payAllOrders();
          trace(`Pay All Orders: ${JSON.stringify(SessionInfo.PayTheseOrders)}`);
          SessionInfo.PayAllOrders = false;
        }
        this.setState({ payThisOrder: payOrderSelect }); // set state for Pay Flags   
        //trace(`OrderItems: ${JSON.stringify(this.OrderItems)}`);
        this.setState({ OrderItems: this.OrderItems });
        this.setState({ orderNames: this.orderNames });
        this.setState({ showSeat: this.showSeat });
      } catch (error) {
        await OMTrace(2,`Failure in getOrderItemRecords - Error: ${error}`);
      }
    }
    //trace(`----> Call getSeatInfo and CurrentTotals - showAll: ${showAll}`);
    await this.getSeatInfo();
    await this.getCurrentTotal(showAll); // Get Totals and OrderType
  }
  //
  getSeatInfo = async () => { // Fetch the current seat orders and Display the Seat Popup
    trace(`--> getSeatInfo: ${SessionInfo.CustomOrderID}`); //" + JSON.stringify(this.OrderItems));
    this.currentOrderChanged = false;
    let CD = await CallOM("GetAllCustomOrderNames",0,SessionInfo.CustomOrderID,0);
    //trace(`afer GetAllCustomOrderNames CD: ${JSON.stringify(CD)}`);
    if (CD.x.o === 0)
      await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
    else if (CD.x.o < 9500) {
      try {
        //trace(`====> seatInfo: ${JSON.stringify(CD.d)}`);
        this.DisplaySeats = true; // Add 6Jun23
        const SeatOrders = CD.d.rows;
        let totalCount = 0;
        let SeatCount = 0;
        //--------------------
        SeatOrders.forEach((rw) => { // Look for the Currently Selected OptionItem and turn off 
          let orderID = rw.CustomOrderID;
          //trace(`Search for Order: ${orderID}`);
          let ix = 0;
          let curIx = -1;
          this.OrderItems.forEach((set) => {
            if (set[0]) {
              if (set[0].CustomOrderID === orderID) {
                curIx = ix;
                trace(`CustomOrderID: ${set[0].CustomOrderID}, ix: ${ix}`);
              }
              else
                ix++;
            }
          });
          //let curIx = this.AllOrderItems.findIndex(dataItem => dataItem.CustomOrderID === orderID);
          //trace(`orderID: ${rw.CustomOrderID}, curIx: ${curIx}`);
          if (curIx >= 0) { // There is at least one orderItem for the current seat - NOTE - 1Jun23 if ShowAll not true - it will not fetch the orderitems for the non-primary order
            this.seatNames[curIx] = rw.SeatName;
            this.tax[curIx] = rw.Tax;
            this.price[curIx] = rw.Price;
            this.owing[curIx] = rw.Owing;
            this.items[curIx] = rw.Items;
            this.paid[curIx] = rw.Paid;
            this.tip[curIx] = rw.Tip;
            this.showSeat[curIx] = true;
            //trace(`seat ${curIx}: ${rw.SeatName} - Total: ${rw.Price}, Tax: ${rw.Tax}, Paid: ${rw.Paid}, OrderID: ${orderID}`);
          }
          SeatCount++;
        });
        //--------------------------
        if (SeatCount < SeatOrders.length && SessionInfo.ShowAllOrders === true) {
          let diff = SeatOrders.length - SeatCount;
          if (diff === 1)
            displayMessage(`${diff} seat has not ordered yet`);
          else
            displayMessage(`${diff} seats have not ordered yet`);
        } else {
          this.notifyTimer = 0;
          this.setState({ showNotify: false });
        }
        this.setState({ seatNames: this.seatNames });
        this.setState({ tax: this.tax });
        this.setState({ price: this.price });
        this.setState({ owing: this.owing });
        this.setState({ items: this.items });
        this.setState({ paid: this.paid });
        this.setState({ tip: this.tip });
        this.setState({ SeatCount });
        SessionInfo.SeatCount = SeatCount;
        //trace(`SeatCount: ${SessionInfo.SeatCount}`);
        //trace("tax: " + JSON.stringify(this.OrderItems));
      } catch (error) {
        await OMTrace(2,`Failure in getSeatInfo - Error: ${error}`);
      }
    }
  }
  // Note - Should not be adding Order at this point - See addOrder on OrderPage
  //addOrder = async (seatName) => { // Note - 1Jun23 - this should NEVER be called
  //  let CD;
  //  try {
  //    if (!seatName || seatName === '')
  //      seatName = SessionInfo.CurrentSeat;
  //    if (SessionInfo.CustomOrderID === 0) { // Add a CustomOrder - This should NOT happen in Checkout 
  //      trace(`Create CustomOrder (1) - StoreLocationID: ${SessionInfo.StoreLocationID}, CurrentTableID: ${SessionInfo.CurrentTableID}, seatName: ${seatName},${SessionInfo.CurrentSeat},${SessionInfo.SeatName}`);
  //      let orderType = 4; // Order at Table
  //      CD = await CallOM("CreateCustomOrderTab",0,SessionInfo.StoreLocationID,CTYP.TypParm,[TYP.INT,orderType,TYP.INT,0,TYP.STR,"",TYP.STR,seatName,TYP.INT,SessionInfo.CurrentTableID]);
  //      if (CD.x.o === 0)
  //        await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
  //      else if (CD.x.o < 9500) {
  //        if (SessionInfo.CustomOrderID != CD.d.CustomOrderID) {
  //          SessionInfo.CustomOrderID = CD.d.CustomOrderID;
  //          SessionInfo.IsNewOrder = true;
  //        }
  //        this.setState({ TableName: CD.d.TableName });
  //        SessionInfo.CurrentTableName = CD.d.TableName;
  //        this.setState({ SeatCount: CD.d.SeatCount });
  //        SessionInfo.SeatCount = CD.d.SeatCount;
  //        if (SessionInfo.SeatName === '')
  //          SessionInfo.SeatName = `Seat #: ${SessionInfo.SeatCount}`;
  //        SessionInfo.ParentOrderID = CD.d.ParentOrderID;
  //        trace(`CreateCustomOrderTabID: ${SessionInfo.CustomOrderID}, TableName: ${this.state.TableName}`);
  //      }
  //    }
  //  } catch (error) {
  //    await OMTrace(2,`Failure in addOrder in Checkout - Error: ${error}`);
  //  }
  //  return;
  //}
  onCheckout = () => {
    if (this.state.TotalPrice > 0) {
      displayError(`Checkout Order for ${moneyFormat(this.state.TotalPrice)} - not implemented`);
    }
  }
  //---------------------------- Payment Methods ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- 
  getPaymentMethods = async (search) => { // Get the available Participants
    if (this.op0)
      this.op0.current.scrollIntoView({ behavior: 'smooth',block: 'start' });
    if (!search)
      search = '';
    let CD = await CallOM("GetPaymentMethodsL",0,SessionInfo.CustomOrderID,0,search);
    //trace(`PaymentMethods: ${JSON.stringify(CD.d)}`);
    trace(`GetPaymentMethodsL o: ${CD.x.o}`);
    if (CD.x.o === 0)
      await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
    else if (CD.x.o < 9500) {
      try {
        const PaymentMethods = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
        this.setState({ PaymentMethods });
      } catch (error) {
        await OMTrace(2,`Failure in getPaymentMethods - Error: ${error}`);
      }
    }
  }
  getPaymentMethod = async (paymentMethodID) => {
    //let CD;
    //---------------       
    trace(`CallGetPaymentMethod - ID: ${paymentMethodID}`);
    let CD = await CallOM("GetPaymentMethodL",0,paymentMethodID,0);
    //trace("Store Items: " + JSON.stringify(CD.d));
    if (CD.x.o === 0)
      LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
    else if (CD.x.o < 9500) {
      try {
      } catch (error) {
        await OMTrace(2,`Failure in getPaymentMethod - Error: ${error}`);
      }
    }
  }
  deletePaymentMethod = async (paymentMethodID) => {
    trace(`deletepaymentMethodRecord - ID: ${paymentMethodID}`);
    await CallOM("RemovePaymentMethod",0,paymentMethodID,1);
    await this.getCurrentTotal(SessionInfo.ShowAllOrders);
  }

  addUpdatePaymentMethod = async (items) => {
    let CD;
    trace(`add payment data: ${items}`);
    try {
      CD = await CallOM("AddUpdatePaymentMethodL",0,this.state.PaymentMethodID,CTYP.LSTDICTNS,items); // Process Promotion Information and any other related processing   
      if (CD.x.o === 0)
        await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
      else if (CD.x.o >= 9500) {
        trace(`Error on add payment data: ${JSON.stringify(CD.d)}`);
        displayMessage("Payment Add Failed");
      }
      else if (CD.x.o < 9500) {
        trace(`add payment data: ${JSON.stringify(CD.d)}`);
        await this.getOrderItemRecords();
        await this.getPaymentMethods();
      }
    } catch (error) {
      await OMTrace(2,`Failure in addUpdatePaymentMethod - Error: ${error}`);
    }
    return CD;
  }
  makePaymentDirectly = async (items) => { // Make Payment Directly - without saving Card Info (Not Tested - 19Jul22)
    let CD;
    trace(`makePaymentDirectly Checkout items: ${JSON.stringify(items)}`);
    try {
      CD = await CallOM("MakeDirectPaymentMethodL",0,SessionInfo.CustomOrderID,15,items); // Process Promotion Information and any other related processing   
      if (CD.x.o === 0)
        await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
      else if (CD.x.o < 9500) {
        trace(`make payment data: ${JSON.stringify(CD)}`);
      }
    } catch (error) {
      await OMTrace(2,`Failure in makePayment - Error: ${error}`);
    }
    return CD;
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  onDeleteItem = props => {
    displayError(`Delete Item: ${JSON.stringify(props.ItemDescription)}`);
    trace(`Delete ID: ${JSON.stringify(props.CustomOrderItemID)}`);
    this.setState({
      OrderItems: this.state.OrderItems.filter(e => e.CustomOrderItemID !== props.CustomOrderItemID)
    });
    this.deleteOrderStoreItem(props.CustomOrderItemID);
  }
  clearMessage = async () => { // ClearDisplay, Clear Messages
    //trace("ClearMessage Pressed - for: " + SessionInfo.currentPage);
    this.setState({ MessagesValue: '' });
    SessionInfo.message = ''; // MessagesValue is set to SessionInfo.message in the Timer loop  
    this.setState({ showPopup: false });
  }
  PopupMessage = async () => {
    // Keep this code for example
    //var tag = document.createElement('div');
    //var newString = this.state.MessagesValue.replace(/<br\/>/g, "XXX");
    //trace("newString: " + newString);
    //tag.innerHTML = newString;
    //newString = tag.innerHTML.replace(/XXX/g, "&#10;");  
    //trace("newString: " + newString);
    //this.setState({ MessagesText: newString });
    if (this.state.showPopup === false)
      this.popupTimer = 50;
    else
      this.popupTimer = 0;
    this.setState({ showPopup: !this.state.showPopup });
    //this.setState({ dialogVisible: true });
  }
  //------------------------- Image Processing - Save this for Credit Card? ------------------------------------------------------------------
  captureImageRef = () => this.inputReference.current.click();

  captureImage = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    trace(`File: ${JSON.stringify(file)}`);
    let ImageType = GetFileExtension(file.name);
    trace(`ImageType: ${ImageType}`);
    ImageType = ImageType.toLowerCase();
    this.setState({ ImageType });
    let imageURL = URL.createObjectURL(file);
    this.setState({ PersonPhotoURL: imageURL });
    //this.setState({ ContractFileName: file.name });
    let reader = new window.FileReader();
    try {
      reader.readAsArrayBuffer(file);
      reader.onloadend = () => this.convertToBuffer(reader);
      displayMessage(`Image: ${file.name} Loaded`);
    }
    catch (error) {
      trace(`FileReader Error: ${error}`);
      displayError("Error on Image Read");
    } //catch      
    let ix = this.state.DocumentTypes.findIndex(c => c.ddName === ImageType);
    if (ix >= 0)
      this.setState({ DocumentTypeID: this.state.DocumentTypes[ix].ddID });
    else
      displayWarning("Unknown file type: " + ImageType);
  }
  convertToBuffer = async (reader) => { // File is converted to a buffer to prepare for uploading to OM
    const UploadFile = await Buffer.from(reader.result);
    //set this buffer -using es6 syntax          
    this.setState({ UploadFile });
    trace("UploadFile Length: " + UploadFile.length);
    await this.saveImageToServer(UploadFile);
  };

  GetProductItemImage = async (imageID) => {
    let imageURL = getImageFileFromServer(imageID);
    this.setState({ ProductItemPhotoURL: imageURL });
  }
  saveProductItemImageToServer = async (event) => {
    await this.saveImageToServer(this.StoreItemTableObjID,this.state.StoreItemID);
  }
  storeItemsPageChange = (event) => {
    this.setState({ storeItemsSkip: event.page.skip,storeItemsTake: event.page.take });
  }

  saveImageToServer = async (uploadFile) => {
    trace('saveImageToServer: ');
    if (this.isSavingDocument) {
      displayMessage("Document Save in Progress");
      return;
    }
    else if (!uploadFile)
      displayError('A file must be selected');
    else {
      this.isSavingDocument = true;
      displayMessage('Saving Image');
      await CallOM("SaveCustomerProfileImage",0,0,20,uploadFile,this.state.ImageType); // 17
      trace(`Saved Image ${this.state.UploadFile.length}`);
      this.isSavingDocument = false;
    }
  }
  //------------------------- End of Image Processing - Save this for Credit Card? ------------------------------------------------------------------  
  //-------------------------- Receipt Listing --------------------------------------------------------------------------------------------------------------------------------- 
  getCurrentTotal = async (showAll) => {
    let CD;
    //---------------       
    if (showAll === undefined)
      showAll = SessionInfo.ShowAllOrders;
    if (showAll === false)
      CD = await CallOM("GetCustomOrderTotal",0,SessionInfo.CustomOrderID,0);
    else {
      trace(`--> Get Totals - PayTheseOrders: ${JSON.stringify(SessionInfo.PayTheseOrders)}`);
      CD = await CallOM("GetAllCustomOrdersTotal",0,SessionInfo.CustomOrderID,CTYP.INTA,SessionInfo.PayTheseOrders);
    }
    if (CD.x.o < 9500) {
      try {
        let Row = CD.d;
        trace(`CallgetCurrentTotal showAll: ${showAll} Return: ${JSON.stringify(Row)} `);
        let TotalPrice = Row.TotalPrice;
        this.setState({ TotalPrice });
        let TotalTaxAmount = Row.TotalTaxAmount;
        this.setState({ TotalTaxAmount });
        let TotalPriceWithTax = TotalPrice + TotalTaxAmount;
        //trace(`getCurrentTotal - showAll: ${showAll}, TotalPrice: ${TotalPrice}`);
        this.setState({ TotalPriceWithTax });
        //this.setState({ TipAmount: Row["TipAmount"] });
        this.setState({ AmountPaid: Row.AmountPaid });
        this.setState({ AmountOwing: Row.AmountOwing });
        this.AmountOwing = Row.AmountOwing;
        let ItemCount = Row.ItemCount;
        this.setState({ ItemCount });
        this.setState({ CustomOrderType: Row.CustomOrderType });
        this.setState({ RequestDateTime: Row.RequestDateTime });
        if (Row.CustomOrderType == 1)
          this.getOrderAddress();
        trace(`CheckoutPage - OrderType: ${SessionInfo.OrderType}, CustomOrderType: ${Row.CustomOrderType}`);
        SessionInfo.OrderType = Row.CustomOrderType;
        if (Row.CustomOrderType === 3) { // Scanner
          this.setState({ isReadyToPay: true });
        }
      } catch (error) {
        await OMTrace(2,`Failure in getCurrentTotal - Error: ${error}`);
      }
    }
  }
  getOrderAddress = async () => {
    let CD = await CallOM("GetCustomOrderAddress",0,SessionInfo.CustomOrderID,0);
    trace(`CustomOrder Address: ${JSON.stringify(CD)}`);
    let row = CD.d;
    //this.loadAddressFields(row); 
    let Address = '';
    if (row["UnitNumber"] != '')
      Address = `${row["UnitNumber"]} ${row["Address"]}, ${row["City"]}`;
    else
      Address = `${row["Address"]}, ${row["City"]}`;
    this.setState({ Address });
  }
  loadAddressFields(row) {
    trace(`load AddressFields`);
    // Retrieved Value Assignment for: Addresses
    AddressesO.forEach(elem => {
      if (elem.n in row) {
        //trace(`Set ${elem.n}: ${row[elem.n]}`);
        let sobj = {};
        try {
          if (elem.t === 34 || elem.t === 35) { // Date, DateTime
            sobj[elem.n] = new Date(row[elem.n]); // For use with DateTime Control
            //sobj[elem.n] = row[elem.n];
            AV[elem.n] = new Date(row[elem.n]);
          } else if (elem.t === 21 || elem.t === 195 || elem.t === 201) { // String, FK-Translated, Enum
            sobj[elem.n] = row[elem.n];
            let sobjS = {};
            sobjS[elem.n + 'S'] = row[elem.n];
            //trace(`Set String Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
            this.setState(sobjS); // Set the String shadow var
            AV[elem.n] = row[elem.n];
          } else if (elem.t === 22) { // Bool
            let val = row[elem.n].toString().charAt();
            if (val === '1' || val === 't' || val === 'T') {
              sobj[elem.n] = true;
              AV[elem.n] = true;
            } else {
              sobj[elem.n] = false;
              AV[elem.n] = false;
            }
          } else if (elem.t === 23) { // Decimal
            if (elem.n.indexOf('Percent') >= 0 || elem.n.indexOf('Markup') >= 0) {
              sobj[elem.n] = row[elem.n];
              let sobjS = {};
              sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
              this.setState(sobjS); // Set the String shadow var
              AV[elem.n] = row[elem.n];
            } else { // Decimal
              sobj[elem.n] = row[elem.n];
              AV[elem.n] = row[elem.n];
            }
          } else if (elem.t === 28) { // Money
            sobj[elem.n] = row[elem.n];
            let sobjS = {};
            sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
            //trace(`Set Money Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
            this.setState(sobjS); // Set the String shadow var
            AV[elem.n] = row[elem.n];
          } else if (elem.t === 41) { // Media - Image or Document
            sobj[elem.n] = row[elem.n];
            AV[elem.n] = row[elem.n];
            if (elem.n === 'ImageID')
              currentImageID = row[elem.n];
          } else if (elem.t === 15) { // Encoded
            //------ Assign Encoded Values ------

            let AList = [];

            let sobjV = {};
            sobjV[`${elem.n}Vals`] = AList; // Set the Vals Array
            this.setState(sobjV); // Set the Vals var
            sobj[elem.n] = row[elem.n];
            //obj[elem.n] = row[elem.n];
            AV[elem.n] = row[elem.n];
            trace(`Encoded sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${AV[elem.n]}`);
          } else { // Default - Number
            sobj[elem.n] = row[elem.n];
            AV[elem.n] = row[elem.n];
          }
          //trace(`sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${AV[elem.n]}`);
          if (this.state[elem.n] === undefined)
            trace(`=== ${elem.n} is not defined in state ===`);
          this.setState(sobj);
          // Add item specific code here
          //if (elem.n === 'ImageID')
          //  ImageID = row[elem.n];
        }
        catch (error) {
          trace(`Set AddressesO Field: ${elem.n}, Error: ${error.message}`);
        }
      }
    });
  }
  onAddMore = () => {
    trace(`this.returnTo: ${this.returnTo}, OrderType: ${SessionInfo.OrderType}`);
    if (SessionInfo.OrderType === 3)
      this.props.navigate("/ScannerPage");
    else
      this.props.navigate("/OrderPage");
    SessionInfo.CheckoutPage = undefined;
  }
  onRefresh = async () => {
    await this.getOrderItemRecords(); // calls getcurrentTotal(); getSeatInfo() - This initializes payThisOrder
  }
  onAbandonOrder = async () => {
    this.setState({ showVerifyCancel: true });
  }
  verifiedCancelOrder = async () => {
    let CD = await CallOM("CancelOrder",0,SessionInfo.CustomOrderID,0);
    this.setState({ showVerifyCancel: false });
    SessionInfo.CustomOrderID = 0;
    SessionInfo.BillPaymentComplete = true; // Possible that not All orders have been paid
    SessionInfo.BillPaymentDone = false;
    SessionInfo.ResetOrder = true;
    trace(`CancelOrder - Reset: ${SessionInfo.ResetOrder}`);
    SessionInfo.CancelOrder = true;
    CD = await CallOM("GetTableInfoForOrder",1,SessionInfo.CustomOrderID,0);
    if (CD && CD.d) {
      trace(`After Cancel - seat count: ${CD.d.SeatCount}`);
      this.setState({ SeatCount: CD.d.SeatCount });
      SessionInfo.SeatCount = CD.d.SeatCount;
      SessionInfo.CurrentOrderCustomerID = CD.d.CustomerID;
    }
    if (SessionInfo.OrderType === 3)
      this.props.navigate("/ScannerPage");
    else
      this.props.navigate("/OrderPage");
  }
  onVoidOrder = async () => {
    this.setState({ showOrderQRCode: true });
    //this.setState({ voidItem: true });
  }
  onVoidOrderItems = async () => {
    this.setState({ showOrderQRCode: false });
    this.setState({ voidItem: true });
  }
  skipCancel = async () => {
    this.setState({ showVerifyCancel: false });
  }
  onShowAllOrders = async () => {
    trace("Show All Orders");
    this.setState({ showAllOrders: true });
    SessionInfo.ShowAllOrders = true;
    await this.getOrderItemRecords();
  }
  onShowSingleOrders = async () => {
    trace("Show My Order");
    this.setState({ showAllOrders: false });
    SessionInfo.ShowAllOrders = false;
    await this.getOrderItemRecords();
  }
  onSubmitOrder = async () => {
    if (this.state.showAllOrders === true) {
      trace("Submit ALL Orders");
      let CD = await CallOM("SubmitOrders",0,SessionInfo.CustomOrderID,0);
      if (CD.x.o === 0)
        await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
      else if (CD.x.o < 9500) {
        displayMessage("Orders Submitted");
      }
      this.setState({ showAllOrders: true });
      SessionInfo.ShowAllOrders = true;
      await this.getOrderItemRecords();
    }
    else {
      trace("Submit Order");
      let CD = await CallOM("SubmitOrder",0,SessionInfo.CustomOrderID,0);
      if (CD.x.o === 0)
        await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
      else if (CD.x.o < 9500) {
        trace("Order Submitted");
        //displayMessage("Order Submitted"); // I don't think that this is necessary - and gets in the way
      }
      this.setState({ showAllOrders: false });
      SessionInfo.ShowAllOrders = false;
      await this.getOrderItemRecords();
    }
  }
  //----------------------------------------------------------------
  SeatItemFormat = props => {
    let item = props.dataItem;
    this.OptionsPrice = item.OptionsPrice;
    //trace(`DisplaySeats: ${this.DisplaySeats}`);
    if (this.DisplaySeats === false)
      return (<div></div>);
    trace(`Display Seat item: ${JSON.stringify(item)}`);
    //trace(`item: ${JSON.stringify(item.SpecialInstruction)}`);
    return (
      <div className="listVertContainer">
        <div className="listCHContainer">
          <div className="boxCH1">
            <span style={{ fontSize: 16,fontWeight: 'bold' }} > {item.ItemName}</span>
            <br />
            <span>{shortDesc(item.ItemDescription,70)}</span>
          </div>
          <div className="boxCH2">
            <span style={{ float: 'right' }} >{moneyFormat(item.Price)}</span><br />
            {item.OptionsPrice > 0 ?
              <><span style={{ color: "darkblue",fontWeight: 'bold',float: 'right' }} >{moneyFormat(item.OptionsPrice)}</span><br /></> : null}
            {item.RateTypeID === 1 ?
              <>
                {item.Quantity > 1 ? <><span style={{ float: 'right' }}>x {item.Quantity}</span><br /> </> : null}
              </> : <>
                <span style={{ float: 'right', fontSize: "12px" }}>{item.Weight} grams</span>
              </>}
            {item.Price != item.TotalPrice ?
              <span style={{ fontSize: 16,color: "black",fontWeight: 'bold',float: 'right' }} >{moneyFormat(item.TotalPrice)}</span> : null}
          </div>
          <div className="boxCH3">
            {this.state.voidItem ?
              <ButtonQRCode className="boxCH3Icon" fill={this.activeColor} alt="" id="ShowItem" title="Void This Item" onClick={(e) => this.displayItemQRCode(item)} /> :
              <div>
                {item.OrderItemStatus <= 1 ?
                  <ButtonDelete className="boxCH3Icon" fill={this.maroon} alt="" id="DeleteItemBtn" title={`Remove Item ${item.ItemName} from the Order`} onClick={(e) => this.onDeleteTest(item)} /> :
                  <>
                    {item.OrderItemStatus <= 4 ?  // ActiveItem (+InventoryLocked,InventoryDone)
                      <ButtonKitchen className="boxCH3Icon" fill={this.activeColor} alt="" id="KitchenInd" title="Item Submitted" onClick={(e) => this.showSubmitted(item)} /> :
                      <>
                        {item.OrderItemStatus === 5 ?  // InProgress
                          <ButtonInProgress className="boxCH3Icon" fill={this.inProgressColor} alt="" id="CookingInd" title="Active Item" onClick={(e) => this.showSubmitted(item)} /> :
                          <>
                            {item.OrderItemStatus >= 6 ?  // Ready
                              <ButtonDelivered className="boxCH3Icon" fill={this.deliveredColor} alt="" id="DeliveredInd" title={`Item ${item.ItemName} Served `} onClick={(e) => this.showSubmitted(item)} /> : null
                            }</>
                        }</>
                    }</>
                }
              </div>}
          </div>
        </div>
        {item.OptionCount > 0 ?
          <div className="optionListCH" >
            <ListView data={item.options} item={this.ItemOptionFormat} header={this.ItemOptionHeader} footer={this.ItemOptionFooter} />
          </div> : null}
        {item.SpecialInstruction != undefined && item.SpecialInstruction != '' ?
          <div className="itemSpecial" >
            <span>Special Instructions</span>
            <br />
            <div className="itemSpecialInst">{item.SpecialInstruction}</div>
          </div> : null}
      </div>
    );
  }
  ItemOptionHeader = props => {
    return (
      <ListViewHeader className='listOptionHeader'>
        <span>Customizations</span>
      </ListViewHeader>
    );
  }
  ItemOptionFormat = props => {
    let item = props.dataItem;
    //trace(`DisplaySeats: ${this.DisplaySeats}`);
    if (this.DisplaySeats === false)
      return (<div></div>);
    trace(`Item Option Format: ${JSON.stringify(item)}`);
    return (
      <div className="optionCHContainer">
        <div className="boxCH4">
          {/*<span style={{ fontSize: 12,color: "black" }} >XXX {JSON.stringify(item)}</span>*/}
          <span > {item.GrpName}</span>
        </div>
        <div className="boxCH5">
          <span > {item.POName}</span>
        </div>
        <div className="boxCH6">
          {item.Price != '$0.00' ?
            <span > {item.Price}</span> : null}
        </div>
      </div>
    );
  }
  ItemOptionFooter = () => {
    return (
      <ListViewFooter className='listOptionFooter'>
        {this.OptionsPrice > 0 ?
          <div style={{ float: 'right',height: '24px' }}>Customizations Total: &nbsp;<span style={{ fontWeight: 'bold',float: 'right' }}>{moneyFormat(this.OptionsPrice)}&nbsp;</span></div> : null}
      </ListViewFooter>
    );
  }
  //-----------------------------------------------------------------
  onDeleteTest = props => {
    this.setState({ DeleteItemName: props.ItemName });
    this.setState({ DeleteItemDescription: props.ItemDescription });
    this.setState({ DeleteItemCount: props.Quantity });
    this.deleteCustomOrderItemID = props.CustomOrderItemID;
    //trace(`OrderItems: ${JSON.stringify(this.state.OrderItems)}`);
    trace(`OrderItemID: ${props.CustomOrderItemID}`);
    let ioOffset = 0;
    for (let io = 0; io < this.state.OrderItems.length; io++) {
      for (let ix = 0; ix < this.state.OrderItems[io].length; ix++) {
        trace(`OrderItemID: ${this.state.OrderItems[io][ix].CustomOrderItemID} - ix: ${ix}, io: ${io}`);
        if (this.state.OrderItems[io][ix].CustomOrderItemID === props.CustomOrderItemID) {
          trace(`found OrderItemID - ix: ${ix}, io: ${io}`);
          this.deletePopupOffset.top = ix * 65 + 200;
          break;
        }
        else
          ioOffset += this.state.OrderItems[io].length * 60;
      }
    }
    this.setState({ showDeleteItem: true });
  }
  doneDelete = props => {
    this.deleteCustomOrderItemID = 0;
    this.setState({ showDeleteItem: false });
  }
  onDeleteOrderItem = async (props) => {
    this.setState({ showDeleteItem: false });
    //displayError(`Delete Item: ${JSON.stringify(props.ItemDescription)}`);
    trace(`Delete ID: ${JSON.stringify(this.deleteCustomOrderItemID)}`);
    if (this.deleteCustomOrderItemID > 0) {
      this.setState({
        OrderItems: this.state.OrderItems.filter(e => e.CustomOrderItemID !== props.CustomOrderItemID)
      });
      await this.deleteOrderStoreItem(this.deleteCustomOrderItemID);
      //if (this.state.showAllOrders === false)
      //  await this.getOrderItemRecords();
      //else
      await this.getOrderItemRecords();
      SessionInfo.RefreshOrder = true;
    }
    this.deleteCustomOrderItemID = 0;
  }
  deleteOrderStoreItem = async (customOrderItemID) => {
    trace(`DeleteOrderItem - ID: ${customOrderItemID}`);
    await CallOM("DeleteOrderItem",0,customOrderItemID,0);
    await this.getCurrentTotal(SessionInfo.ShowAllOrders);
  }
  showSubmitted = props => {
    trace(`Order for item sent to the kitchen`);
    trace(`Order for ${props.ItemName} sent to the kitchen`);
    displayMessage(`Order for ${props.ItemName} sent to the kitchen`);
  }
  displayItemQRCode = (item) => {
    trace(`display Item: ${JSON.stringify(item)}`);
    this.deleteCustomOrderItemID = item.CustomOrderItemID;
    this.itemName = item.ItemName;
    this.setState({ showItemQRCode: true });
  }
  closePopups = (code) => {
    this.setState({ showNotify: false });
    //this.setState({ showCancelOrder: false });
    this.setState({ showVerifyCancel: false });
    this.setState({ showItemChangePopup: false });
    this.setState({ showVerifyItemDelete: false });
    this.setState({ showReviewPurchasePopup: false });
    this.setState({ showOrderQRCode: false });
    this.setState({ showItemQRCode: false });
    this.setState({ showPayCash: false });
    this.setState({ showScanQRCode: false });
    this.setState({ voidItem: false });
  }
  payCash = async () => {
    let PaymentDetails = [{ 'CustomOrderID': SessionInfo.CustomOrderID }];
    // NOTE - this does not really work - fake it - it will take more than this
    trace(`Payment - Cash - Order ID: ${this.state.CustomOrderID},${SessionInfo.CustomOrderID}, Amt Owing: ${this.state.AmountOwingWithTip}, Tip: ${this.state.TipAmount}, Pay Amt: ${this.state.ActualPaymentAmount}, type: ${this.state.PaymentTypeID}, Pay Method: ${this.state.PaymentMethodID}, All: ${SessionInfo.ShowAllOrders}`);
    let ActPayAmount = this.state.ActualPaymentAmount;
    // Convert this to token pay
    //let encToken = xorEncode("1|" + this.state.PaymentTypeID + '|' + this.state.PaymentMethodID,SessionInfo.Key);
    // change to PayCustomOrderToken
    let cash = 30;
    let payMethod = 0; // No recorded paymentmethod   
    let CD;
    if (SessionInfo.ShowAllOrders === false) {// Pay single Order
      CD = await CallOMP("PayCustomOrder",SessionInfo.CustomOrderID,parseFloat(this.state.ActualPaymentAmount),parseFloat(this.state.TipAmount),cash,payMethod,"","");
    } else {
      CD = await CallOM("PayAllCustomOrders",0,SessionInfo.CustomOrderID,CTYP.TypParm,[TYP.INT,SessionInfo.CustomOrderID,TYP.DEC,this.state.ActualPaymentAmount,TYP.DEC,this.state.TipAmount,TYP.INT,cash,TYP.INT,payMethod,TYP.STR,"",TYP.STR,"",TYP.IAR,SessionInfo.PayTheseOrders]);
    }
    if (CD.x.o >= 9500) {
      displayMessage(`Cash Payment Failed: ${CD.x.m}`);
      trace(`Cash Payment Failed: ${CD.x.m}, o: ${CD.x.o}`);
    } else { //--- Cash Payment ---------------------------                       
      let AmountOwing = CD.d.AmountOwing;
      let AmountOwingWithTip = CD.d.AmountOwingWithTip;
      trace(`==>Pay-7 - AmountOwingWithTip: ${this.state.AmountOwingWithTip},${AmountOwingWithTip},${AmountOwing},  ActualPaymentAmount: ${this.state.ActualPaymentAmount},${ActPayAmount}, CD: ${JSON.stringify(CD.d)}`);
      if (AmountOwingWithTip > 0) { // Go Back to Review Purchase   
        this.setState({ AmountOwing: CD.d.AmountOwing });
        this.setState({ AmountOwingWithTip: CD.d.BillWithTip });
        this.setState({ AmountPaid: CD.d.AmountPaid });
        this.setState({ ActualPaymentAmount: CD.d.AmountOwingWithTip.toFixed(2) });
        this.setState({ ActualPaymentAmountS: this.moneyFormat(CD.d.AmountOwingWithTip.toFixed(2)) });
        this.setState({ showPayCash: false });
        this.setState({ showReviewPurchasePopup: true }); // Show status of payment - get remaining
      } else { // If AmountOwing is paid - exit CheckoutPage
        trace(`--- onPay Payment - Owing: ${AmountOwingWithTip}`);
        //await this.makePayment(PaymentDetails); // 17Apr24 - I don't think this should be here anymore
        SessionInfo.ResetOrder = true;
        trace(`onPay - Reset: ${SessionInfo.ResetOrder}`)
        SessionInfo.BillPaymentComplete = true; // Possible that not All orders have been paid
        SessionInfo.BillPaymentDone = true;
        this.closePopups(8);
        if (SessionInfo.OrderType === 3)
          this.props.navigate("/ScannerPage");
        else
          this.props.navigate("/OrderPage");
      }
    }
  }
  //--------------------------Payment Cards ---------------------------------------------------------------------------------------------------------------------------------
  onAddPayment = async () => {
    window.scrollTo(0,0);
    if (this.state.AmountOwing === 0) { // Bill has been paid
      if (SessionInfo.OrderType === 3)
        this.props.navigate("/ScannerPage");
      else
        this.props.navigate("/OrderPage");
    }
    if (SessionInfo.bamboraOffline === true)
      SessionInfo.PaymentProc = "X";
    else
      SessionInfo.PaymentProc = "B"; // Bambora - Note, only PCI compliant one
    trace(`Payment Type: ${SessionInfo.PaymentProc}`);
    //trace(`==> AppCfg: ${JSON.stringify(SessionInfo.AppCfg)}`);
    SessionInfo.firstDigit = '';
    if (SessionInfo.PaymentProc === "F")
      this.setState({ showPaymentFull: true });
    else if (SessionInfo.PaymentProc === "P")
      this.setState({ showPaymentPostal: true });
    else if (SessionInfo.PaymentProc === "S")
      this.setState({ showPaymentShort: true });
    else if (SessionInfo.PaymentProc === "M")
      this.setState({ showPaymentMinimal: true });
    else if (SessionInfo.PaymentProc === "X") {
      //this.setState({ showPaymentXMinimal: true });
      await this.goToPurchase();
    } else if (SessionInfo.PaymentProc === "B") { // Bambora Payment
      SessionInfo.BamToken = '';
      SessionInfo.BamProc = false;
      if (SessionInfo.GetBamboraTokenFirst === true) { // Show Bambora CC Input and Get Token First 
        await this.setState({ showPaymentBambora: true });
        this.InitBamScript();
      }
      else { // Get Token After
        await this.goToPurchase();
      }
    }
    else {
      trace(`Invalid Payment Proc: ${SessionInfo.PaymentProc}`,5);
      this.setState({ showPaymentMinimal: true });
    }
  }
  cancelPay = () => {
    this.setState({ showPaymentBambora: false });
  }
  PaymentHeader = () => {
    return (
      <ListViewHeader className='pl-4 pb-2 pt-2'>
        {SessionInfo.defaultLogon === true ?
          <div> <br /><br /> </div> :
          <div style={{ marginTop: '5px',marginBottom: '5px' }}>
            <span style={{ fontSize: 18,fontWeight: 'bold',color: "darkblue" }} >Saved Payment Cards</span>
          </div>}
      </ListViewHeader>
    );
  }
  PaymentMethodFormat = props => {
    let item = props.dataItem;
    //trace("PaymentMethodFormat");
    //trace(props);
    //let imageVal = undefined;
    //if (item["Image"]) {
    //  let value = item["Image"];
    //  //trace("ImageCell value: " + value);
    //  const byteCharacters = atob(value);
    //  const byteNumbers = new Array(byteCharacters.length);
    //  for (let i = 0; i < byteCharacters.length; i++) {
    //    byteNumbers[i] = byteCharacters.charCodeAt(i);
    //  }
    //  const byteArray = new Uint8Array(byteNumbers);
    //  let image = new Blob([byteArray], { type: 'image/jpeg' });
    //  //trace("image: " + JSON.stringify(image));
    //  imageVal = URL.createObjectURL(image);
    //}
    return (
      <div className="listRow">
        <div className="listColPayM1">
          <span style={{ fontSize: 16,color: "black" }} >{item.Identifier}</span><br />
          <span style={{ fontSize: 14,color: "black" }} >{item.CardNickName}</span>
        </div>
        <div className="listColPayM2">
          {item.PaymentTypeID === 'Visa' ? <ButtonVisa fill={this.activeColor} alt="" id="VisaBtn" title="Item Submitted" className="paymentCard" onClick={(e) => this.onMakePayment(item)} /> : null}
          {item.PaymentTypeID === 'MasterCard' ? <ButtonMC fill={this.activeColor} alt="" id="MCBtn" title="Item Submitted" className="paymentCard" onClick={(e) => this.onMakePayment(item)} /> : null}
          {item.PaymentTypeID === 'American Express' ? <ButtonAmex fill={this.activeColor} alt="" id="AmexBtn" title="Item Submitted" className="paymentCard" onClick={(e) => this.onMakePayment(item)} /> : null}
          {item.PaymentTypeID === 'Discover' ? <ButtonDiscover fill={this.activeColor} alt="" id="DiscoverBtn" title="Item Submitted" className="paymentCard" onClick={(e) => this.onMakePayment(item)} /> : null}
        </div>
        <div className="listColPayM3">
          {/*<img src={deleteIcon} alt="" id="GridRefresh" title="Delete the current entry" className="functionImage" onClick={(e) => true && this.onMakePayment(item)} />*/}
          <Button icon="reset" className="largeButton" style={{ top: 5,}} onClick={(e) => true && this.onMakePayment(item)}>PAY</Button>
        </div>
      </div>
    );
  }
  PaymentFooter = () => {
    return (
      <ListViewFooter className='listFooter3'>
        <div>
          <div>
            {SessionInfo.defaultLogon === true ?
              <Button id="addPaymentButton" icon="calculator" look="outline" className="centerButton" onClick={this.onAddPayment} >&nbsp;&nbsp; PAY CHECK &nbsp;&nbsp;</Button>
              :
              <Button id="addPaymentButton" icon="calculator" look="outline" className="centerButton" onClick={this.onAddPayment} >&nbsp;&nbsp; ADD PAYMENT METHOD &nbsp;&nbsp;</Button>}
          </div>
        </div>
      </ListViewFooter>
    );
  }
  //---- Make Payment
  onMakePayment = props => {
    //trace(`payment props: ${JSON.stringify(props, null, 2)}`);
    //displayError(`Make Payment Item: ${JSON.stringify(props.CardNickName)}`);
    trace(`Pay with ID: ${JSON.stringify(props)}`);
    SessionInfo.PaymentMethodID = props.PaymentMethodID;
    SessionInfo.PaymentTypeID = props.PaymentTypeID;
    this.completeReview(props);
  }

  completeReview = async (props) => { // Complete Orders and Goto Review Payment
    trace(`MakePayment Customer OrderID: ${SessionInfo.CustomOrderID}`);
    if (SessionInfo.CustomOrderID > 0) {
      try {
        let payCnt = 0;
        for (let i = 0; i < 10; i++)
          if (SessionInfo.PayTheseOrders[i] !== 0)
            payCnt++;
        if (payCnt === 0) {
          displayMessage(`Select Order(s) to Pay`);
        } else {
          trace(`Goto ReviewPurchasePage - CustomOrderID: ${SessionInfo.CustomOrderID}, payAll: ${this.state.showAllOrders}`);
          if (this.state.showAllOrders === false) {
            await CallOM("CompleteCustomOrder",0,SessionInfo.CustomOrderID,CTYP.TypParm,[TYP.INT,SessionInfo.CustomOrderID,TYP.INT,6,TYP.STR,""]);
          } else {
            await CallOM("CompleteCustomOrders",0,SessionInfo.CustomOrderID,CTYP.TypParm,[TYP.INT,SessionInfo.CustomOrderID,TYP.INT,6,TYP.STR,"",TYP.IAR,SessionInfo.PayTheseOrders]);
          }
          await this.goToPurchase();
        }
      }
      catch (Ex) {
        await OMTrace(2,`Failure in Card Select Payment - Error: ${Ex}`);
      }
    }
  }
  chgPayOrder = async (event) => {
    //event.preventDefault();
    let name = event.target.name;
    let num = parseInt(name.substring(12,13));  // name: PayThisOrder0
    if (this.state.showAllOrders === true) {
      trace(`change Pay Order: ${JSON.stringify(name)}, number: ${num}`);
      trace(`PayTheseOrders: ${JSON.stringify(SessionInfo.PayTheseOrders)}`);
      let payOs = [false,false,false,false,false,false,false,false,false,false];
      for (let i = 0; i < 10; i++) {
        if (SessionInfo.PayTheseOrders.indexOf(this.currentOrderIDs[i]) >= 0)
          payOs[i] = true;
        else
          payOs[i] = false;
      }
      // Invert switch and save to state
      if (payOs[num] === true) {
        if (this.currentOrderIDs[num] != SessionInfo.CustomOrderID) { // Dont switch if Current Order
          payOs[num] = false;
          this.setState({ payThisOrder: payOs });
        }
      } else {
        payOs[num] = true;
        this.setState({ payThisOrder: payOs });
      }
      // Set PayTheseOrders according to the order-of CustomOrders - based on payThisOrder=true - in currentOrderIDs
      for (let i = 0; i < 10; i++)
        SessionInfo.PayTheseOrders[i] = 0;
      let p = 0;
      //trace(`payOs: ${JSON.stringify(payOs)}`);
      for (let i = 0; i < 10; i++) {
        //trace(`i: ${i}, p: ${p}, pay: ${payOs[i]}, order: ${this.currentOrderIDs[i]}`);
        if (payOs[i] === true)
          SessionInfo.PayTheseOrders[p++] = this.currentOrderIDs[i];
      }
      trace(`change Pay Order: ${JSON.stringify(name)}, number: ${num}, pay: ${JSON.stringify(SessionInfo.PayTheseOrders)}, yes: ${JSON.stringify(SessionInfo.payThisOrder)}`);
      await this.getCurrentTotal(SessionInfo.showAllOrders);
    }
  }
  payAllOrders = async (event) => {
    //event.preventDefault();
    SessionInfo.showAllOrders = true;
    this.setState({ showAllOrders: true });
    let payOs = [false,false,false,false,false,false,false,false,false,false];
    for (let i = 0; i < 10; i++) {
      if (SessionInfo.PayTheseOrders.indexOf(this.currentOrderIDs[i]) >= 0)
        payOs[i] = true;
      else
        payOs[i] = false; // No Order
    }
    this.setState({ payThisOrder: payOs });
    // Set PayTheseOrders according to the order-of CustomOrders - based on payThisOrder=true - in currentOrderIDs
    for (let i = 0; i < 10; i++)
      SessionInfo.PayTheseOrders[i] = 0;
    let p = 0;
    trace(`payOs: ${JSON.stringify(payOs)}`);
    for (let i = 0; i < 10; i++) {
      //trace(`i: ${i}, p: ${p}, pay: ${payOs[i]}, order: ${this.currentOrderIDs[i]}`);
      if (payOs[i] === true)
        SessionInfo.PayTheseOrders[p++] = this.currentOrderIDs[i];
    }
    await this.getCurrentTotal(SessionInfo.showAllOrders);
  }

  onPayOrder = async () => {
    trace(`Pay for Order - MakePayment Customer OrderID: ${SessionInfo.CustomOrderID}`);
    if (SessionInfo.CustomOrderID > 0) {
      try {
        trace(`Goto ReviewPurchasePage - CustomOrderID: ${SessionInfo.CustomOrderID}, payAll: ${this.state.showAllOrders}`);
        await CallOM("CompleteCustomOrder",0,SessionInfo.CustomOrderID,CTYP.TypParm,[TYP.INT,SessionInfo.CustomOrderID,TYP.INT,6,TYP.STR,""]);
        await this.goToPurchase();
      }
      catch (Ex) {
        await OMTrace(2,`Failure in Card Select Payment - Error: ${Ex}`);
      }
    }
  }

  onDeletePaymentItem = props => {
    displayError(`Delete Payment Item: ${JSON.stringify(props.CardNickName)}`);
    trace(`Delete ID: ${JSON.stringify(props.PaymentMethodID)}`);
    this.setState({
      PaymentMethods: this.state.PaymentMethods.filter(e => e.PaymentMethodID !== props.PaymentMethodID)
    });
    this.deleteOrderStoreItem(props.CustomOrderItemID);
  }
  cancelPayment = () => {
    this.setState({ showPaymentFull: false });
    this.setState({ showPaymentPostal: false });
    this.setState({ showPaymentShort: false });
    this.setState({ showPaymentMinimal: false });
    this.setState({ showPaymentXMinimal: false });
    this.setState({ showPaymentBambora: false });
  }
  processPayment = async () => {
    //console.log(`card: ${this.state.number}, ${this.state.cvc}`);
    let dataItem = {};
    dataItem.Identifier = this.state.number;
    dataItem.CCV = this.state.cvc;
    dataItem.ExpiryDate = this.state.expiry;
    trace(`Payment Card process - dataItem: ${JSON.stringify(dataItem)}`);
    this.makePaymentDirectly(dataItem);
    await this.goToPurchase();
    this.setState({ showPaymentShort: false });
  }
  processPaymentXMinimal = async () => {
    //console.log(`card: ${this.state.number}, ${this.state.cvc}`);
    let dataItem = {};
    if (this.numok && this.cvcok && this.expok) {
      dataItem.Identifier = this.state.number;
      dataItem.CCV = this.state.cvc;
      dataItem.ExpiryDate = this.state.expiry;
      //dataItem['Identifier'] = this.state.number;
      //dataItem['CCV'] = this.state.cvc;
      //dataItem['ExpiryDate = this.state.expiry;
      trace(`Payment Card process - dataItem: ${JSON.stringify(dataItem)}`);
      //this.makePaymentDirectly(dataItem);
      this.onRegularTokenPay(dataItem);
      await this.goToPurchase();
      this.setState({ showPaymentXMinimal: false });
    }
  }
  //-----------------------------------------------------------------------------------------------------------------------
  // ReviewPurchase Code
  //-----------------------------------------------------------------------------------------------------------------------

  formatMoney = (amount) => {
    let decimalCount = 2;
    const decimal = ".";
    const thousands = ",";
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substr(0,j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g,"$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      trace(`format money: ${e}`,10)
    }
  }
  percentFormat = (value) => new Intl.NumberFormat('en-US',{ style: 'percent',minimumFractionDigits: 2,maximumFractionDigits: 2 }).format(value);
  moneyFormat = (value) => new Intl.NumberFormat('en-US',{ style: 'currency',currency: 'USD' }).format(value);
  datetimeFormat = (value) => new Intl.DateTimeFormat('en-US',{ weekday: 'short',year: 'numeric',month: 'short',day: 'numeric',hour: 'numeric',minute: 'numeric' }).format(value);
  //        
  getCustomOrder = async (event) => {
    trace(`getCustomOrder - ReviewPurchase - SessionInfo.CustomOrderID: ${SessionInfo.CustomOrderID}, Type: ${SessionInfo.OrderType}`);
    let CD;
    this.setState({ ActualPaymentAmountS: '' });
    this.setState({ ActualPaymentAmount: 0 });
    this.setState({ TipAmountOverDS: '' });
    this.setState({ TipAmountOverD: 0 });
    this.setState({ TipAmountOverPS: '' });
    this.setState({ TipAmountOverP: 0 });
    this.setState({ TipAmount: 0 });
    //trace(`GetCustomOrder Total - show all: ${SessionInfo.ShowAllOrders}, CustomOrderID: ${SessionInfo.CustomOrderID}`);
    // Note - fetch orders and mark it, or all of them, PaymentInProcess - code 100
    if (SessionInfo.ShowAllOrders === false)
      CD = await CallOM("GetCustomOrderTotal",100,SessionInfo.CustomOrderID,0);
    else
      CD = await CallOM("GetAllCustomOrdersTotal",100,SessionInfo.CustomOrderID,CTYP.INTA,SessionInfo.PayTheseOrders);
    if (CD.x.o === 0) {
      trace(`GetOrderTotal Fail o: ${CD.x.o}`);
      await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
    } else if (CD.x.o < 9500) {
      try {
        let row = CD.d;
        trace(`after Get Order Total: ${JSON.stringify(row)}`);
        CustomOrdersO.forEach((elem) => {
          if (elem.n in row) {
            //trace(`Set ${elem.n}: ${row[elem.n]}`);
            let sobj = {};
            try {
              if (elem.t === 34 || elem.t === 35) // Date, DateTime
                sobj[elem.n] = new Date(row[elem.n]);
              else if (elem.t === 28) {
                sobj[elem.n] = row[elem.n];
                let sobjS = {};
                sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
                //trace(`Set Money Field: ${elem.n + 'S'} Value: ${sobjS[elem.n + 'S']}`);
                this.setState(sobjS);
              }
              else
                sobj[elem.n] = row[elem.n];
              //trace(`sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${COV[elem.n]}`);
              this.setState(sobj);
              COV[elem.n] = row[elem.n];
              // Add item specific code here
              //if (elem.n === "ImageID")
              //  ImageID = row[elem.n];
            } catch (error) {
              trace(`Set CustomOrdersO Field: ${elem.n} Error: ${error.message}`);
            }
          }
        });
        if (COV.CustomOrderType && SessionInfo.OrderType != COV.CustomOrderType)
          SessionInfo.OrderType = COV.CustomOrderType;
        if (SessionInfo.OrderType === 3) { // Scanner Page
          this.setState({ hasTip: false });
        } else {
          this.setState({ hasTip: true });
        }
        this.setState({ PaymentMethodID: SessionInfo.PaymentMethodID }); // MethodID overwritten in get
        this.setState({ stateHasChanged: false });
        if (COV.TipAmount > 0) { // Why??? 23May24
          trace(`COV TipAmount: ${COV.TipAmount}`)
          this.startTipAmount = COV.TipAmount;
          //this.setState({ TipAmount: 0 });
        }
        let TotalBillWithTip = COV.AmountOwing + COV.TipAmount + COV.AmountPaid;
        let AmountOwingWithTip = COV.AmountOwing + COV.TipAmount;
        let TotalPriceWithTax = COV.TotalPrice + COV.TotalTaxAmount; // + COV.TotalTax2Amount + COV.TotalTax3Amount;
        this.setState({ TotalPriceWithTax });
        this.setState({ TotalPriceWithTaxS: this.moneyFormat(TotalPriceWithTax) });
        trace(`TotalPrice: ${COV.TotalPrice}, TotalPriceWithTax: ${TotalPriceWithTax}, S: ${this.moneyFormat(TotalPriceWithTax)}, AmountOwingWithTip: ${AmountOwingWithTip}`);
        this.setState({ AmountOwingWithTip: TotalBillWithTip.toFixed(2) });
        if (this.state.ActualPaymentAmount == 0) {
          this.setState({ ActualPaymentAmount: AmountOwingWithTip.toFixed(2) });
          this.setState({ ActualPaymentAmountS: this.moneyFormat(AmountOwingWithTip.toFixed(2)) });
          this.setState({ PayStyle: { border: '2px solid blue',borderRadius: '5px',} });
        }
      } catch (error) {
        await OMTrace(2,`Failure in getCustomOrder - Error: ${error}`);
      }
    }
  }
  onSetTip1 = () => {
    let TipAmount = this.state.TotalPriceWithTax * this.state.TipRate1 / 100.0;
    this.setState({ TipAmount: TipAmount.toFixed(2) });
    this.setState({ TipAmountOverDS: '' });
    this.setState({ TipAmountOverD: 0 });
    this.setState({ TipAmountOverPS: '' });
    this.setState({ TipAmountOverP: 0 });
    let AmountOwingWithTip = this.state.AmountOwing + TipAmount;
    this.setState({ AmountOwingWithTip: AmountOwingWithTip.toFixed(2) });
    this.setState({ ActualPaymentAmount: AmountOwingWithTip.toFixed(2) });
    this.setState({ ActualPaymentAmountS: this.moneyFormat(AmountOwingWithTip.toFixed(2)) });
    this.setState({ PayStyle: { border: '2px solid blue',borderRadius: '5px',} });
  }
  onSetTip2 = () => {
    let TipAmount = this.state.TotalPriceWithTax * this.state.TipRate2 / 100.0;
    this.setState({ TipAmount: TipAmount.toFixed(2) });
    this.setState({ TipAmountOverDS: '' });
    this.setState({ TipAmountOverD: 0 });
    this.setState({ TipAmountOverPS: '' });
    this.setState({ TipAmountOverP: 0 });
    let AmountOwingWithTip = this.state.AmountOwing + TipAmount;
    this.setState({ AmountOwingWithTip: AmountOwingWithTip.toFixed(2) });
    this.setState({ ActualPaymentAmount: AmountOwingWithTip.toFixed(2) });
    this.setState({ ActualPaymentAmountS: this.moneyFormat(AmountOwingWithTip.toFixed(2)) });
    this.setState({ PayStyle: { border: '2px solid blue',borderRadius: '5px',} });
  }
  onSetTip3 = () => {
    let TipAmount = this.state.TotalPriceWithTax * this.state.TipRate3 / 100.0;
    this.setState({ TipAmount: TipAmount.toFixed(2) });
    this.setState({ TipAmountOverDS: '' });
    this.setState({ TipAmountOverD: 0 });
    this.setState({ TipAmountOverPS: '' });
    this.setState({ TipAmountOverP: 0 });
    let AmountOwingWithTip = this.state.AmountOwing + TipAmount;
    this.setState({ AmountOwingWithTip: AmountOwingWithTip.toFixed(2) });
    this.setState({ ActualPaymentAmount: AmountOwingWithTip.toFixed(2) });
    this.setState({ ActualPaymentAmountS: this.moneyFormat(AmountOwingWithTip.toFixed(2)) });
    this.setState({ PayStyle: { border: '2px solid blue',borderRadius: '5px',} });
  }

  customOrdersPageChange = (event) => {
    this.setState({ customOrdersSkip: event.page.skip,customOrdersTake: event.page.take });
  }
  //--------------------------------------------------------------
  onCancel = async (event) => {
    //this.context.router.history.goBack();
    this.setState({ ActualPaymentAmountS: '' });
    this.setState({ ActualPaymentAmount: 0 });
    this.setState({ TipAmountOverDS: '' });
    this.setState({ TipAmountOverD: 0 });
    this.setState({ TipAmountOverPS: '' });
    this.setState({ TipAmountOverP: 0 });
    this.setState({ TipAmount: 0 });
    SessionInfo.BillPaymentDone = false;
    this.setState({ showReviewPurchasePopup: false });
  }
  //----------------------------------------------------------------------------------------------------------------------------
  // Payment Processing - of various types
  //----------------------------------------------------------------------------------------------------------------------------
  //PayAllCustomOrdersToken(int CustomOrderID, decimal AmountPaid, decimal TipAmount, string BamToken, string Notes, int Code, string Last4, string Expiry, int[] includeOrders)
  onPay = async (event) => { // Note -5Apr24 - OnPay is only called once
    trace(`--> onPay - Actual: ${this.state.ActualPaymentAmount}, Amount Owing: ${this.state.AmountOwingWithTip}`)
    if (this.state.ActualPaymentAmount <= 0) {
      if (this.state.AmountOwingWithTip > 0) {
        displayMessage("Payment Required");
        this.setState({ PayStyle: { border: '2px solid red',borderRadius: '5px',} });
      }
    } else {
      trace(`BamProc: ${SessionInfo.BamProc}, Token: ${JSON.stringify(SessionInfo.BamToken)}, InternalPay: ${SessionInfo.InternalPaymentProcess}`);
      if (SessionInfo.InternalPaymentProcess === true) {
        document.documentElement.style.setProperty('--CardBorderColorN','#c1f7f2');
        document.documentElement.style.setProperty('--CardBorderColorC','#c1f7f2');
        document.documentElement.style.setProperty('--CardBorderColorE','#c1f7f2');
        if (SessionInfo.PaymentProc === "F")
          this.setState({ showPaymentFull: true });
        else if (SessionInfo.PaymentProc === "P")
          this.setState({ showPaymentPostal: true });
        else if (SessionInfo.PaymentProc === "S")
          this.setState({ showPaymentShort: true });
        else if (SessionInfo.PaymentProc === "M")
          this.setState({ showPaymentMinimal: true });
        else if (SessionInfo.PaymentProc === "X") {
          this.setState({ showPaymentXMinimal: true });
        } else {
          this.setState({ showPaymentXMinimal: true });
          //await this.onRegularTokenPay("");
        }
      } else if (SessionInfo.GetBamboraTokenFirst === false) {
        // Get Bambora Token - This will show Bambora Payment screen
        await this.setState({ showPaymentBambora: true });
        this.InitBamScript();
      } else if (SessionInfo.BamProc === true) { // Token based payment
        if (SessionInfo.BamToken.token && SessionInfo.BamToken.token.length > 0)
          await this.onBamboraTokenPay("onPay");
        else
          trace(`Error - invalid token: ${SessionInfo.BamToken.token}`)
      } else if (SessionInfo.defaultLogon === true) { // Regular Payment Processing - Non-Token - No Save Payment Processing - this is a place holder
        //string CardNumber = (string)LO["Identifier"];
        //LO["PaymentTypeID"] = PaymentTypeID;
        //if (LO.ContainsKey("CardNickName") == true) {
        //int ProvinceStateID = CustDS.GetValAsInt("ProvinceStateID");
        //string Province = (string)LO["Province"]; // Should be two characters from form
        //LO["CustomerID"] = CustomerID;
        //  LO["EmailAddress"] = CustDS.GetValAsStr("EmailAddress");
        //  LO["PhoneNumber"] = CustDS.GetValAsStr("PhoneNumber");
        //  LO["PaymentMethodID"] = 0;
        let PaymentDetails = [{ 'CustomOrderID': SessionInfo.CustomOrderID }];
        // NOTE - this does not really work - fake it - it will take more than this
        trace(`Payment - 1 - Order ID: ${this.state.CustomOrderID},${SessionInfo.CustomOrderID}, Amt Owing: ${this.state.AmountOwingWithTip}, Tip: ${this.state.TipAmount}, Pay Amt: ${this.state.ActualPaymentAmount}, type: ${this.state.PaymentTypeID}, Pay Method: ${this.state.PaymentMethodID}, All: ${SessionInfo.ShowAllOrders}`);
        // Convert this to token pay
        let encToken = xorEncode("1|" + this.state.PaymentTypeID + '|' + this.state.PaymentMethodID,SessionInfo.Key);
        let ActPayAmount = this.state.ActualPaymentAmount;
        // change to PayCustomOrderToken      
        let CD;
        if (SessionInfo.ShowAllOrders === false) {// Pay single Order
          CD = await CallOMP("PayCustomOrder",SessionInfo.CustomOrderID,parseFloat(this.state.ActualPaymentAmount),parseFloat(this.state.TipAmount),this.state.PaymentTypeID,this.state.PaymentMethodID,"","");
        } else {
          CD = await CallOM("PayAllCustomOrders",0,SessionInfo.CustomOrderID,CTYP.TypParm,[TYP.INT,SessionInfo.CustomOrderID,TYP.DEC,this.state.ActualPaymentAmount,TYP.DEC,this.state.TipAmount,TYP.INT,this.state.PaymentTypeID,TYP.INT,this.state.PaymentMethodID,,TYP.STR,"",TYP.STR,"",TYP.IAR,SessionInfo.PayTheseOrders]);
        }
        if (CD.x.o >= 9500) {
          displayMessage(`Payment Failed: ${CD.x.m}`);
          trace(`Payment Failed: ${CD.x.m}, o: ${CD.x.o}`);
        } else { //--- Default (No-Logon) Pay ---------------------------                       
          let AmountOwing = CD.d.AmountOwing;
          trace(`==>Pay-1 - AmountOwingWithTip: ${this.state.AmountOwingWithTip},${AmountOwing},  ActualPaymentAmount: ${this.state.ActualPaymentAmount},${ActPayAmount}, CD: ${JSON.stringify(CD.d)}`);
          if (AmountOwing > 0) {
            this.setState({ AmountOwing: CD.d.AmountOwing });
            this.setState({ AmountOwingWithTip: CD.d.BillWithTip });
            this.setState({ AmountPaid: CD.d.AmountPaid });
            this.setState({ ActualPaymentAmount: AmountOwingWithTip.toFixed(2) });
            this.setState({ ActualPaymentAmountS: this.moneyFormat(AmountOwingWithTip.toFixed(2)) });
            this.setState({ showReviewPurchasePopup: true }); // Show status of payment - get remaining
          } else { // If AmountOwing is paid - exit CheckoutPage
            let dict = CD.d;
            trace(`Pay Return: ${JSON.stringify(CD)}`);
            SessionInfo.TotalBill = dict.TotalBill; // AmountOwing + TipAmount (Includes Tax)
            SessionInfo.TotalTip = dict.TotalTip;
            trace(`TotalBill: ${SessionInfo.TotalBill}`);
            trace(`--- onPay Payment`);
            await this.makePayment(PaymentDetails); // 17Apr24 - I don't think this should be here anymore
            SessionInfo.ResetOrder = true;
            trace(`onPay - Reset: ${SessionInfo.ResetOrder}`);
            SessionInfo.BillPaymentComplete = true; // Possible that not All orders have been paid
            SessionInfo.BillPaymentDone = true;
            if (SessionInfo.OrderType === 3)
              this.props.navigate("/ScannerPage");
            else
              this.props.navigate("/OrderPage");
          }
        }
      }
      else { //------------------------------  
        trace(`Payment - 2 - Order ID: ${this.state.CustomOrderID},${SessionInfo.CustomOrderID}, Amt Owing: ${this.state.AmountOwingWithTip}, Tip: ${this.state.TipAmount}, Pay Amt: ${this.state.ActualPaymentAmount}, type: ${this.state.PaymentTypeID}, Pay Method: ${this.state.PaymentMethodID}, All: ${SessionInfo.ShowAllOrders}`);
        let ActPayAmount = this.state.ActualPaymentAmount;
        let paymentTypeID = 4; // Visa
        if (this.state.PaymentTypeID == "MasterCard")
          paymentTypeID = 5; // Note - the following returns a dictionary<string,string>   
        let CD;
        if (SessionInfo.ShowAllOrders === false) {// Pay single Order
          CD = await CallOMP("PayCustomOrder",SessionInfo.CustomOrderID,parseFloat(this.state.ActualPaymentAmount),parseFloat(this.state.TipAmount),this.state.PaymentTypeID,this.state.PaymentMethodID,"","");
        } else {
          CD = await CallOM("PayAllCustomOrders",0,SessionInfo.CustomOrderID,CTYP.TypParm,[TYP.INT,SessionInfo.CustomOrderID,TYP.DEC,this.state.ActualPaymentAmount,TYP.DEC,this.state.TipAmount,TYP.INT,paymentTypeID,TYP.INT,this.state.PaymentMethodID,TYP.STR,"",TYP.STR,"",TYP.IAR,SessionInfo.PayTheseOrders]);
        }
        if (CD.x.o >= 9500) {
          displayMessage(`Payment Failed: ${CD.x.m}`);
          trace(`Payment Failed: ${CD.x.m}, o: ${CD.x.o}`);
        } else { //--- Sign-in Pay with Card ---------------------------                      
          let AmountOwing = CD.d.AmountOwing;
          trace(`==>Pay-2 - AmountOwingWithTip: ${this.state.AmountOwingWithTip},${AmountOwing},  ActualPaymentAmount: ${this.state.ActualPaymentAmount},${ActPayAmount}, CD: ${JSON.stringify(CD.d)}`);
          if (AmountOwing > 0) {
            this.setState({ AmountOwing: CD.d.AmountOwing });
            this.setState({ AmountOwingWithTip: CD.d.BillWithTip });
            this.setState({ AmountPaid: CD.d.AmountPaid });
            this.setState({ ActualPaymentAmount: CD.d.AmountOwingWithTip.toFixed(2) });
            this.setState({ ActualPaymentAmountS: this.moneyFormat(CD.d.AmountOwingWithTip.toFixed(2)) });
            this.setState({ showReviewPurchasePopup: true }); // Show status of payment - get remaining
          } else { // If AmountOwing is paid - exit CheckoutPage
            let dict = CD.d;
            trace(`Pay Return: ${JSON.stringify(CD)}`);
            SessionInfo.TotalBill = dict.TotalBill; // AmountOwing + TipAmount (Includes Tax)
            SessionInfo.TotalTip = dict.TotalTip;
            trace(`TotalBill: ${SessionInfo.TotalBill}`);
            SessionInfo.ResetOrder = true;
            trace(`onPay 2 - Reset: ${SessionInfo.ResetOrder}`);
            SessionInfo.RelatedOrders = dict.RelatedOrders;
            SessionInfo.PaidOrders = dict.PaidOrders;
            SessionInfo.BillPaymentComplete = false; // Possible that not All orders have been paid
            SessionInfo.BillPaymentDone = true;
            if (SessionInfo.OrderType === 3)
              this.props.navigate("/ScannerPage");
            else
              this.props.navigate("/OrderPage");
          }
        }
      }
    }
  }
  googlePayProc = async (event) => {
    trace(`handle Google Pay: ${JSON.stringify(event)}`);
    let desc = event.paymentMethodData.description;
    let info = event.paymentMethodData.info;
    let tokenData = event.paymentMethodData.tokenizationData;
    trace(`desc: ${desc}, info: ${JSON.stringify(info)}, token: ${JSON.stringify(tokenData)}`);
    trace(`Google Pay - Order ID: ${this.state.CustomOrderID},${SessionInfo.CustomOrderID}, Amt Owing: ${this.state.AmountOwingWithTip}, Tip: ${this.state.TipAmount}, Pay Amt: ${this.state.ActualPaymentAmount}, type: ${this.state.PaymentTypeID}, Pay Method: ${this.state.PaymentMethodID}, All: ${SessionInfo.ShowAllOrders}`);
    let encToken = xorEncode("4|" + tokenData.token,SessionInfo.Key);
    trace(`encToken: ${JSON.stringify(encToken)}`);
    let ActPayAmount = this.state.ActualPaymentAmount;
    let CD;
    if (SessionInfo.ShowAllOrders === false) { // Pay single Order
      CD = await CallOMP("PayCustomOrderToken",SessionInfo.CustomOrderID,parseFloat(this.state.ActualPaymentAmount),parseFloat(this.state.TipAmount),encToken,"",info.cardDetails,"",""); // info.CCV,info.ExpiryDate);
    } else { // Pay Selected Orders
      CD = await CallOM("PayAllCustomOrdersToken",0,SessionInfo.CustomOrderID,CTYP.TypParm,[TYP.INT,SessionInfo.CustomOrderID,TYP.DEC,this.state.ActualPaymentAmount,TYP.DEC,this.state.TipAmount,TYP.STR,encToken,TYP.STR,"",TYP.STR,info.cardDetails,TYP.STR,"",TYP.STR,"",TYP.IAR,SessionInfo.PayTheseOrders]);
    }
    if (CD.x.o >= 9500) {
      displayMessage(`Payment Failed: ${CD.x.m}`);
      trace(`Payment Failed: ${CD.x.m}, o: ${CD.x.o}`);
    } else { //--- Google Pay ---------------------------
      trace(`CD: ${JSON.stringify(CD)}`);
      let AmountOwing = CD.d.AmountOwing;
      trace(`==>Pay-3 - AmountOwingWithTip: ${this.state.AmountOwingWithTip},${AmountOwing},  ActualPaymentAmount: ${this.state.ActualPaymentAmount},${ActPayAmount}, CD: ${JSON.stringify(CD.d)}`);
      if (AmountOwing > 0) {
        this.setState({ AmountOwing: CD.d.AmountOwing });
        this.setState({ AmountOwingWithTip: CD.d.BillWithTip });
        this.setState({ AmountPaid: CD.d.AmountPaid });
        this.setState({ ActualPaymentAmount: CD.d.AmountOwingWithTip.toFixed(2) });
        this.setState({ ActualPaymentAmountS: this.moneyFormat(CD.d.AmountOwingWithTip.toFixed(2)) });
        this.setState({ showReviewPurchasePopup: true }); // Show status of payment - get remaining
      } else { // If AmountOwing is paid - exit CheckoutPage
        let dict = CD.d;
        trace(`Pay Return: ${JSON.stringify(CD)}`);
        SessionInfo.TotalBill = dict.TotalBill; // AmountOwing + TipAmount (Includes Tax)
        SessionInfo.TotalTip = dict.TotalTip;
        trace(`TotalBill: ${SessionInfo.TotalBill}`);
        SessionInfo.ResetOrder = true;
        trace(`onPay - Reset: ${SessionInfo.ResetOrder}`);
        SessionInfo.BillPaymentComplete = true; // Possible that not All orders have been paid
        SessionInfo.BillPaymentDone = true;
        if (SessionInfo.OrderType === 3)
          this.props.navigate("/ScannerPage");
        else
          this.props.navigate("/OrderPage");
      }
    }
  }
  onPayGiftCard = async (event) => {
    this.setState({ showScanQRCode: true });
    this.setState({ ScanTitle: "Scan Gift Card QR Code" });
    this.scanType = this.scanTypes.GiftCard;
    this.startScan();
  }
  onPayCash = async (event) => { // Note - this is for when the actual payment is handled by the manager - for now - disabled in OM
    let CD = await CallOM("UpdateTipAmount",0,SessionInfo.CustomOrderID,CTYP.DEC,parseFloat(this.state.TipAmount)); // Save the TipAmount from Checkout in the Order
    this.setState({ showPayCash: true }); // Sho the Cash QR Code
  }
  PayWithGiftCard = async (event) => {
    this.setState({ showScanQRCode: false });
  }
  handleScan = (data) => {
    trace(`handleScan: ${data}`);
    if (data) {
      this.setState({ scannedString: data });
      codeReader.reset();
      this.setState({ showTableQR: false });
      this.showMessage(`Scanned: ${data}`);
      switch (this.scanType) {
        case this.scanTypes.GiftCard:
          this.PayWithGiftCard(data);
          break;
        default:
          trace(`Unknown Scan Type`);
          break;
      }
    }
  };
  handleError = (err) => {
    console.error(err);
  };
  cancelQR = () => {
    codeReader.reset();
    this.setState({ showScanQRCode: false });
    this.showWarn('Scan Cancelled');
  }
  startScan = () => {
    trace('ZXing code reader initialized');
    codeReader.decodeFromInputVideoDevice(undefined,'scannerVideo').then(this.handleScan).catch(this.handleError);
  };
  onRegularTokenPay = async (info) => { // Regular Token Pay - The 'token' is the credit info
    let PaymentDetails = [{ 'CustomOrderID': SessionInfo.CustomOrderID }];
    // token result : {"code":200,"token":"c41-172dce29-296c-4119-9068-5c0184f83e6f","last4":"1234","expiryMonth":"05","expiryYear":"25"}
    trace(`Reg Token Payment - Order ID: ${this.state.CustomOrderID},${SessionInfo.CustomOrderID}, Amt Owing: ${this.state.AmountOwingWithTip}, Tip: ${this.state.TipAmount}, Actual Pay Amt: ${this.state.ActualPaymentAmount}, All: ${SessionInfo.ShowAllOrders}`);
    //---------- New ----------------------
    trace(`Reg Token info: ${JSON.stringify(info)}`);
    //let expiry = info.ExpiryDate.month.toString() + "/" + info.ExpiryDate.year.toString();
    let Str = '3|' + info.Identifier + '|' + info.CCV + '|' + info.ExpiryDate;
    //trace(`==> Str to Encode ${Str}`);
    Str = xorEncode('3|' + info.Identifier + '|' + info.CCV + '|' + info.ExpiryDate,SessionInfo.Key);
    //trace(`==> Encoded Str: ${Str}`);
    SessionInfo.RegularToken = Str;
    trace(`Token Encoded: ${SessionInfo.RegularToken}, Pay Amt: ${this.state.ActualPaymentAmount}`);
    let ActPayAmount = this.state.ActualPaymentAmount;
    //let expiry = "0225";    
    let paymentTypeID = 4;
    if (this.state.PaymentTypeID == "MasterCard")
      paymentTypeID = 5;
    let CD;
    if (SessionInfo.ShowAllOrders === false) { // Pay single Order
      CD = await CallOMP("PayCustomOrderToken",SessionInfo.CustomOrderID,parseFloat(this.state.ActualPaymentAmount),parseFloat(this.state.TipAmount),SessionInfo.RegularToken,"",info.Identifier,info.CCV,info.ExpiryDate);
    } else { // Pay Selected Orders
      CD = await CallOM("PayAllCustomOrdersToken",0,SessionInfo.CustomOrderID,CTYP.TypParm,[TYP.INT,SessionInfo.CustomOrderID,TYP.DEC,this.state.ActualPaymentAmount,TYP.DEC,this.state.TipAmount,TYP.STR,SessionInfo.RegularToken,TYP.STR,"",TYP.STR,"100",TYP.STR,info.CCV,TYP.STR,info.ExpiryDate,TYP.IAR,SessionInfo.PayTheseOrders]);
    }
    if (CD.x.o >= 9500) {
      displayMessage(`Payment Failed: ${CD.x.m}`);
      trace(`Payment Failed: ${CD.x.m}, o: ${CD.x.o}`);
    } else { //--- Token Pay - SignIn Pay ---------------------------                  
      let AmountOwing = CD.d.AmountOwing;
      trace(`==>Pay-5 - AmountOwingWithTip: ${this.state.AmountOwingWithTip},${AmountOwing}, ActualPaymentAmount: ${this.state.ActualPaymentAmount},${ActPayAmount}, CD: ${JSON.stringify(CD.d)}`);
      if (AmountOwing > 0) {
        this.setState({ AmountOwing: CD.d.AmountOwing });
        this.setState({ AmountOwingWithTip: CD.d.BillWithTip });
        this.setState({ AmountPaid: CD.d.AmountPaid });
        this.setState({ ActualPaymentAmount: CD.d.AmountOwingWithTip.toFixed(2) });
        this.setState({ ActualPaymentAmountS: this.moneyFormat(CD.d.AmountOwingWithTip.toFixed(2)) });
        this.setState({ showReviewPurchasePopup: true }); // Show status of payment - get remaining
      } else { // If AmountOwing is paid - exit CheckoutPage
        let dict = CD.d;
        trace(`Pay Return: ${JSON.stringify(CD)}`);
        SessionInfo.TotalBill = dict.TotalBill; // AmountOwing + TipAmount (Includes Tax)
        SessionInfo.TotalTip = dict.TotalTip;
        trace(`TotalBill: ${SessionInfo.TotalBill}`);
        SessionInfo.ResetOrder = true;
        trace(`on RegularTokenPay - Reset: ${SessionInfo.ResetOrder}`);
        SessionInfo.BillPaymentComplete = true; // Possible that not All orders have been paid
        SessionInfo.BillPaymentDone = true;
        //SessionInfo.BamProc = false;
        //SessionInfo.BamToken = '';
        trace(`Regular Token Payment Complete`);
        if (SessionInfo.OrderType === 3)
          this.props.navigate("/ScannerPage");
        else
          this.props.navigate("/OrderPage");
      }
    }
  }
  onBamboraTokenPay = async (caller) => { // Bambora Token Pay
    // NOTE - 5Apr24 - onBamboraTokenPay is being called 5 or 6 times in a row on payment
    let PaymentDetails = [{ 'CustomOrderID': SessionInfo.CustomOrderID }];
    // token result : {"code":200,"token":"c41-172dce29-296c-4119-9068-5c0184f83e6f","last4":"1234","expiryMonth":"05","expiryYear":"25"}
    trace(`Bambora Token Payment - Order ID: ${this.state.CustomOrderID},${SessionInfo.CustomOrderID}, Amt Owing: ${this.state.AmountOwingWithTip}, Tip: ${this.state.TipAmount}, Pay Amt: ${this.state.ActualPaymentAmount}, All: ${SessionInfo.ShowAllOrders}`);
    trace(`token: ${SessionInfo.BamToken.token}`);
    //---------- Use the Token returned from Bambora to pay the bill and record the payment ----------------------
    let encToken = xorEncode("2|" + SessionInfo.BamToken.token,SessionInfo.Key); // NT00002
    let ActPayAmount = this.state.ActualPaymentAmount;
    let paymentTypeID = 4;
    if (this.state.PaymentTypeID == "MasterCard")
      paymentTypeID = 5;
    let CD;
    if (SessionInfo.ShowAllOrders === false) { // Pay single Order - NT00002
      CD = await CallOMP("PayCustomOrderToken",SessionInfo.CustomOrderID,parseFloat(this.state.ActualPaymentAmount),parseFloat(this.state.TipAmount),encToken,"",SessionInfo.BamToken.code.toString(),SessionInfo.BamToken.last4,SessionInfo.BamToken.expiryMonth + SessionInfo.BamToken.expiryYear);
    } else { // Pay Selected Orders
      CD = await CallOM("PayAllCustomOrdersToken",0,SessionInfo.CustomOrderID,CTYP.TypParm,[TYP.INT,SessionInfo.CustomOrderID,TYP.DEC,this.state.ActualPaymentAmount,TYP.DEC,this.state.TipAmount,TYP.STR,SessionInfo.BamToken.token,TYP.STR,"",TYP.STR,SessionInfo.BamToken.code.toString(),TYP.STR,SessionInfo.BamToken.last4,TYP.STR,(SessionInfo.BamToken.expiryMonth + SessionInfo.BamToken.expiryYear),TYP.IAR,SessionInfo.PayTheseOrders]);
    }
    if (CD.x.o >= 9500) {
      displayMessage(`Payment Failed: ${CD.x.m}`);
      trace(`Payment Failed: ${CD.x.m}, o: ${CD.x.o}`);
    } else { //--- Bambora Token Pay ---------------------------                             
      let AmountOwing = CD.d.AmountOwing;
      trace(`==>Pay-6 - AmountOwingWithTip: ${this.state.AmountOwingWithTip},${AmountOwing},  ActualPaymentAmount: ${this.state.ActualPaymentAmount},${ActPayAmount}, CD: ${JSON.stringify(CD.d)}`);
      if (AmountOwing > 0) {
        this.setState({ AmountOwing: CD.d.AmountOwing });
        this.setState({ AmountOwingWithTip: CD.d.BillWithTip });
        this.setState({ AmountPaid: CD.d.AmountPaid });
        this.setState({ ActualPaymentAmount: CD.d.AmountOwingWithTip.toFixed(2) });
        this.setState({ ActualPaymentAmountS: this.moneyFormat(CD.d.AmountOwingWithTip.toFixed(2)) });
        this.setState({ showReviewPurchasePopup: true }); // Show status of payment - get remaining
      } else { // If AmountOwing is paid - exit CheckoutPage
        let dict = CD.d;
        trace(`Bambora Pay Return: ${JSON.stringify(CD)}`);
        SessionInfo.TotalBill = dict.TotalBill; // AmountOwing + TipAmount (Includes Tax)
        SessionInfo.TotalTip = dict.TotalTip;
        trace(`TotalBill: ${SessionInfo.TotalBill}`);
        //trace(`--- onPay Payment`);
        //await this.makePayment(PaymentDetails);
        SessionInfo.ResetOrder = true;
        trace(`onBamboraTokenPay - caller: ${caller} - Reset: ${SessionInfo.ResetOrder}`);
        SessionInfo.BillPaymentComplete = true; // Possible that not All orders have been paid
        SessionInfo.BillPaymentDone = true;
        SessionInfo.BamProc = false;
        SessionInfo.BamToken = '';
        trace(`Token Payment Complete`);
        if (SessionInfo.OrderType === 3)
          this.props.navigate("/ScannerPage");
        else
          this.props.navigate("/OrderPage");
      }
    }
  }
  //--------------------------------------------------------------
  chgFldVal(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ stateHasChanged: true });
  }
  chgFldValMask(event,mask) {
    //console.log(`chg masked value: ${event.target.value}, mask: ${mask}`);
    let str = UnmaskString(event.target.value,mask);
    //console.log(`chg masked value: ${str}, mask: ${mask}`);
    //if (str >= '2' && str <= '9') {
    //  str = '0' + str;
    //  console.log(`updated masked value: ${str}`);
    //}
    this.setState({ [event.target.name]: str });
    //console.log(`chg masked - name: ${event.target.name}:${event.target.value}`);
  }
  // React card
  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  }
  handleInputChange = (e) => {
    const { name,value } = e.target;
    this.setState({ [name]: value });
  }
  handleInputChangeEx = (e) => {
    let { name,value } = e.target;
    //console.log(`value: ${value}, isInteger: ${isNaN(value)}`);
    if (value == '') {
      this.setState({ [name]: value });
    } else if (isNaN(value) === false) {
      let val = parseInt(value);
      if (val > 1 && val < 10) {
        this.leaddigit = '0';
        e.target.value = this.leaddigit + val.toString();
      } else if (val == 0) {
        this.leaddigit = '';
        e.target.value = val.toString();
      }
      else if (val > 12 && val < 20) {
        this.leaddigit = '';
        val = 12;
      }
      value = val.toString();
      this.setState({ [name]: this.leaddigit + value });
      this.expiryDate = this.leaddigit + value;
      //console.log(`expiryDate: ${this.expiryDate}`);
    }
  }
  // Old React Card
  handleChangeCardNumber(event) {
    //console.log(`change card - event`);
    //console.log(event);
    console.log(`cc value: ${event.target.value}`);
    this.setState({ cardNumber: event.target.value });
    this.cardNumber = event.target.value;
    //this.cardNumber = event.target.value;
  }
  handleChangeExpiryDate(event) {
    console.log(`change expiry value: ${event.target.value}`);
    this.setState({ expiry: event.target.value });
    this.expiry = event.target.value;
  }
  verifyCard(event) {
    let cardNum = event.target.value;
    let ctype = getCardType(cardNum);
    if (!ctype)
      this.setColor('NUM',false);
    else {
      //console.log(`card: ${cardNum}, ctype: ${ctype.type}`);
      let lth = cardNum.length;
      if (lth > ctype.maxCardNumberLength)
        this.setColor('NUM',false);
      else {
        if (!validateCard(cardNum))
          this.setColor('NUM',false);
        else
          this.setColor('NUM',true);
        //console.log(`validate: ${num}, ctype: ${ctype.type}`);
      }
    }
    this.number = cardNum;
  }
  verifyCVC(event) {
    let cvc = event.target.value;
    console.log(`blur cvc value: ${event.target.value}, num: ${this.number}`);
    if (isNaN(cvc))
      this.setColor('CVC',false);
    else {
      let ctype = getCardType(this.number);
      if (!ctype)
        this.setColor('CVC',false);
      else if ((cvc + "").length != ctype.cvcLength)
        this.setColor('CVC',false);
      else
        this.setColor('CVC',true);
    }
  }
  verifyExpiry(event) {
    //console.log(`blur expiry value: ${event.target.value}`);
    let val = event.target.value.toString();
    this.expok = false;
    val = val.substring(0,2) + '/' + val.substring(2,4);
    console.log(`blur expiry value: ${val}, return: ${isExpiryInvalid(val)}`);
    if (isExpiryInvalid(val)) {
      this.setColor('EXP',false);
    } else
      this.setColor('EXP',true);
  }
  setColor(fld,isOK) {
    if (isOK) {
      if (fld === "NUM") {
        document.documentElement.style.setProperty('--CardBorderColorN','#c1f7f2');
        this.numok = true;
      } else if (fld === "CVC") {
        document.documentElement.style.setProperty('--CardBorderColorC','#c1f7f2');
        this.cvcok = true;
      } else if (fld === "EXP") {
        document.documentElement.style.setProperty('--CardBorderColorE','#c1f7f2');
        this.expok = true;
      }
      this.blockPay = false;
    } else {
      if (fld === "NUM") {
        document.documentElement.style.setProperty('--CardBorderColorN','red');
        this.numok = false;
      } else if (fld === "CVC") {
        document.documentElement.style.setProperty('--CardBorderColorC','red');
        this.cvcok = false;
      } else if (fld === "EXP") {
        document.documentElement.style.setProperty('--CardBorderColorE','red');
        this.expok = false;
      }
      this.blockPay = true;
    }
  }
  handleChangeCVC(event) {
    console.log(`change CVC value: ${event.target.value}`);
    this.setState({ cvc: event.target.value });
    this.cvc = event.target.value;
  }
  handleChangeName(event) {
    console.log(`change Name value: ${event.target.value}`);
    this.setState({ name: event.target.value });
    this.name = event.target.value;
  }
  //chgMoneyFldVal(event) {
  //  //var stateCopy = Object.assign({}, this.state[event.target.name]);
  //  let newVal = event.target.value.replace(/[^\d.-]/g,'');
  //  this.setState({ [event.target.name]: newVal });
  //}
  async chgMoneyFldVal(event) {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g,''); // Matches any single single character not in the bracket [] and replaces with ''  - https://www.infobyip.com/regularexpressioncalculator.php
    //trace(`money newval: ${newVal}, target: ${this.state[event.target.name]}, name: ${event.target.name}`);
    //this.setState({ [event.target.name + 'S']: this.moneyFormat(newVal) });    
    let tstVal = +newVal;
    if (Number.isNaN(tstVal))
      newVal = event.target.value.replace(/-/g,''); // Remove the - sign
    this.setState({ [event.target.name + 'S']: newVal });
    await this.setState({ [event.target.name]: newVal / 1.0 });
    //trace(`updated target: ${this.state[event.target.name]}, str: ${this.state[event.target.name + 'S']}`);
    this.setState({ stateHasChanged: true });
  }
  chgPercentFldVal(event) {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g,'');
    //trace("percent newval: " + newVal);
    newVal /= 100.0;
    this.setState({ [event.target.name]: newVal });
    this.setState({ stateHasChanged: true });
  }
  chgDDFldVal = (event) => {
    this.setState({ [event.target.props.name]: event.target.value.ddID });
    this.setState({ stateHasChanged: true });
  }
  chgCheckboxVal(event) {
    let setVal = true;
    if (this.state[event.target.name] === true)
      setVal = false;
    this.setState({ [event.target.name]: setVal });
    this.setState({ stateHasChanged: true });
  }
  //
  async blurMoneyFldVal(event) {
    //trace(`money target: ${this.state[event.target.name]}`);
    this.setState({ [event.target.name + 'S']: this.moneyFormat(this.state[event.target.name]) });
    //trace(`money target: ${this.state[event.target.name + 'S']}`);
  }
  //    
  async focusMoneyFldVal(event) {
    //trace(`money target: ${this.state[event.target.name]}`);
    if (this.state[event.target.name] === 0)
      this.setState({ [event.target.name + 'S']: '' });
    else
      this.setState({ [event.target.name + 'S']: this.state[event.target.name] });
    //trace(`money target: ${this.state[event.target.name + 'S']}`);
  }
  async chgMoneyFldValD(event) {
    this.setState({ TipAmountOverPS: '' });
    this.setState({ TipAmountOverP: 0.0 });
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g,''); // Matches any single single character not in the bracket [] and replaces with ''  - https://www.infobyip.com/regularexpressioncalculator.php
    //trace(`money newval: ${newVal}, target: ${this.state[event.target.name]}, name: ${event.target.name}`);
    //this.setState({ [event.target.name + 'S']: this.moneyFormat(newVal) });    
    let tstVal = +newVal;
    if (Number.isNaN(tstVal))
      newVal = event.target.value.replace(/-/g,''); // Remove the - sign
    this.setState({ TipAmountOverDS: newVal });
    await this.setState({ TipAmountOverD: newVal / 1.0 });
    //trace(`updated target: ${this.state[event.target.name]}, str: ${this.state[event.target.name + 'S']}`);
    this.setState({ stateHasChanged: true });
  }
  async blurTipAmountD(event) {
    trace(`blur Tip Amount: ${this.state.TipAmountOverD}`);
    if (this.state.TipAmountOverD > 0.0) {
      this.setState({ TipAmountOverDS: this.moneyFormat(this.state.TipAmountOverD) });
      this.setState({ TipAmount: this.state.TipAmountOverD });
      let TipPercent = 0;
      if (this.state.TotalPriceWithTax > 0)
        TipPercent = this.state.TipAmountOverD / this.state.TotalPriceWithTax;
      trace(`TipPercent: ${TipPercent}`);
      this.setState({ TipAmountOverPS: this.percentFormat(TipPercent) });
      let AmountOwingWithTip = this.state.AmountOwing + this.state.TipAmountOverD;
      this.setState({ AmountOwingWithTip });
      this.setState({ ActualPaymentAmount: AmountOwingWithTip.toFixed(2) });
      this.setState({ ActualPaymentAmountS: this.moneyFormat(AmountOwingWithTip.toFixed(2)) });
      //trace(`money target: ${this.state[event.target.name + 'S']}`); 
    } else if (this.state.TipAmountOverP > 0.0) {
      this.blurTipAmountP(event);
    }
  }
  //
  async focusPercentFldVal(event) {
    //trace(`money target: ${this.state[event.target.name]}`);
    if (this.state[event.target.name] === 0)
      this.setState({ [event.target.name + 'S']: '' });
    else
      this.setState({ [event.target.name + 'S']: this.state[event.target.name] });
    //trace(`money target: ${this.state[event.target.name + 'S']}`);
  }
  async chgPercentFldValP(event) {
    this.setState({ TipAmountOverDS: '' });
    this.setState({ TipAmountOverD: 0.0 });
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g,'');
    trace(`percent newval: ${newVal}`);
    //newVal /= 100.0;                            
    this.setState({ TipAmountOverPS: newVal });
    await this.setState({ TipAmountOverP: newVal / 1.0 });
    this.setState({ stateHasChanged: true });
  }
  async blurTipAmountP(event) {
    //trace(`money target: ${this.state[event.target.name]}`); 
    trace(`blur Tip Percent: ${this.state.TipAmountOverP}`);
    if (this.state.TipAmountOverP > 0.0) {
      this.setState({ TipAmountOverPS: this.percentFormat(this.state.TipAmountOverP / 100.0) });
      let TipAmount = this.state.AmountOwing * this.state.TipAmountOverP / 100.0
      TipAmount = Math.round(TipAmount * 10.0) / 10.0;
      this.setState({ TipAmount });
      this.setState({ TipAmountOverDS: this.moneyFormat(TipAmount) });
      let AmountOwingWithTip = this.state.AmountOwing + TipAmount;
      this.setState({ AmountOwingWithTip });
      this.setState({ ActualPaymentAmount: AmountOwingWithTip.toFixed(2) });
      this.setState({ ActualPaymentAmountS: this.moneyFormat(AmountOwingWithTip.toFixed(2)) });
      //trace(`money target: ${this.state[event.target.name + 'S']}`);
    } else if (this.state.TipAmountOverD > 0.0) {
      this.blurTipAmountD(event);
    }
  }
  //
  async focusPayAmount(event) {
    trace(`ActualPaymentAmount: ${this.state.ActualPaymentAmount}, AmountOwingWithTip: ${this.state.AmountOwingWithTip}`);
    if (this.state.ActualPaymentAmount === 0 || this.state.ActualPaymentAmount === this.state.AmountOwingWithTip)
      this.setState({ ActualPaymentAmountS: '' });
    else
      this.setState({ [event.target.name + 'S']: this.state[event.target.name] });
    //trace(`money target: ${this.state[event.target.name + 'S']}`);
  }
  async blurPayAmount(event) {
    trace(`blur pay amount: ${event.target.name}, Actual: ${this.state.ActualPaymentAmount}, OwingWithTip: ${this.state.AmountOwingWithTip}`);
    if (this.state.ActualPaymentAmount <= 0.0) {
      trace(`blur pay amount paid: ${this.state.AmountPaid}`);
      let amountToPay = (this.state.AmountOwingWithTip - this.state.AmountPaid).toFixed(2);
      this.setState({ ActualPaymentAmount: amountToPay });
      this.setState({ ActualPaymentAmountS: this.moneyFormat(amountToPay) });
    } else {
      let amountToPay = (1.0 * this.state.ActualPaymentAmount).toFixed(2);
      trace(`blur amountToPay: ${amountToPay}`);
      this.setState({ ActualPaymentAmount: amountToPay });
      this.setState({ ActualPaymentAmountS: this.moneyFormat(amountToPay) });
    }
    this.setState({ PayStyle: { border: '2px solid blue',borderRadius: '5px',} });
    //let AmountOwingWithTip = this.state.AmountOwing + this.state.ActualPaymentAmount;
    //this.setState({ AmountOwingWithTip });
    //trace(`money target: ${this.state[event.target.name + 'S']}`);
  }
  onChangeAmenities = (event) => {
    this.setState({ StoreAmenitiesVals: [...event.target.value] });
    trace(`Amenities: ${this.state.StoreAmenitiesVals}`);
  }
  chgCheckboxVal(event) {
    //trace("stateCopy-" + event.target.name + " : " + JSON.stringify(this.state[event.target.name]));
    let setVal = true;
    if (this.state[event.target.name] === true)
      setVal = false;
    this.setState({ [event.target.name]: setVal });
    this.setState({ stateHasChanged: true });
  }

  //-------------------- Payment Card Form -----------------------------------------------------
  makePayment = async (items) => { // Make Payment Directly - without saving Card Info (Not Tested - 19Jul22) - Note - this is only called when card info entered directly
    let CD;
    trace(`makePayment Review items: ${JSON.stringify(items)}`);
    try {
      CD = await CallOM("MakeDirectPaymentMethodL",0,SessionInfo.CustomOrderID,15,items); // Process Promotion Information and any other related processing   
      if (CD.x.o === 0)
        await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
      else if (CD.x.o < 9500) {
        trace(`make payment data: ${JSON.stringify(CD.d)}`);
      }
    } catch (error) {
      await OMTrace(2,`Failure in makePayment - Error: ${error}`);
    }
    return CD;
  }
  //-----------------------------------------------------------------------------------------------------------------------
  // End of Review Purchase
  //-----------------------------------------------------------------------------------------------------------------------
  signIn = async () => {
    if (SessionInfo.appEntityLogonType === 1) {
      SessionInfo.isPhoneLogin = false;
    } else if (SessionInfo.appEntityLogonType === 8) {
      SessionInfo.isPhoneLogin = true;
      SessionInfo.hasPhoneLogin = true;
    }
    trace(`signin - current OrderID: ${SessionInfo.CustomOrderID}`);
    SessionInfo.logonReturn = 2;
    SessionInfo.PersonInfo.FirstName = undefined;
    this.props.navigate(`/Login`);
  }
  //-----------------------------------------------------------------------------------------------------------------------
  // Credit Card Input Code 
  //-----------------------------------------------------------------------------------------------------------------------
  //var CreditCardInput = function (_Component) {
  //inherits(CreditCardInput,_Component);

  //CreditCardInput = (props) => {
  //  classCallCheck(this,CreditCardInput);

  //  var _this = possibleConstructorReturn(this,(CreditCardInput.__proto__ || Object.getPrototypeOf(CreditCardInput)).call(this,props));

  //  _this.componentDidMount = function () {
  //    _this.setState({ cardNumber: _this.cardNumberField.value },function () {
  //      var cardType = payment.fns.cardType(_this.state.cardNumber);
  //      var images$$1 = _this.images;
  //      _this.setState({
  //        cardImage: images$$1[cardType] || images$$1.placeholder
  //      });
  //    });
  //  };

  //function isMonthDashKey() {
  //  var _ref8 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
  //    key = _ref8.key,
  //    value = _ref8.target.value;
  //  return !value.match(/[/-]/) && /^[/-]$/.test(key);
  //}
  //checkIsNumeric = (e) => {
  //  if (!/^\d*$/.test(e.key)) {
  //    e.preventDefault();
  //  }
  //}
  //handleCardNumberBlur = () => {
  //  var _ref9 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { onBlur: null },
  //    onBlur = _ref9.onBlur;
  //  return function (e) {
  //    var customTextLabels = _this.props.customTextLabels;
  //    if (!payment.fns.validateCardNumber(e.target.value)) {
  //      this.setFieldInvalid(customTextLabels.invalidCardNumber || 'Card number is invalid','cardNumber');
  //    }
  //    var cardNumberInputProps = _this.props.cardNumberInputProps;
  //    cardNumberInputProps.onBlur && cardNumberInputProps.onBlur(e);
  //    onBlur && onBlur(e);
  //  };
  //}

  //handleCardNumberChange = () => {
  //  var _ref10 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { onChange: null },
  //    onChange = _ref10.onChange;
  //  return function (e) {
  //    var _this$props = _this.props,
  //      customTextLabels = _this$props.customTextLabels,
  //      enableZipInput = _this$props.enableZipInput,
  //      cardNumberInputProps = _this$props.cardNumberInputProps;
  //    var images$$1 = _this.images;
  //    var cardNumber = e.target.value;
  //    var cardNumberLength = cardNumber.split(' ').join('').length;
  //    var cardType = payment.fns.cardType(cardNumber);
  //    var cardTypeInfo = creditCardType.getTypeInfo(creditCardType.types[_this.CARD_TYPES[cardType]]) || {};
  //    var cardTypeLengths = cardTypeInfo.lengths || [16];
  //    this.cardNumberField.value = formatCardNumber(cardNumber);
  //    this.setState({
  //      cardImage: images$$1[cardType] || images$$1.placeholder,
  //      cardNumber: cardNumber
  //    });
  //    if (enableZipInput) {
  //      this.setState({ showZip: cardNumberLength >= 6 });
  //    }
  //    this.setFieldValid();
  //    if (cardTypeLengths) {
  //      var lastCardTypeLength = cardTypeLengths[cardTypeLengths.length - 1];
  //      var _iteratorNormalCompletion = true;
  //      var _didIteratorError = false;
  //      var _iteratorError = undefined;

  //      try {
  //        for (var _iterator = cardTypeLengths[Symbol.iterator](),_step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
  //          var length = _step.value;

  //          if (length === cardNumberLength && payment.fns.validateCardNumber(cardNumber)) {
  //            _this.cardExpiryField.focus();
  //            break;
  //          }
  //          if (cardNumberLength === lastCardTypeLength) {
  //            _this.setFieldInvalid(customTextLabels.invalidCardNumber || 'Card number is invalid','cardNumber');
  //          }
  //        }
  //      } catch (err) {
  //        _didIteratorError = true;
  //        _iteratorError = err;
  //      } finally {
  //        try {
  //          if (!_iteratorNormalCompletion && _iterator.return) {
  //            _iterator.return();
  //          }
  //        } finally {
  //          if (_didIteratorError) {
  //            throw _iteratorError;
  //          }
  //        }
  //      }
  //    }
  //    cardNumberInputProps.onChange && cardNumberInputProps.onChange(e);
  //    onChange && onChange(e);
  //  };
  //}
  //handleCardNumberKeyPress = (e) => {
  //  var value = e.target.value;
  //  this.checkIsNumeric(e);
  //  if (value && !isHighlighted()) {
  //    var valueLength = value.split(' ').join('').length;
  //    if (hasCardNumberReachedMaxLength(value,valueLength)) {
  //      e.preventDefault();
  //    }
  //  }
  //}
  //handleCardExpiryBlur = () => {
  //  var _ref11 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { onBlur: null },
  //    onBlur = _ref11.onBlur;
  //  return function (e) {
  //    var customTextLabels = _this.props.customTextLabels;
  //    var cardExpiry = e.target.value.split(' / ').join('/');
  //    var expiryError = isExpiryInvalid(cardExpiry,customTextLabels.expiryError);
  //    if (expiryError) {
  //      this.setFieldInvalid(expiryError,'cardExpiry');
  //    }
  //    var cardExpiryInputProps = _this.props.cardExpiryInputProps;
  //    cardExpiryInputProps.onBlur && cardExpiryInputProps.onBlur(e);
  //    onBlur && onBlur(e);
  //  };
  //}

  //handleCardExpiryChange = () => {
  //  var _ref12 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { onChange: null },
  //    onChange = _ref12.onChange;
  //  return function (e) {
  //    var customTextLabels = _this.props.customTextLabels;
  //    this.cardExpiryField.value = formatExpiry(e);
  //    var value = _this.cardExpiryField.value.split(' / ').join('/');
  //    this.setFieldValid();
  //    var expiryError = isExpiryInvalid(value,customTextLabels.expiryError);
  //    if (value.length > 4) {
  //      if (expiryError) {
  //        _this.setFieldInvalid(expiryError,'cardExpiry');
  //      } else {
  //        _this.cvcField.focus();
  //      }
  //    }
  //    var cardExpiryInputProps = _this.props.cardExpiryInputProps;
  //    cardExpiryInputProps.onChange && cardExpiryInputProps.onChange(e);
  //    onChange && onChange(e);
  //  };
  //}
  //handleCardExpiryKeyPress = (e) => {
  //  var value = e.target.value;
  //  if (!this.isMonthDashKey(e)) {
  //    this.checkIsNumeric(e);
  //  }
  //  if (value && !isHighlighted()) {
  //    var valueLength = value.split(' / ').join('').length;
  //    if (valueLength >= 4) {
  //      e.preventDefault();
  //    }
  //  }
  //}
  //handleCardCVCBlur = () => {
  //  var _ref13 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { onBlur: null },
  //    onBlur = _ref13.onBlur;

  //  return function (e) {
  //    var customTextLabels = _this.props.customTextLabels;
  //    if (!payment.fns.validateCardCVC(e.target.value)) {
  //      _this.setFieldInvalid(customTextLabels.invalidCvc || 'CVC is invalid','cardCVC');
  //    }
  //    var cardCVCInputProps = _this.props.cardCVCInputProps;
  //    cardCVCInputProps.onBlur && cardCVCInputProps.onBlur(e);
  //    onBlur && onBlur(e);
  //  };
  //}

  //handleCardCVCChange = () => {
  //  var _ref14 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { onChange: null },
  //    onChange = _ref14.onChange;
  //  return function (e) {
  //    var customTextLabels = _this.props.customTextLabels;
  //    var value = formatCvc(e.target.value);
  //    this.cvcField.value = value;
  //    var CVC = value;
  //    var CVCLength = CVC.length;
  //    var isZipFieldAvailable = _this.props.enableZipInput && _this.state.showZip;
  //    var cardType = payment.fns.cardType(_this.state.cardNumber);
  //    this.setFieldValid();
  //    if (CVCLength >= 4) {
  //      if (!payment.fns.validateCardCVC(CVC,cardType)) {
  //        _this.setFieldInvalid(customTextLabels.invalidCvc || 'CVC is invalid','cardCVC');
  //      }
  //    }
  //    if (isZipFieldAvailable && hasCVCReachedMaxLength(cardType,CVCLength)) {
  //      _this.zipField.focus();
  //    }
  //    var cardCVCInputProps = _this.props.cardCVCInputProps;
  //    cardCVCInputProps.onChange && cardCVCInputProps.onChange(e);
  //    onChange && onChange(e);
  //  };
  //}

  //handleCardCVCKeyPress = (e) => {
  //  var cardType = payment.fns.cardType(_this.state.cardNumber);
  //  var value = e.target.value;
  //  this.checkIsNumeric(e);
  //  if (value && !isHighlighted()) {
  //    var valueLength = value.split(' / ').join('').length;
  //    if (hasCVCReachedMaxLength(cardType,valueLength)) {
  //      e.preventDefault();
  //    }
  //  }
  //}

  //handleCardZipBlur = () => {
  //  var _ref15 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { onBlur: null },
  //    onBlur = _ref15.onBlur;
  //  return function (e) {
  //    var customTextLabels = _this.props.customTextLabels;
  //    if (!isZipValid(e.target.value)) {
  //      _this.setFieldInvalid(customTextLabels.invalidZipCode || 'Zip code is invalid','cardZip');
  //    }
  //    var cardZipInputProps = _this.props.cardZipInputProps;
  //    cardZipInputProps.onBlur && cardZipInputProps.onBlur(e);
  //    onBlur && onBlur(e);
  //  };
  //}

  //handleCardZipChange = () => {
  //  var _ref16 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { onChange: null },
  //    onChange = _ref16.onChange;

  //  return function (e) {
  //    var customTextLabels = _this.props.customTextLabels;
  //    var zip = e.target.value;
  //    var zipLength = zip.length;
  //    this.setFieldValid();
  //    if (zipLength >= 5 && !isZipValid(zip)) {
  //      _this.setFieldInvalid(customTextLabels.invalidZipCode || 'Zip code is invalid','cardZip');
  //    }
  //    var cardZipInputProps = _this.props.cardZipInputProps;
  //    cardZipInputProps.onChange && cardZipInputProps.onChange(e);
  //    onChange && onChange(e);
  //  };
  //}
  //handleCardZipKeyPress = (e) => {
  //  var cardType = payment.fns.cardType(_this.state.cardNumber);
  //  var value = e.target.value;
  //  this.checkIsNumeric(e);
  //  if (value && !isHighlighted()) {
  //    var valueLength = value.split(' / ').join('').length;
  //    if (hasZipReachedMaxLength(cardType,valueLength)) {
  //      e.preventDefault();
  //    }
  //  }
  //}
  //handleKeyDown = (ref) => {
  //  return function (e) {
  //    if (e.keyCode === BACKSPACE_KEY_CODE && !e.target.value) {
  //      ref.focus();
  //    }
  //  };
  //}
  //setFieldInvalid = (errorText,inputName) => {
  //  var _this$props2 = _this.props,
  //    invalidClassName = _this$props2.invalidClassName,
  //    onError = _this$props2.onError;
  //  // $FlowFixMe

  //  document.getElementById('field-wrapper').classList.add(invalidClassName);
  //  this.setState({ errorText: errorText });

  //  if (inputName) {
  //    var _onError = _this.props[inputName + 'InputProps'].onError;
  //    _onError && _onError(errorText);
  //  }
  //  if (onError) {
  //    onError({ inputName: inputName,error: errorText });
  //  }
  //}
  //setFieldValid = () => {
  //  var invalidClassName = _this.props.invalidClassName;
  //  // $FlowFixMe
  //  document.getElementById('field-wrapper').classList.remove(invalidClassName);
  //  _this.setState({ errorText: null });
  //}
  //---------------------------------------------------------------------------------------------
  // End of Credit Card Input Code
  //---------------------------------------------------------------------------------------------
  //-------------------- Full Payment Card Form -----------------------------------------------------
  PaymentCardForm = () => {
    const handleSubmit = async (dataItem) => {
      trace(`Payment Card F1 - dataItem: ${JSON.stringify(dataItem)}`);
      if (SessionInfo.defaultLogon === false)
        this.addUpdatePaymentMethod(dataItem);
      else { // Make Payment directly without saving the card information
        this.makePaymentDirectly(dataItem); // Set up to make the payment directly - save token to the Payment Method
        await this.goToPurchase();
      }
      this.setState({ showPaymentFull: false });
    }
    return (
      <div className="FullCardForm">
        <Form
          onSubmit={handleSubmit}
          render={(formRenderProps) => (
            <FormElement>
              <fieldset className={'k-form-fieldset'}>
                <legend className={'k-form-legend'} style={{ fontWeight: 'bold',fontSize: '18px' }}>Payment Card Information</legend>
                <Field id={'Identifier'} name={'Identifier'} label={'Card Number'} component={FormInput} validator={cardValidator} />
                <Field id={'FullName'} name={'FullName'} label={'Name on Card'} hint={'Full Name'} component={FormInput} validator={nameValidator} />
                <div style={{ display: 'flex',justifyContent: 'space-between' }}>
                  <Field id={'CCV'} name={'CCV'} label={'CCV'} hint={'Card security code'} component={FormInput} validator={ccvValidator} />
                  <Field id={'ExpiryDate'} name={'ExpiryDate'} label={'Expiry Date'} component={FormDatePicker} validator={arrivalDateValidator} wrapperStyle={{ width: '90%',marginRight: '18px' }} />
                </div>
                <Field id={'Address'} name={'Address'} label={'Street Address'} hint={'Street Name and Number'} component={FormInput} validator={addressValidator} />
                <Field id={'City'} name={'City'} label={'City'} component={FormInput} />
                <div style={{ display: 'flex',justifyContent: 'space-between' }}>
                  <Field id={'PostalCode'} name={'PostalCode'} label={'Postal Code'} component={FormInput} />
                  <Field id={'Province'} name={'Province'} label={'Province'} component={FormInput} />
                </div>
                {SessionInfo.defaultLogon === false ?
                  <div>
                    <Field id={'CardNickName'} name={'CardNickName'} label={'Card Nick Name'} hint={'Description for your card'} component={FormInput} />
                    {/*<Field id={'DefaultPaymentMethod'} name={'DefaultPaymentMethod'} label={'Default Payment Method'} component={FormCheckbox} />*/}
                    <Field id={'DefaultPaymentMethod'} name={'DefaultPaymentMethod'} label={'Default Payment Method'} component={FormSwitch} optional={true} />
                  </div>
                  :
                  <div>
                    <br />
                    <span style={{ fontSize: "16" }}> &nbsp;Note - Card information will NOT be saved</span>
                    <br />
                    <br />
                    <br />
                  </div>}
                <div className="k-form-buttons">
                  <span>&nbsp;</span>
                  {SessionInfo.defaultLogon === false ?
                    <Button className="smallButton" primary={'true'} type={'submit'} disabled={!formRenderProps.allowSubmit}>Save Card Info</Button> :
                    <Button className="smallButton" primary={'true'} type={'submit'} disabled={!formRenderProps.allowSubmit}>Submit Payment</Button>}
                  <span>&nbsp;</span>
                  <Button className="smallButton" onClick={formRenderProps.onFormReset}>Clear</Button>
                  <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>
                  <Button className="smallButton" onClick={this.cancel}>Cancel</Button>
                </div>
              </fieldset>
            </FormElement>
          )}
        />
      </div>
    );
  }
  //-------------------- Postal Payment Card Form -----------------------------------------------------
  PaymentCardFormPostal = () => {
    const handleSubmit = async (dataItem) => {
      trace(`Payment Card P - dataItem: ${JSON.stringify(dataItem)}`);
      if (SessionInfo.defaultLogon === false)
        this.addUpdatePaymentMethod(dataItem);
      else { // Make Payment directly without saving the card information
        this.makePaymentDirectly(dataItem);
        await this.goToPurchase();
      }
      this.setState({ showPaymentPostal: false });
    }
    return (
      <div className="PostalCardForm">
        <Form
          onSubmit={handleSubmit}
          render={(formRenderProps) => (
            <FormElement>
              <fieldset className={'k-form-fieldset'}>
                <legend className={'k-form-legend'} style={{ fontWeight: 'bold',fontSize: '18px' }}>Payment Card Information</legend>
                <Field id={'Identifier'} name={'Identifier'} label={'Card Number'} component={FormInput} validator={cardValidator} />
                <Field id={'FullName'} name={'FullName'} label={'Name on Card'} hint={'Full Name'} component={FormInput} validator={nameValidator} />
                <div style={{ display: 'flex',justifyContent: 'space-between' }}>
                  <Field id={'CCV'} name={'CCV'} label={'CCV'} hint={'Card security code'} component={FormInput} validator={ccvValidator} />
                  <Field id={'ExpiryDate'} name={'ExpiryDate'} label={'Expiry Date'} component={FormDatePicker} validator={arrivalDateValidator} wrapperStyle={{ width: '90%',marginRight: '18px' }} />
                </div>
                <div style={{ display: 'flex',justifyContent: 'space-between' }}>
                  <Field id={'PostalCode'} name={'PostalCode'} label={'Postal Code'} component={FormInput} />
                </div>
                {SessionInfo.defaultLogon === false ?
                  <div>
                    <Field id={'CardNickName'} name={'CardNickName'} label={'Card Nick Name'} hint={'Description for your card'} component={FormInput} />
                    {/*<Field id={'DefaultPaymentMethod'} name={'DefaultPaymentMethod'} label={'Default Payment Method'} component={FormCheckbox} />*/}
                    <Field id={'DefaultPaymentMethod'} name={'DefaultPaymentMethod'} label={'Default Payment Method'} component={FormSwitch} optional={true} />
                  </div>
                  :
                  <div>
                    <br />
                    <span style={{ fontSize: "16" }}> &nbsp;Note - Card information will NOT be saved</span>
                    <br />
                    <br />
                    <br />
                  </div>}
                <div className="k-form-buttons">
                  <span>&nbsp;  </span>
                  {SessionInfo.defaultLogon === false ?
                    <Button className="smallButton" primary={'true'} type={'submit'} disabled={!formRenderProps.allowSubmit}>Save Card Info</Button> :
                    <Button className="smallButton" primary={'true'} type={'submit'} disabled={!formRenderProps.allowSubmit}>Submit Payment</Button>}
                  <span>&nbsp;&nbsp;   </span>
                  <Button className="smallButton" onClick={formRenderProps.onFormReset}>Clear</Button>
                  <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;   </span>
                  <Button className="smallButton" onClick={this.cancel}>Cancel</Button>
                </div>
              </fieldset>
            </FormElement>
          )}
        />
      </div>
    );
  }
  //-------------------- Short Payment Card Form -----------------------------------------------------
  PaymentCardFormShort = () => {
    const handleSubmit = async (dataItem) => {
      trace(`Payment Card S - dataItem: ${JSON.stringify(dataItem)}`);
      //if (SessionInfo.defaultLogon === false)
      //  this.addUpdatePaymentMethod(dataItem);
      //else { // Make Payment directly without saving the card information
      this.makePaymentDirectly(dataItem);
      await this.goToPurchase();
      //}
      this.setState({ showPaymentShort: false });
    }
    return (
      <div className="ShortCardForm">
        <Form
          onSubmit={handleSubmit}
          render={(formRenderProps) => (
            <FormElement>
              <fieldset className={'k-form-fieldset'}>
                <legend className={'k-form-legend'} style={{ fontWeight: 'bold',fontSize: '18px' }}>Payment Card Information</legend>
                <Field id={'Identifier'} name={'Identifier'} label={'Card Number'} component={FormInput} validator={cardValidator} />
                <Field id={'FullName'} name={'FullName'} label={'Name on Card'} hint={'Full Name'} component={FormInput} validator={nameValidator} />
                <div style={{ display: 'flex',justifyContent: 'space-between' }}>
                  <Field id={'CCV'} name={'CCV'} label={'CCV'} hint={'Card security code'} component={FormInput} validator={ccvValidator} />
                  <Field id={'ExpiryDate'} name={'ExpiryDate'} label={'Expiry Date'} component={FormDatePicker} validator={arrivalDateValidator} wrapperStyle={{ width: '90%',marginRight: '18px' }} />
                </div>
                {SessionInfo.defaultLogon === false ?
                  <div>
                    <Field id={'CardNickName'} name={'CardNickName'} label={'Card Nick Name'} hint={'Description for your card'} component={FormInput} />
                    {/*<Field id={'DefaultPaymentMethod'} name={'DefaultPaymentMethod'} label={'Default Payment Method'} component={FormCheckbox} />*/}
                    <Field id={'DefaultPaymentMethod'} name={'DefaultPaymentMethod'} label={'Default Payment Method'} component={FormSwitch} optional={true} />
                  </div>
                  :
                  <div>
                    <br />
                    <span style={{ fontSize: "16" }}> &nbsp;Note - Card information will NOT be saved</span>
                    <br />
                    <br />
                    <br />
                  </div>}
                <div className="k-form-buttons">
                  <span>&nbsp;  </span>
                  {SessionInfo.defaultLogon === false ?
                    <Button className="smallButton" primary={'true'} type={'submit'} disabled={!formRenderProps.allowSubmit}>Save Card Info</Button> :
                    <Button className="smallButton" primary={'true'} type={'submit'} disabled={!formRenderProps.allowSubmit}>Submit Payment</Button>}
                  <span>&nbsp;&nbsp;   </span>
                  <Button className="smallButton" onClick={formRenderProps.onFormReset}>Clear</Button>
                  <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;   </span>
                  <Button className="smallButton" onClick={this.cancel}>Cancel</Button>
                </div>
              </fieldset>
            </FormElement>
          )}
        />
      </div>
    );
  }
  //-------------------- Minimal Payment Card Form -----------------------------------------------------
  PaymentCardFormMinimal = () => {
    const handleSubmit = async (dataItem) => {
      trace(`Payment Card M - dataItem: ${JSON.stringify(dataItem)}`);
      if (SessionInfo.defaultLogon === false)
        this.addUpdatePaymentMethod(dataItem);
      else { // Make Payment directly without saving the card information
        this.makePaymentDirectly(dataItem);
        await this.goToPurchase();
      }
      this.setState({ showPaymentMinimal: false });
    }
    return (
      <div className="MinimalCardForm">
        <Form
          onSubmit={handleSubmit}
          render={(formRenderProps) => (
            <FormElement>
              <fieldset className={'k-form-fieldset'}>
                <legend className={'k-form-legend'} style={{ fontWeight: 'bold',fontSize: '18px' }}>Payment Card Informationx</legend>
                <Field id={'Identifier'} name={'Identifier'} label={'Card Number'} component={FormInput} validator={cardValidator} />
                <div style={{ display: 'flex',justifyContent: 'space-between' }}>
                  <Field id={'CCV'} name={'CCV'} label={'CCV'} hint={'Card security code'} component={FormInput} validator={ccvValidator} />
                  <Field id={'ExpiryDate'} name={'ExpiryDate'} label={'Expiry Date'} component={FormDatePicker} validator={arrivalDateValidator} wrapperStyle={{ width: '90%',marginRight: '18px' }} />
                </div>
                {SessionInfo.defaultLogon === false ?
                  <div>
                    <Field id={'CardNickName'} name={'CardNickName'} label={'Card Nick Name'} hint={'Description for your card'} component={FormInput} />
                  </div>
                  :
                  <div>
                    <br />
                    <span style={{ fontSize: "16" }}> &nbsp;Note - Card information will NOT be saved</span>
                    <br />
                  </div>}
                <div className="k-form-buttons">
                  <span>&nbsp;  </span>
                  {SessionInfo.defaultLogon === false ?
                    <Button className="smallButton" primary={'true'} type={'submit'} disabled={!formRenderProps.allowSubmit}>Save Card Info</Button> :
                    <Button className="smallButton" primary={'true'} type={'submit'} disabled={!formRenderProps.allowSubmit}>Submit Payment</Button>}
                  <span>&nbsp;&nbsp;   </span>
                  <Button className="smallButton" onClick={formRenderProps.onFormReset}>Clear</Button>
                  <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;   </span>
                  <Button className="smallButton" onClick={this.cancel}>Cancel</Button>
                </div>
              </fieldset>
            </FormElement>
          )}
        />
      </div>
    );
  }
  //-------------------- Extreme Minimal Payment Card Form -----------------------------------------------------
  PaymentCardFormXMinimal = () => {
    const handleSubmit = async (dataItem) => {
      trace(`Payment Card X - dataItem: ${JSON.stringify(dataItem)}`);
      //trace(`==> AppCfg: ${JSON.stringify(SessionInfo.AppCfg)}`);
      // Make Payment directly without saving the card information
      await this.onRegularTokenPay(dataItem);
      //this.makePaymentDirectly(dataItem);
      //await this.goToPurchase();
      this.setState({ showPaymentXMinimal: false });
    }
    return (
      <div className="XMinimalCardForm">
        <Form
          onSubmit={handleSubmit}
          render={(formRenderProps) => (
            <FormElement>
              <fieldset className={'k-form-fieldset'}>
                <legend className={'k-form-legend'} style={{ fontWeight: 'bold',fontSize: '18px' }}>Payment Card Informationy</legend>
                {/*<Field id={'Identifier'} name={'Identifier'} label={'Card Number'} component={FormInput} validator={cardValidator} />*/}
                <Field id={'Identifier'} name={'Identifier'} label={'Card Number'} component={FormInput} type='tel' validator={cardValidator} />
                {/*<Field id={'Identifier'} name={'Identifier'} label={'Card Number'} hint={'Card Number'} onChange={evt => this.chgFldValMask(evt,"#### #### #### ####")} mask="#### #### #### ####" component={FormMaskedTextBox} type='tel' validator={cardValidator} />}*/}
                <div style={{ display: 'flex',justifyContent: 'space-between' }}>
                  {SessionInfo.firstDigit === '3' ?
                    <Field id={'CCV'} name={'CCV'} label={'CCV'} hint={'Card Security Code'} onChange={evt => this.chgFldValMask(evt,"####")} mask="####" component={FormMaskedTextBox} type='tel' validator={ccvValidator} wrapperStyle={{ width: "90%",marginRight: "18px" }} /> :
                    <Field id={'CCV'} name={'CCV'} label={'CCV'} hint={'Card Security Code'} onChange={evt => this.chgFldValMask(evt,"###")} mask="###" component={FormMaskedTextBox} type='tel' validator={ccvValidator} wrapperStyle={{ width: "90%",marginRight: "18px" }} />}
                  <Field id={'ExpiryDate'} name={'ExpiryDate'} label={'Expiry Date'} hint={'Expiry Date mm/yy'} onChange={evt => this.chgFldValMask(evt,"##/##")} mask="##/##" component={FormMaskedTextBox} type='tel' validator={expiryValidator} />
                  {/*<Field id={'ExpiryDate'} name={'ExpiryDate'} label={'Expiry Date'} component={FormDatePicker} format={{ year: "numeric",month: "short",}} validator={arrivalDateValidator} wrapperStyle={{ width: '90%',marginRight: '18px' }} />*/}
                </div>
                <br />
                <div className="k-form-buttons">
                  <span>&nbsp;  </span>
                  <Button icon="track-changes-accept" className="smallButton" primary={'true'} type={'submit'} disabled={!formRenderProps.allowSubmit}>&nbsp; Pay &nbsp; </Button>
                  {/*<Button icon="track-changes-accept" className="smallButton" onClick={this.processPayment}>&nbsp; Pay&nbsp; </Button>*/}
                  <span>&nbsp; &nbsp; &nbsp; &nbsp; </span>
                  <Button className="smallButton" onClick={formRenderProps.onFormReset}>Clear</Button>
                  <span>&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>
                  <Button icon="close-circle" className="smallButton" onClick={this.cancelPayment}>&nbsp;Cancel&nbsp;</Button>
                </div>
              </fieldset>
            </FormElement>
          )}
        />
      </div>
    );
  }
  //-------------------- Extreme Minimal Payment Card Form -----------------------------------------------------
  PaymentCardFormXMinimal2 = () => {
    const handleSubmit = async (dataItem) => {
      trace(`Payment Card X - dataItem: ${JSON.stringify(dataItem)}`);
      //trace(`==> AppCfg: ${JSON.stringify(SessionInfo.AppCfg)}`);
      // Make Payment directly without saving the card information
      await this.onRegularTokenPay(dataItem);
      //this.makePaymentDirectly(dataItem);
      //await this.goToPurchase();
      this.setState({ showPaymentXMinimal: false });
    }
    return (
      <div className="XMinimalCardForm2">
        <Form
          onSubmit={handleSubmit}
          render={(formRenderProps) => (
            <FormElement>
              <fieldset className={'k-form-fieldset'}>
                <Field id={'number'} name={'number'} label={'Card Number'} component={FormInput} type='tel' validator={cardValidator} />
                {/*<Field id={'Identifier'} name={'Identifier'} label={'Card Number'} hint={'Card Number'} onChange={evt => this.chgFldValMask(evt,"#### #### #### ####")} mask="#### #### #### ####" component={FormMaskedTextBox} type='tel' validator={cardValidator} />}*/}
                <div style={{ display: 'flex',justifyContent: 'space-between' }}>
                  {SessionInfo.firstDigit === '3' ?
                    <Field id={'cvc'} name={'cvc'} label={'CCV'} hint={'Card Security Code'} onChange={evt => this.chgFldValMask(evt,"####")} mask="####" component={FormMaskedTextBox} type='tel' validator={ccvValidator} wrapperStyle={{ width: "90%",marginRight: "18px" }} /> :
                    <Field id={'cvc'} name={'cvc'} label={'CCV'} hint={'Card Security Code'} onChange={evt => this.chgFldValMask(evt,"###")} mask="###" component={FormMaskedTextBox} type='tel' validator={ccvValidator} wrapperStyle={{ width: "90%",marginRight: "18px" }} />}
                  <Field id={'expiry'} name={'expiry'} label={'Expiry Date'} hint={'Expiry Date mm/yy'} onChange={evt => this.chgFldValMask(evt,"##/##")} mask="##/##" component={FormMaskedTextBox} type='tel' validator={expiryValidator} />
                  {/*<Field id={'ExpiryDate'} name={'ExpiryDate'} label={'Expiry Date'} component={FormDatePicker} format={{ year: "numeric",month: "short",}} validator={arrivalDateValidator} wrapperStyle={{ width: '90%',marginRight: '18px' }} />*/}
                </div>
                <br />
                <div className="k-form-buttons">
                  <span>&nbsp;  </span>
                  <Button icon="track-changes-accept" className="smallButton" primary={'true'} type={'submit'} disabled={!formRenderProps.allowSubmit}>&nbsp; Pay &nbsp; </Button>
                  {/*<Button icon="track-changes-accept" className="smallButton" onClick={this.processPayment}>&nbsp; Pay&nbsp; </Button>*/}
                  <span>&nbsp; &nbsp; &nbsp; &nbsp; </span>
                  <Button className="smallButton" onClick={formRenderProps.onFormReset}>Clear</Button>
                  <span>&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>
                  <Button icon="close-circle" className="smallButton" onClick={this.cancelPayment}>&nbsp;Cancel&nbsp;</Button>
                </div>
              </fieldset>
            </FormElement>
          )}
        />
      </div>
    );
  }
  //-------------------- Extreme Minimal Payment Card Form -----------------------------------------------------
  //PaymentCardFormXMinimal = () => {
  //  const handleSubmit = async (dataItem) => {
  //    trace(`Payment Card X - dataItem: ${JSON.stringify(dataItem)}`);
  //    // Make Payment directly without saving the card information
  //    await this.onRegularTokenPay(dataItem);
  //    //this.makePaymentDirectly(dataItem);
  //    //await this.goToPurchase();
  //    this.setState({ showPaymentXMinimal: false });
  //  }
  //  return (
  //    <div className="XMinimalCardForm">
  //      <Form
  //        onSubmit={handleSubmit}
  //        render={(formRenderProps) => (
  //          <FormElement>
  //            <fieldset className={'k-form-fieldset'}>
  //              <legend className={'k-form-legend'} style={{ fontWeight: 'bold',fontSize: '18px' }}>Payment Card Information</legend>
  //              <Field id={'Identifier'} name={'Identifier'} label={'Card Number'} component={FormInput} validator={cardValidator} />
  //              <div style={{ display: 'flex',justifyContent: 'space-between' }}>
  //                <Field id={'CCV'} name={'CCV'} label={'CCV'} hint={'Card security code'} component={FormInput} validator={ccvValidator} />
  //                <Field id={'ExpiryDate'} name={'ExpiryDate'} label={'Expiry Date'} component={FormDatePicker} format={{ year: "numeric",month: "short",}} validator={arrivalDateValidator} wrapperStyle={{ width: '90%',marginRight: '18px' }} />
  //              </div>
  //              <div className="k-form-buttons">
  //                <span>&nbsp;  </span>
  //                <Button className="smallButton" primary={'true'} type={'submit'} disabled={!formRenderProps.allowSubmit}>Pay</Button>
  //                <span>&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;  </span>
  //                <Button className="smallButton" onClick={this.cancel}>Cancel</Button>
  //              </div>
  //            </fieldset>
  //          </FormElement>
  //        )}
  //      />
  //    </div>
  //  );
  //}
  //---------------- Bambora Quick Form -------------------------------------------
  // Converted Script
  InitBamScript = () => {
    trace('----------> Init Bambora Script');
    var customCheckout = customcheckout(); // From Bambora Library - see App.js
    trace('----------> After customCheckout Exec');

    var isCardNumberComplete = false;
    var isCVVComplete = false;
    var isExpiryComplete = false;

    var customCheckoutController = {
      init: function () {
        //trace('----------> checkout.init()');
        this.createInputs();
        this.addListeners();
      },
      createInputs: function () {
        //trace('checkout.createInputs()');
        var options = {};
        // Create and mount the inputs
        options.placeholder = 'Card number';
        customCheckout.create('card-number',options).mount('#card-number');
        options.placeholder = 'CVV';
        customCheckout.create('cvv',options).mount('#card-cvv');
        options.placeholder = 'MM / YY';
        customCheckout.create('expiry',options).mount('#card-expiry');
      },
      addListeners: function () {
        var self = this;
        //trace('checkout.addListeners()');
        // listen for submit button
        if (document.getElementById('checkout-form') !== null) {
          document
            .getElementById('checkout-form')
            .addEventListener('submit',self.onSubmit.bind(self));
        }
        customCheckout.on('brand',function (event) {
          //trace('brand: ' + JSON.stringify(event));
          var cardLogo = 'none';
          if (event.brand && event.brand !== 'unknown') {
            var filePath =
              'https://cdn.na.bambora.com/downloads/images/cards/' +
              event.brand +
              '.svg';
            cardLogo = 'url(' + filePath + ')';
          }
          document.getElementById('card-number').style.backgroundImage = cardLogo;
        });
        customCheckout.on('blur',function (event) {
          //trace('blur: ' + JSON.stringify(event));
        });
        customCheckout.on('focus',function (event) {
          //trace('focus: ' + JSON.stringify(event));
        });
        customCheckout.on('empty',function (event) {
          //trace('empty: ' + JSON.stringify(event));
          if (event.empty) {
            if (event.field === 'card-number') {
              isCardNumberComplete = false;
            } else if (event.field === 'cvv') {
              isCVVComplete = false;
            } else if (event.field === 'expiry') {
              isExpiryComplete = false;
            }
            self.setPayButton(false);
          }
        });
        customCheckout.on('complete',function (event) {
          //trace('complete: ' + JSON.stringify(event));

          if (event.field === 'card-number') {
            isCardNumberComplete = true;
            self.hideErrorForId('card-number');
          } else if (event.field === 'cvv') {
            isCVVComplete = true;
            self.hideErrorForId('card-cvv');
          } else if (event.field === 'expiry') {
            isExpiryComplete = true;
            self.hideErrorForId('card-expiry');
          }
          self.setPayButton(
            isCardNumberComplete && isCVVComplete && isExpiryComplete
          );
        });
        customCheckout.on('error',function (event) {
          //trace('error: ' + JSON.stringify(event));
          if (event.field === 'card-number') {
            isCardNumberComplete = false;
            self.showErrorForId('card-number',event.message);
          } else if (event.field === 'cvv') {
            isCVVComplete = false;
            self.showErrorForId('card-cvv',event.message);
          } else if (event.field === 'expiry') {
            isExpiryComplete = false;
            self.showErrorForId('card-expiry',event.message);
          }
          self.setPayButton(false);
        });
      },
      onSubmit: function (event) {
        var self = this;
        //trace('checkout.onSubmit()');
        event.preventDefault();
        self.setPayButton(false);
        self.toggleProcessingScreen();
        var callback = function (result) {
          //trace('token result : ' + JSON.stringify(result));
          if (result.error) {
            self.processTokenError(result.error);
          } else {
            self.processTokenSuccess(result);
          }
        };
        //trace('checkout.createToken()');
        customCheckout.createToken(callback);
      },
      hideErrorForId: function (id) {
        //trace('hideErrorForId: ' + id);
        var element = document.getElementById(id);
        if (element !== null) {
          var errorElement = document.getElementById(id + '-error');
          if (errorElement !== null) {
            errorElement.innerHTML = '';
          }
          var bootStrapParent = document.getElementById(id + '-bootstrap');
          if (bootStrapParent !== null) {
            bootStrapParent.className = 'form-group has-feedback has-success';
          }
        } else {
          //trace('showErrorForId: Could not find ' + id);
        }
      },
      showErrorForId: function (id,message) {
        //trace('showErrorForId: ' + id + ' ' + message);
        var element = document.getElementById(id);
        if (element !== null) {
          var errorElement = document.getElementById(id + '-error');
          if (errorElement !== null) {
            errorElement.innerHTML = message;
          }
          var bootStrapParent = document.getElementById(id + '-bootstrap');
          if (bootStrapParent !== null) {
            bootStrapParent.className = 'form-group has-feedback has-error ';
          }
        } else {
          //trace('showErrorForId: Could not find ' + id);
        }
      },
      setPayButton: function (enabled) {
        //trace('checkout.setPayButton() disabled: ' + !enabled);
        var payButton = document.getElementById('pay-button');
        if (enabled) {
          payButton.disabled = false;
          payButton.className = 'btn btn-primary';
        } else {
          payButton.disabled = true;
          payButton.className = 'btn btn-primary disabled';
        }
      },
      toggleProcessingScreen: function () {
        var processingScreen = document.getElementById('processing-screen');
        if (processingScreen) {
          processingScreen.classList.toggle('visible');
        }
      },
      showErrorFeedback: function (message) {
        var xMark = '\u2718';
        this.feedback = document.getElementById('feedback');
        this.feedback.innerHTML = xMark + ' ' + message;
        this.feedback.classList.add('error');
      },
      showSuccessFeedback: function (message) {
        var checkMark = '\u2714';
        this.feedback = document.getElementById('feedback');
        this.feedback.innerHTML = checkMark + ' ' + message;
        this.feedback.classList.add('success');
      },
      processTokenError: function (error) {
        if (SessionInfo.bamboraOffline === true) {
          this.setPayButton(false);
          this.toggleProcessingScreen();
          SessionInfo.BamToken = "xyz123"; // Test token
          SessionInfo.BamPayProc(tokstr);
        } else {
          trace(`processTokenError: ${JSON.stringify(error,undefined,2)}`);
          this.showErrorFeedback('Error creating token: </br>' + JSON.stringify(error,null,4));
          this.setPayButton(true);
          this.toggleProcessingScreen();
        }
      },
      processTokenSuccess: function (tokstr) {
        trace(`success processTokenSuccess: ${tokstr.token}`);
        //this.showSuccessFeedback('Success! Created token: ' + tokstr.token);
        this.setPayButton(false);
        this.toggleProcessingScreen();
        SessionInfo.BamToken = tokstr;
        // Use token to call payments api
        SessionInfo.BamPayProc(tokstr);
        //this.setState({ showPaymentBambora: false });
      },
    };
    customCheckoutController.init(); // Call the init function just passed in
  }
  makeTokenPayment = async (tokstr) => {
    if (SessionInfo.bamboraOffline === false) {
      trace(`makeTokenPayment token return: ${JSON.stringify(tokstr)}`);
      this.setState({ showPaymentBambora: false });
      // token result : {"code":200,"token":"c41-172dce29-296c-4119-9068-5c0184f83e6f","last4":"1234","expiryMonth":"05","expiryYear":"25"}
      if (tokstr.code !== 200) { // What are valid codes?
        // Popup Error Notification
        displayMessage('Failure to Verify Credit Card');
      } else { // Valid Bambora Token Received
        SessionInfo.BamProc = true;
        if (SessionInfo.GetBamboraTokenFirst) {
          this.goToPurchase();
        } else {
          this.onBamboraTokenPay("makeTokenPayment");
        }
      }
    }
  }
  //------------------------------------------------------------------------------------------------------
  // https://dev.na.bambora.com/docs/guides/custom_checkout/
  //PaymentBambora = () => {
  //  const handleSubmit = async (dataItem) => {
  //    //alert(JSON.stringify(dataItem, null, 2));
  //    this.addUpdatePaymentMethod(dataItem);
  //    this.setState({ showPaymentMinimal: false });
  //  }
  //  return (
  //    <div className="MinimalCardForm">
  //      <div class="container">
  //        <Form id="checkout-form"
  //          onSubmit={handleSubmit}
  //          render={(formRenderProps) => (
  //            <FormElement>
  //              <fieldset className={'k-form-fieldset'}>
  //                <legend className={'k-form-legend'} style={{ fontWeight: 'bold', fontSize: '18px' }}>Payment Card Information</legend>
  //                <Field id={'card-number'} name={'card-number'} label={'CardX Number'} component={FormInput} validator={cardValidator} />
  //                <Field id={'card-cvv'} name={'card-cvv'} label={'CCV'} hint={'Card security code'} component={FormInput} validator={ccvValidator} />
  //                <Field id={'card-expiry'} name={'card-expiry'} label={'Expiry Date'} component={FormDatePicker} validator={arrivalDateValidator} wrapperStyle={{ width: '90%', marginRight: '18px' }} />
  //                <div className="k-form-buttons">
  //                  <span>&nbsp;  </span>
  //                  <Button className="smallButton" primary={'true'} type={'submit'} disabled={!formRenderProps.allowSubmit}>&nbsp; PayX&nbsp; </Button>
  //                  <span>&nbsp;   </span>
  //                  <Button className="smallButton" onClick={this.cancel}>Cancel</Button>
  //                </div>
  //              </fieldset>
  //            </FormElement>
  //          )}
  //        />
  //      </div>
  //    </div>
  //  );
  //}

  //------------------------- Render ------------------------------------------------------------------
  render() {
    return (
      <div id="CheckoutPage" className="pageMain">
        <div id="hdr" className="headerCheckout" >
          <div className="headerTop">
            <div className="headerLeft">
              <div onClick={this.onAddMore}>&nbsp; {this.backPrompt}</div>
            </div>
            <div className="headerOrderCenter">
              {/*<div className="headerCenterMenu">
                    {SessionInfo.loggedOn === true && SessionInfo.accountLogon == true ?
                      <MenuBar alt="" fill={SessionInfo.ECV.BrandColor} id="MenuBtn" title={`View Menu`} className="functionImageL" onClick={(e) => this.openMenu()} ref={this.menuAnchor} /> :
                      <span className="signin" onClick={(e) => this.signIn()}>Sign In</span>}
                  </div>*/}

            </div>

            <div className="headerCheckoutCenterMid">
              {this.state.OrderType === 4 ?
                <>
                  {this.state.TableID > 0 && SessionInfo.AC.HasTable === true ?
                    <span className='landingHeadText'>{this.state.TableName}</span> : null}
                  {this.state.SeatCount > 1 && SessionInfo.AC.HasViewSeats === true ?
                    <span>
                      <span className='landingHeadTextReg'>&nbsp; Seat:</span>
                      <span className='landingHeadText'>&nbsp;{this.state.SeatName}</span>
                      <span style={{ fontSize: 14 }} >&nbsp;(</span>
                      <span className='landingHeadText' >{this.state.SeatCount}</span>
                      <span className='landingHeadTextReg'>&nbsp;seats)</span>
                    </span> : null}
                  {this.state.SeatCount <= 1 && SessionInfo.AC.HasGuest === true && SessionInfo.AppCfg.HasDelivery === true ?
                    <span>
                      <span className='landingHeadTextReg'>&nbsp; &nbsp; &nbsp; Guest:</span>
                      <span className='landingHeadText' >&nbsp;{SessionInfo.logonName}</span>
                    </span> : null}
                  <br />
                </> :
                <>
                  <span className='landingHeadText'>{this.state.StoreName}</span>
                  {SessionInfo.debug && 1 === 0 ?
                    <span className='landingHeadTextSmall'>&nbsp;&nbsp;Ver: {SessionInfo.appVersion}-{SessionInfo.Platform}</span> : null}
                </>}
            </div>
          </div>

          <div className="headerMiddle">
            <div className="headerSpecial">
            </div>
            <div className="headerStoreInfo">
              {this.state.showStoreInfo ?
                <div className="headerLocation">
                  <div className="landingTextXXXL" >{SessionInfo.SLV.Description}</div>
                  <div className="landingTextXXL" >&nbsp; {this.state.HoursForToday}</div>
                </div> : null}
            </div>
            <div className="headerCheckoutMessage">
              {this.state.showNotify ?
                <>
                  <div dangerouslySetInnerHTML={{ __html: this.state.notifyMessage }} onClick={(e) => { this.acknowledgeMessage(); }} />
                  {SessionInfo.acknowledge ?
                    <div className="bottomButton">
                      <Button icon="tell-a-friend" className="mediumButton" onClick={this.acknowledgeMessage}>Acknowledge</Button>
                    </div> : null}
                </> : null}
            </div>
          </div>
          <div className="headerCheckoutBottom">
            <div style={{ fontSize: 18,fontWeight: 'bold',color: "white",padding: '5px' }} >&nbsp; {this.state.showAllOrders ? 'ITEMS FOR ALL CARTS' : 'YOUR CART ITEMS'}</div>
          </div>
          <div className='headerCheckoutButtons'>
            {/*<div ref={node => this.op0.current = node}>*/}
            {this.state.showAllOrders === true ?
              <Button id="showYourButton" icon="form-element" look="outline" className="leftButton" onClick={this.onShowSingleOrders} >&nbsp; JUST MY ORDER &nbsp;</Button> : null}
            {this.state.showAllOrders === false && this.state.SeatCount > 1 && SessionInfo.logonType < 9 ?
              <Button id="showAllButton" icon="filter-add-expression" look="outline" className="leftButton" onClick={this.onShowAllOrders} >&nbsp; SHOW ALL ORDERS &nbsp;</Button> : null}
            <br />
            {/*</div>*/}
            {this.state.isReadyToPay === true ?
              <Button id="addPaymentButton" icon="calculator" look="outline" className="leftButton" onClick={this.onAddPayment} >&nbsp;&nbsp; PAY CHECK &nbsp;&nbsp;</Button>
              :
              <Button id="submitOrderButton" icon="hyperlink-open" look="outline" className="leftButton" onClick={this.onSubmitOrder} > {this.state.showAllOrders ? 'SUBMIT ORDERS' : 'SUBMIT ORDER'} </Button>}
            {this.state.showCancelOrder === true ?
              <>
                <br /><br />
                <Button id="abandonButton" icon="cancel" look="outline" className="leftButton" onClick={this.onAbandonOrder} >CANCEL ORDER</Button>
              </> :
              <>
                <br /><br />
                {SessionInfo.AppCfg.HasVoidOrder === true ?
                  <Button id="voidOrder" icon="file-excel" look="outline" className="leftButton" onClick={this.onVoidOrder} >VOID ORDER/ITEM</Button> : null}
              </>
            }
          </div>
          <div className='headerCheckoutTotal'>
            <div className='boxCHO1'>
              <span >SubTotal </span>
              <br />
              <span>Tax </span>
              <br />
              <span >TOTAL </span>
              {this.state.TipAmount > 0 ?
                <><span>Tip Amount </span><br /></> : null}
              {this.state.AmountPaid > 0 ?
                <><span>Amount Paid </span><br /></> : null}
              {this.state.AmountOwing < (this.state.TotalPriceWithTax - 0.01) ?
                <span>Owing </span> : null}
            </div>
            <div className='boxCHO2'>
              <span >{moneyFormat(this.state.TotalPrice)}</span>
              <br />
              <span>{moneyFormat(this.state.TotalTaxAmount)}</span>
              <br />
              <span style={{ fontWeight: "bold" }}>{moneyFormat(this.state.TotalPriceWithTax)}</span>
              <br />
              {this.state.TipAmount > 0 ?
                <><span>{moneyFormat(this.state.TipAmount)}</span><br /></> : null}
              {this.state.AmountPaid > 0 ?
                <><span>{moneyFormat(this.state.AmountPaid)}</span><br /></> : null}
              {this.state.AmountOwing < (this.state.TotalPriceWithTax - 0.01) ?
                <span>{moneyFormat(this.state.AmountOwing)}</span> : null}
            </div>
          </div>
        </div>

        {/****** Display Order - or Orders - Depends on showAllOrders */}
        <div className="bodyCheckout">
          {this.state.showSeat[0] === true ?
            <div className="seatCheckPane">
              {this.state.SeatCount > 1 && SessionInfo.AC.HasViewSeats === true ?
                <>
                  <div className="seatGroupHeader1">
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Seat: &nbsp;</span>
                    <span style={{ fontSize: 16,color: "darkblue",fontWeight: "bold",float: 'left' }} >{this.state.seatNames[0]} &nbsp;&nbsp;</span>
                    <span style={{ fontSize: 16,color: "darkblue",float: 'left' }} >{this.state.orderNames[0]} &nbsp;&nbsp;</span>
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Items: {this.state.items[0]} &nbsp;&nbsp;</span>
                  </div>
                  <div className="seatGroupHeader2">
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Price: {moneyFormat(this.state.price[0])} &nbsp;&nbsp;</span>
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Tax: {moneyFormat(this.state.tax[0])} &nbsp;&nbsp;</span>
                    {moneyFormat(this.state.price[0] + this.state.tax[0]) != moneyFormat(this.state.owing[0]) ?
                      <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Total: {moneyFormat(this.state.price[0] + this.state.tax[0])}</span> : null}
                  </div>
                </> :
                <div className="seatGroupHeader1">
                  <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Items Selected: {this.state.items[0]}</span>
                </div>}
              <div className="seatGroupHeader3">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Total Owing: {moneyFormat(this.state.owing[0])}</span>
                {this.state.paid[0] > 0 ?
                  <div>
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Paid: {moneyFormat(this.state.paid[0])} &nbsp;&nbsp;</span>
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Tip: {moneyFormat(this.state.tip[0])} &nbsp;&nbsp;</span>
                  </div> : null}
                {this.state.showAllOrders ?
                  <div style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'right',paddingRight: '10px' }}>
                    <Switch checked={this.state.payThisOrder[0]} name="PayThisOrder0" onChange={evt => this.chgPayOrder(evt)} />
                    {this.state.payThisOrder[0] ?
                      <span className="editFieldLabel"> &nbsp;Pay This Order</span> : <span className="editFieldLabel"> &nbsp;Pay For This?</span>}
                  </div> : null}
              </div>
              <ListView data={this.state.OrderItems[0]} item={this.SeatItemFormat} style={{ width: "100%" }} />
              {this.state.CustomOrderType === 1 ?
                <div>
                  <br />
                  <div className="displayInfo">
                    <span style={{ fontSize: 18,color: "blue",fontWeight: "bold",float: 'left' }} >&nbsp;Delivery Order &nbsp;&nbsp;</span>
                  </div>
                  <div className="displayInfo">
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >&nbsp;Delivery Time: &nbsp;</span>
                    <span style={{ fontSize: 16,color: "darkblue",fontWeight: "bold",float: 'left' }} >{dateFormat(this.state.RequestDateTime)}</span>
                  </div>
                  <div className="displayInfo">
                    <span style={{ fontSize: 16,color: "darkblue",fontWeight: "bold",float: 'left' }} >&nbsp;{this.state.Address}</span>
                  </div>
                  <br />
                </div> : null}
              {this.state.CustomOrderType === 2 ?
                <div>
                  <br />
                  <div className="displayInfo">
                    <span style={{ fontSize: 18,color: "blue",fontWeight: "bold",float: 'left' }} >&nbsp;Order for Pickup &nbsp;&nbsp;</span>
                  </div>
                  <div className="displayInfo">
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >&nbsp;&nbsp;Pickup Time: &nbsp;</span>
                    <span style={{ fontSize: 16,color: "darkblue",fontWeight: "bold",float: 'left' }} >{dateFormat(this.state.RequestDateTime)}</span>
                  </div>
                  <br />
                </div> : null}
              {this.state.CustomOrderType === 5 ?
                <div>
                  <br />
                  <div className="displayInfo">
                    <span style={{ fontSize: 18,color: "blue",fontWeight: "bold",float: 'left' }} >&nbsp;Order Ahead &nbsp;&nbsp;</span>
                  </div>
                  <div className="displayInfo">
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >&nbsp;&nbsp;Arrival Time: &nbsp;</span>
                    <span style={{ fontSize: 16,color: "darkblue",fontWeight: "bold",float: 'left' }} >{dateFormat(this.state.RequestDateTime)}</span>
                  </div>
                  <br />
                </div> : null}
            </div> : null}

          {this.state.showSeat[1] === true ?
            <div className="seatCheckPane">
              <div className="seatGroupHeader1">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Seat: &nbsp;</span>
                <span style={{ fontSize: 16,color: "darkblue",fontWeight: "bold",float: 'left' }} >{this.state.seatNames[1]} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "darkblue",float: 'left' }} >{this.state.orderNames[1]} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Items: {this.state.items[1]} &nbsp;&nbsp;</span>
              </div>
              <div className="seatGroupHeader2">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Price: {moneyFormat(this.state.price[1])} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Tax: {moneyFormat(this.state.tax[1])} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Total: {moneyFormat(this.state.price[1] + this.state.tax[1])}</span>
              </div>
              <div className="seatGroupHeader3">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Total Owing: {moneyFormat(this.state.owing[1])} &nbsp;&nbsp;</span>
                {this.state.paid[1] > 0 ?
                  <div>
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Paid: {moneyFormat(this.state.paid[1])} &nbsp;&nbsp;</span>
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Tip: {moneyFormat(this.state.tip[1])} &nbsp;&nbsp;</span>
                  </div> : null}
                <div style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'right',paddingRight: '10px' }}>
                  <Switch checked={this.state.payThisOrder[1]} name="PayThisOrder1" onChange={evt => this.chgPayOrder(evt)} />
                  {this.state.payThisOrder[1] ?
                    <span className="editFieldLabel"> &nbsp;Pay This Order</span> : <span className="editFieldLabel"> &nbsp;Pay For This?</span>}
                </div>
              </div>
              <ListView data={this.state.OrderItems[1]} item={this.SeatItemFormat} style={{ width: "100%" }} />
            </div> : null}
          {this.state.showSeat[2] === true ?
            <div className="seatCheckPane">
              <div className="seatGroupHeader1">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Seat: &nbsp;</span>
                <span style={{ fontSize: 16,color: "darkblue",fontWeight: "bold",float: 'left' }} >{this.state.seatNames[2]} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "darkblue",float: 'left' }} >{this.state.orderNames[2]} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Items: {this.state.items[2]} &nbsp;&nbsp;</span>
              </div>
              <div className="seatGroupHeader2">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Price: {moneyFormat(this.state.price[2])} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Tax: {moneyFormat(this.state.tax[2])} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Total: {moneyFormat(this.state.price[2] + this.state.tax[2])}</span>
              </div>
              <div className="seatGroupHeader3">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Total Owing: {moneyFormat(this.state.owing[2])} &nbsp;&nbsp;</span>
                {this.state.paid[2] > 0 ?
                  <div>
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Paid: {moneyFormat(this.state.paid[2])} &nbsp;&nbsp;</span>
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Tip: {moneyFormat(this.state.tip[2])} &nbsp;&nbsp;</span>
                  </div> : null}
                <div style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'right',paddingRight: '10px' }}>
                  <Switch checked={this.state.payThisOrder[2]} name="PayThisOrder2" onChange={evt => this.chgPayOrder(evt)} />
                  {this.state.payThisOrder[2] ?
                    <span className="editFieldLabel"> &nbsp;Pay This Order</span> : <span className="editFieldLabel"> &nbsp;Pay For This?</span>}
                </div>
              </div>
              <ListView data={this.state.OrderItems[2]} item={this.SeatItemFormat} style={{ width: "100%" }} />
            </div> : null}
          {this.state.showSeat[3] === true ?
            <div className="seatCheckPane">
              <div className="seatGroupHeader1">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Seat: &nbsp;</span>
                <span style={{ fontSize: 16,color: "darkblue",fontWeight: "bold",float: 'left' }} >{this.state.seatNames[3]} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "darkblue",float: 'left' }} >{this.state.orderNames[3]} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Items: {this.state.items[3]} &nbsp;&nbsp;</span>
              </div>
              <div className="seatGroupHeader2">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Price: {moneyFormat(this.state.price[3])} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Tax: {moneyFormat(this.state.tax[2])} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Total: {moneyFormat(this.state.price[3] + this.state.tax[2])}</span>
              </div>
              <div className="seatGroupHeader3">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Total Owing: {moneyFormat(this.state.owing[3])} &nbsp;&nbsp;</span>
                {this.state.paid[3] > 0 ?
                  <div>
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Paid: {moneyFormat(this.state.paid[3])} &nbsp;&nbsp;</span>
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Tip: {moneyFormat(this.state.tip[3])} &nbsp;&nbsp;</span>
                  </div> : null}
                <div style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'right',paddingRight: '10px' }}>
                  <Switch checked={this.state.payThisOrder[3]} name="PayThisOrder3" onChange={evt => this.chgPayOrder(evt)} />
                  {this.state.payThisOrder[3] ?
                    <span className="editFieldLabel"> &nbsp;Pay This Order</span> : <span className="editFieldLabel"> &nbsp;Pay For This?</span>}
                </div>
              </div>
              <ListView data={this.state.OrderItems[3]} item={this.SeatItemFormat} style={{ width: "100%" }} />
            </div> : null}
          {this.state.showSeat[4] === true ?
            <div className="seatCheckPane">
              <div className="seatGroupHeader1">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Seat: &nbsp;</span>
                <span style={{ fontSize: 16,color: "darkblue",fontWeight: "bold",float: 'left' }} >{this.state.seatNames[4]} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "darkblue",float: 'left' }} >{this.state.orderNames[4]} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Items: {this.state.items[4]} &nbsp;&nbsp;</span>
              </div>
              <div className="seatGroupHeader2">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Price: {moneyFormat(this.state.price[4])} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Tax: {moneyFormat(this.state.tax[4])} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Total: {moneyFormat(this.state.price[4] + this.state.tax[2])}</span>
              </div>
              <div className="seatGroupHeader3">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Total Owing: {moneyFormat(this.state.owing[4])} &nbsp;&nbsp;</span>
                {this.state.paid[4] > 0 ?
                  <div>
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Paid: {moneyFormat(this.state.paid[4])} &nbsp;&nbsp;</span>
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Tip: {moneyFormat(this.state.tip[4])} &nbsp;&nbsp;</span>
                  </div> : null}
                <div style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'right',paddingRight: '10px' }}>
                  <Switch checked={this.state.payThisOrder[4]} name="PayThisOrder4" onChange={evt => this.chgPayOrder(evt)} />
                  {this.state.payThisOrder[4] ?
                    <span className="editFieldLabel"> &nbsp;Pay This Order</span> : <span className="editFieldLabel"> &nbsp;Pay For This?</span>}
                </div>
              </div>
              <ListView data={this.state.OrderItems[4]} item={this.SeatItemFormat} style={{ width: "100%" }} />
            </div> : null}
          {this.state.showSeat[5] === true ?
            <div className="seatCheckPane">
              <div className="seatGroupHeader1">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Seat: &nbsp;</span>
                <span style={{ fontSize: 16,color: "darkblue",fontWeight: "bold",float: 'left' }} >{this.state.seatNames[5]} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "darkblue",float: 'left' }} >{this.state.orderNames[5]} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Items: {this.state.items[5]} &nbsp;&nbsp;</span>
              </div>
              <div className="seatGroupHeader2">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Price: {moneyFormat(this.state.price[5])} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Tax: {moneyFormat(this.state.tax[5])} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Total: {moneyFormat(this.state.price[5] + this.state.tax[2])}</span>
              </div>
              <div className="seatGroupHeader3">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Total Owing: {moneyFormat(this.state.owing[5])} &nbsp;&nbsp;</span>
                {this.state.paid[5] > 0 ?
                  <div>
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Paid: {moneyFormat(this.state.paid[5])} &nbsp;&nbsp;</span>
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Tip: {moneyFormat(this.state.tip[5])} &nbsp;&nbsp;</span>
                  </div> : null}
                <div style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'right',paddingRight: '10px' }}>
                  <Switch checked={this.state.payThisOrder[5]} name="PayThisOrder5" onChange={evt => this.chgPayOrder(evt)} />
                  {this.state.payThisOrder[5] ?
                    <span className="editFieldLabel"> &nbsp;Pay This Order</span> : <span className="editFieldLabel"> &nbsp;Pay For This?</span>}
                </div>
              </div>
              <ListView data={this.state.OrderItems[5]} item={this.SeatItemFormat} style={{ width: "100%" }} />
            </div> : null}
          {this.state.showSeat[6] === true ?
            <div className="seatCheckPane">
              <div className="seatGroupHeader1">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Seat: &nbsp;</span>
                <span style={{ fontSize: 16,color: "darkblue",fontWeight: "bold",float: 'left' }} >{this.state.seatNames[6]} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "darkblue",float: 'left' }} >{this.state.orderNames[6]} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Items: {this.state.items[6]} &nbsp;&nbsp;</span>
              </div>
              <div className="seatGroupHeader2">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Price: {moneyFormat(this.state.price[6])} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Tax: {moneyFormat(this.state.tax[6])} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Total: {moneyFormat(this.state.price[6] + this.state.tax[2])}</span>
              </div>
              <div className="seatGroupHeader3">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Total Owing: {moneyFormat(this.state.owing[6])} &nbsp;&nbsp;</span>
                {this.state.paid[6] > 0 ?
                  <div>
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Paid: {moneyFormat(this.state.paid[6])} &nbsp;&nbsp;</span>
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Tip: {moneyFormat(this.state.tip[6])} &nbsp;&nbsp;</span>
                  </div> : null}
                <div style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'right',paddingRight: '10px' }}>
                  <Switch checked={this.state.payThisOrder[6]} name="PayThisOrder6" onChange={evt => this.chgPayOrder(evt)} />
                  {this.state.payThisOrder[6] ?
                    <span className="editFieldLabel"> &nbsp;Pay This Order</span> : <span className="editFieldLabel"> &nbsp;Pay For This?</span>}
                </div>
              </div>
              <ListView data={this.state.OrderItems[6]} item={this.SeatItemFormat} style={{ width: "100%" }} />
            </div> : null}
          {this.state.showSeat[7] === true ?
            <div className="seatCheckPane">
              <div className="seatGroupHeader1">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Seat: &nbsp;</span>
                <span style={{ fontSize: 16,color: "darkblue",fontWeight: "bold",float: 'left' }} >{this.state.seatNames[7]} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "darkblue",float: 'left' }} >{this.state.orderNames[7]} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Items: {this.state.items[7]} &nbsp;&nbsp;</span>
              </div>
              <div className="seatGroupHeader2">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Price: {moneyFormat(this.state.price[7])} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Tax: {moneyFormat(this.state.tax[7])} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Total: {moneyFormat(this.state.price[7] + this.state.tax[2])}</span>
              </div>
              <div className="seatGroupHeader3">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Total Owing: {moneyFormat(this.state.owing[7])} &nbsp;&nbsp;</span>
                {this.state.paid[7] > 0 ?
                  <div>
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Paid: {moneyFormat(this.state.paid[7])} &nbsp;&nbsp;</span>
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Tip: {moneyFormat(this.state.tip[7])} &nbsp;&nbsp;</span>
                  </div> : null}
                <div style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'right',paddingRight: '10px' }}>
                  <Switch checked={this.state.payThisOrder[7]} name="PayThisOrder7" onChange={evt => this.chgPayOrder(evt)} />
                  {this.state.payThisOrder[7] ?
                    <span className="editFieldLabel"> &nbsp;Pay This Order</span> : <span className="editFieldLabel"> &nbsp;Pay For This?</span>}
                </div>
              </div>
              <ListView data={this.state.OrderItems[7]} item={this.SeatItemFormat} style={{ width: "100%" }} />
            </div> : null}
          {this.state.showSeat[8] === true ?
            <div className="seatCheckPane">
              <div className="seatGroupHeader1">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Seat: &nbsp;</span>
                <span style={{ fontSize: 16,color: "darkblue",fontWeight: "bold",float: 'left' }} >{this.state.seatNames[8]} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "darkblue",float: 'left' }} >{this.state.orderNames[8]} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Items: {this.state.items[8]} &nbsp;&nbsp;</span>
              </div>
              <div className="seatGroupHeader2">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Price: {moneyFormat(this.state.price[8])} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Tax: {moneyFormat(this.state.tax[8])} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Total: {moneyFormat(this.state.price[8] + this.state.tax[2])}</span>
              </div>
              <div className="seatGroupHeader3">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Total Owing: {moneyFormat(this.state.owing[8])} &nbsp;&nbsp;</span>
                {this.state.paid[8] > 0 ?
                  <div>
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Paid: {moneyFormat(this.state.paid[8])} &nbsp;&nbsp;</span>
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Tip: {moneyFormat(this.state.tip[8])} &nbsp;&nbsp;</span>
                  </div> : null}
                <div style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'right',paddingRight: '10px' }}>
                  <Switch checked={this.state.payThisOrder[8]} name="PayThisOrder8" onChange={evt => this.chgPayOrder(evt)} />
                  {this.state.payThisOrder[8] ?
                    <span className="editFieldLabel"> &nbsp;Pay This Order</span> : <span className="editFieldLabel"> &nbsp;Pay For This?</span>}
                </div>
              </div>
              <ListView data={this.state.OrderItems[8]} item={this.SeatItemFormat} style={{ width: "100%" }} />
            </div> : null}
          {this.state.showSeat[9] === true ?
            <div className="seatCheckPane">
              <div className="seatGroupHeader1">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Seat: &nbsp;</span>
                <span style={{ fontSize: 16,color: "darkblue",fontWeight: "bold",float: 'left' }} >{this.state.seatNames[9]} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "darkblue",float: 'left' }} >{this.state.orderNames[9]} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Items: {this.state.items[9]} &nbsp;&nbsp;</span>
              </div>
              <div className="seatGroupHeader2">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Price: {moneyFormat(this.state.price[9])} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Tax: {moneyFormat(this.state.tax[9])} &nbsp;&nbsp;</span>
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Total: {moneyFormat(this.state.price[9] + this.state.tax[2])}</span>
              </div>
              <div className="seatGroupHeader3">
                <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Total Owing: {moneyFormat(this.state.owing[9])} &nbsp;&nbsp;</span>
                {this.state.paid[9] > 0 ?
                  <div>
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Paid: {moneyFormat(this.state.paid[9])} &nbsp;&nbsp;</span>
                    <span style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'left' }} >Tip: {moneyFormat(this.state.tip[9])} &nbsp;&nbsp;</span>
                  </div> : null}
                <div style={{ fontSize: 16,color: "black",fontWeight: "bold",float: 'right',paddingRight: '10px' }}>
                  <Switch checked={this.state.payThisOrder[9]} name="PayThisOrder9" onChange={evt => this.chgPayOrder(evt)} />
                  {this.state.payThisOrder[9] ?
                    <span className="editFieldLabel"> &nbsp;Pay This Order</span> : <span className="editFieldLabel"> &nbsp;Pay For This?</span>}
                </div>
              </div>
              <ListView data={this.state.OrderItems[9]} item={this.SeatItemFormat} style={{ width: "100%" }} />
            </div> : null}
        </div>

        {/*<div className="footerCheckout">
                </div> */}
        {/*---------------------------------------------------------------------------------------------------*/}
        {/* Message Notify Popup */}
        <Popup offset={SessionInfo.popupNotifyOffset} show={this.state.showNotify} popupClass={'popup-content'} >
          <div id="messageBox" dangerouslySetInnerHTML={{ __html: this.state.notifyMessage }} onClick={(e) => { this.acknowledgeMessage(); }} />
          {SessionInfo.acknowledge ?
            <div className="bottomButton">
              <Button icon="tell-a-friend" className="mediumButton" onClick={this.acknowledgeMessage}>Acknowledge</Button>
            </div> : null}
        </Popup>
        {/* Delete Item Popup */}
        <Popup offset={this.deletePopupOffset} show={this.state.showDeleteItem} popupClass={'popup-content'} >
          <div id="seatBox">
            {this.state.DeleteItemCount > 1 ?
              <div id="div2" style={{ fontSize: 18,color: "darkblue",fontWeight: "bold",paddingLeft: "20px",paddingTop: "3px" }}>
                <span >Remove Items?</span>
              </div> :
              <div id="div2" style={{ fontSize: 18,color: "darkblue",fontWeight: "bold",paddingLeft: "20px",paddingTop: "3px" }}>
                <span >Remove Item?</span>
              </div>}
            <br />
            <div id="div2" style={{ fontSize: 18,color: "black",fontWeight: "bold",paddingLeft: "20px",paddingTop: "3px" }}>
              <span >{this.state.DeleteItemName}</span>
            </div>
            <br />
            <div id="div2" style={{ fontSize: 14,color: "black",workWrap: "break-word",paddingLeft: "10px",paddingTop: "1px",paddingRight: "6px" }}>
              <span >{this.state.DeleteItemDescription}&nbsp;</span>
            </div>
            {this.state.DeleteItemCount > 1 ?
              <div id="div2" style={{ fontSize: 16,color: "black",workWrap: "break-word",paddingLeft: "10px",paddingTop: "1px",paddingRight: "6px" }}>
                <br />
                <span > Note: Remove all {this.state.DeleteItemCount} Items?&nbsp;</span>
              </div> : null}
            <br />
            <div style={{ height: 39,width: '100%',marginTop: 5 }}>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <Button id="addButton" icon="checkbox-checked" className="smallButton" onClick={this.onDeleteOrderItem} >&nbsp;Remove&nbsp;</Button>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <Button id="doneButton" icon="close-outline" className="smallButton" onClick={this.doneDelete}>Back</Button>
              <span>&nbsp;&nbsp;&nbsp;</span>
            </div>
            <br />
          </div>
        </Popup>
        {/* Full Payment Form Popup */}
        <Popup offset={this.popupFormOffset} show={this.state.showPaymentFull} popupClass={'popup-content'} ><div style={{ backgroundColor: 'linen',}}>{this.PaymentCardForm()}</div></Popup>
        {/* Postal Only Payment Form Popup */}
        <Popup offset={this.popupFormOffset} show={this.state.showPaymentPostal} popupClass={'popup-content'} ><div style={{ backgroundColor: 'linen',}}>{this.PaymentCardFormPostal()}</div></Popup>
        {/* Short Payment Form Popup */}
        <Popup offset={this.popupFormOffset} show={this.state.showPaymentShort} popupClass={'popup-content'} ><div style={{ backgroundColor: 'linen',}}>{this.PaymentCardFormShort()}</div></Popup>
        {/* Extra Short Payment Form Popup */}
        <Popup offset={this.popupFormOffset} show={this.state.showPaymentMinimal} popupClass={'popup-content'} ><div style={{ backgroundColor: 'linen',}}>{this.PaymentCardFormMinimal()}</div></Popup>
        {/* eXtremely Short Payment Form Popup */}
        {/*<Popup offset={this.popupFormOffset} show={this.state.showPaymentXMinimal} popupClass={'popup-content'} ><div style={{ backgroundColor: 'linen',}}>{this.PaymentCardFormXMinimal()}</div></Popup>*/}
        <Popup offset={this.popupFormOffset} show={this.state.showPaymentXMinimal} popupClass={'popup-content'} >
          <div className='XMinimalCardForm1'>
            <h4>PAYMENT CARD INFORMATION</h4>
            <div id="PaymentForm">
              <Cards
                cvc={this.state.cvc}
                expiry={this.state.expiry}
                focused={this.state.focus}
                name={this.state.name}
                number={this.state.number}
              />
              <div className='XMinimalCardForm3'>
                <form>
                  <br />
                  <div className='editField'>
                    <input type="tel" name="number" placeholder="Card Number" onChange={this.handleInputChange} onFocus={this.handleInputFocus} onBlur={e => this.verifyCard(e)} className='editCardInput' />
                  </div>
                  <div className='editField'>
                    <input type="tel" name="cvc" placeholder="CVV" onChange={this.handleInputChange} onFocus={this.handleInputFocus} onBlur={e => this.verifyCVC(e)} className='editCardShortInputCVC' />
                  </div>
                  <div className='editField'>
                    <input type="tel" name="expiry" value={this.state.expiry} placeholder="Expiry Date" onChange={this.handleInputChangeEx} onFocus={this.handleInputFocus} onBlur={e => this.verifyExpiry(e)} className='editCardShortInputEXP' />
                  </div>
                  <div className='editField'>
                    <input type="text" name="name" placeholder={this.state.CCFullName} onChange={this.handleInputChange} onFocus={this.handleInputFocus} className='editCardInputName' />
                  </div>
                  {/*<MaskedTextBox value={MaskString(this.state.number,"#### #### #### ####")} name='number' onChange={evt => this.chgFldValMask(evt,"#### #### #### ####")} mask="#### #### #### ####" className='editPhoneInput' />
                      <br />
                      <MaskedTextBox value={MaskString(this.state.cvc,"###")} name='cvc' onChange={evt => this.chgFldValMask(evt,"###")} mask="###" className='editPhoneInput' />
                      <br />
                      <MaskedTextBox value={MaskString(this.state.expiry,"##/##")} name='expiry' onChange={evt => this.chgFldValMask(evt,"##/##")} mask="##/##" className='editPhoneInput' /> */}
                  <span>&nbsp;  </span>
                </form>
                <span>&nbsp; &nbsp;  &nbsp; &nbsp; </span>
                <Button icon="track-changes-accept" className="smallButton" enabled="false" onClick={this.processPaymentXMinimal}>&nbsp; Pay &nbsp; </Button>
                <span>&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  </span>
                <Button icon="close-circle" className="smallButton" onClick={this.cancelPayment}>&nbsp; Cancel &nbsp; </Button>
                {/*{this.PaymentCardFormXMinimal2()}
                    <form>
                      <input
                        type="tel"
                        name="number"
                        placeholder="Card Number"
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                      />
                      ...
                    </form> */}
              </div>
            </div>
            {/*<Button className="smallButton" onClick={this.processPayment}>Pay</Button>
                  <span>&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;  </span>
                  <Button className="smallButton" onClick={this.cancelPayment}>Cancel</Button>*/}
          </div>
        </Popup>
        {/*<Popup offset={this.popupFormOffset} show={this.state.showPaymentXMinimal} popupClass={'popup-content'} >
                <div className='XMinimalCardForm'>
                  <h4>Payment Card Informationz</h4>
                  <CreditCardInput
                    cardNumberInputProps={{ value: this.cardNumber,onChange: this.handleChangeCardNumber }}
                    cardExpiryInputProps={{ value: this.expiry,onChange: this.handleChangeExpiryDate }}
                    cardCVCInputProps={{ value: this.cvc,onChange: this.handleChangeCVC }}
                    fieldClassName="input"
                  />
                  <CreditCardInput    
                    cardNumberInputRenderer={({ handleCardNumberChange,props }) => (
                      <input {...props} onChange={handleCardNumberChange(e => this.handleChangeCardNumber(e))} /> 
                    )}
                    cardCVCInputRenderer={({ handleCardCVCChange,props }) => (
                      <input {...props} onChange={handleCardCVCChange(e => this.handleChangeCVC(e))} />
                    )}
                    cardExpiryInputRenderer={({ handleCardExpiryChange,props }) => (
                      <input {...props} onChange={handleCardExpiryChange(e => this.handleChangeExpiryDate(e))} />
                    )}
                  />
                  <PaymentInputsContainer>
                    {({ meta,getCardNumberProps,getExpiryDateProps,getCVCProps }) => (
                      <div>
                        <input {...getCardNumberProps({ onChange: handleChangeCardNumber })} value={cardNumber} />
                        <input {...getExpiryDateProps({ onChange: handleChangeExpiryDate })} value={expiryDate} />
                        <input {...getCVCProps({ onChange: handleChangeCVC })} value={cvc} />
                        {meta.isTouched && meta.error && <span>Error: {meta.error}</span>}
                      </div>
                    )}
                  </PaymentInputsContainer>
                  <Button className="smallButton" onClick={this.processPayment}>Pay</Button>
                  <span>&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;  </span>
                  <Button className="smallButton" onClick={this.cancelPayment}>Cancel</Button>
                </div>
              </Popup>*/}
        {/*<Popup offset={this.popupFormOffset} show={this.state.showPaymentXMinimal} popupClass={'popup-content'} >
                <div className='XMinimalCardForm'>
                  <h4>Payment Card Information</h4>
                  <MaskedTextBox value={MaskString(this.state.Identifier,"#### #### #### ####")} name='Identifier' onChange={evt => this.chgFldValMask(evt,"#### #### #### ####")} mask="#### #### #### ####" className='editPhoneInput' />
                  <MaskedTextBox value={MaskString(this.state.CCV,"###")} name='CCV' onChange={evt => this.chgFldValMask(evt,"###")} mask="###" className='editPhoneInput' />
                  <MaskedTextBox value={MaskString(this.state.ExpiryDate,"##/##")} name='ExpiryDate' onChange={evt => this.chgFldValMask(evt,"##/##")} mask="##/##" className='editPhoneInput' />
                  <span>&nbsp;  </span>
                  <Button className="smallButton" onClick={this.processPayment}>Pay</Button>
                  <span>&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;  </span>
                  <Button className="smallButton" onClick={this.cancel}>Cancel</Button>
                </div>
              </Popup>*/}
        {/* PaymentBambora Form Popup */}
        {/*<Popup offset={this.popupFormOffset} show={this.state.showPaymentBambora} popupClass={'popup-content'} ><div style={{ backgroundColor: 'linen', }}>{this.PaymentBambora()}</div></Popup>*/}
        {/* See InitBamScript for Functions on Pay Etc. */}
        <Popup offset={this.popupFormOffset} show={this.state.showPaymentBambora} popupClass={'popup-content'} >
          <div className="bamboraPane">
            <div className="bamboraHeading">
              <legend className={'k-form-legend'} style={{ fontWeight: 'bold',fontSize: '18px' }}>Payment Card Information</legend>
            </div>
            <div className="bamboraCard">
              <div dangerouslySetInnerHTML={{ __html: this.BamboraContent }} />
            </div>
            <div className="bamboraFooter">
              {/*<Button id="cancelButton" icon="close-circle" style={{ cursor: 'pointer', fontSize: '14', color: 'black', fontWeight: 'bold', borderRadius: '5px', left: '20px', width: '150px', height: '24px' }} onClick={(e) => { this.cancelPay() }} >&nbsp;Cancel &nbsp;</Button>*/}
              <Button id="cancelButton" icon="close-circle" className="smallButton" style={{ left: '170px',}} onClick={this.cancelPay} >&nbsp;CANCEL &nbsp;</Button>
            </div>
          </div>
        </Popup>
        {/* Verify Cancel Popup */}
        <Popup offset={this.addPopupOffset} show={this.state.showVerifyCancel} popupClass={'popup-content'} >
          <div id="seatBox">
            <br />
            <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >&nbsp;&nbsp; Cancel Order:&nbsp;</span><span style={{ fontSize: 15,color: "black",fontWeight: "bold" }} >#{SessionInfo.CustomOrderID}&nbsp;&nbsp;</span>
            <span style={{ fontSize: 15,color: "black",fontWeight: "bold" }} >{this.state.SeatName}&nbsp;&nbsp;</span>
            <span style={{ fontSize: 15,color: "black",fontWeight: "bold" }} > at {this.state.TableName}&nbsp;&nbsp;</span>
            <br />
            <br />
            <br />
            <Button id="verifyButton" icon="checkbox-checked" style={{ cursor: 'pointer',fontSize: '12',color: 'black',fontWeight: 'bold',borderRadius: '5px',left: '20px',height: '20px' }} onClick={this.verifiedCancelOrder} >&nbsp;Yes - Cancel</Button>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <Button id="cancelButton" icon="cancel" style={{ cursor: 'pointer',fontSize: '12',color: 'black',fontWeight: 'bold',borderRadius: '5px',left: '20px',height: '20px' }} onClick={this.skipCancel} >&nbsp;No&nbsp;</Button>
            <br />
            <br />
            <br />
          </div>
        </Popup>
        {/* Order QR Code Popup */}
        <Popup offset={this.showQRPopupOffset} show={this.state.showOrderQRCode} popupClass={'popupQRCode'}>
          <div className="wholeDiv">
            <div className="headerScan" >
              <span style={{ fontSize: 16,color: "black",fontWeight: "bold",left: "12px",position: "absolute" }} >VOID ORDER SCAN</span>
              <br />
              <br />
              <div style={{ fontSize: 16,color: "black",position: "relative",height: "20px" }}>
                <span >Location</span>
              </div>
              <span style={{ fontSize: 18,color: "black",fontWeight: "bold" }} >&nbsp;{this.state.StoreName}</span>
              <br />
              <br />
              <div style={{ fontSize: 16,color: "black",position: "relative",height: "20px" }}>
                <span >Order Number</span>
              </div>
              <span style={{ fontSize: 18,color: "black",fontWeight: "bold",left: "10px" }} >&nbsp;#{SessionInfo.CustomOrderID}</span>
            </div>
            <div style={{ position: "absolute",top: "125px",left: "25px" }} onClick={(e) => this.closePopups(7)}>
              <QRCode
                value={`${SessionInfo.CustomOrderID}|${SessionInfo.OMUserID}`}
                errorCorrection="M"
              />
            </div>
            <div style={{ position: "absolute",top: "295px",left: "33px" }}>
              <Button id="voidOrder" icon="file-excel" look="outline" className="leftButton" onClick={this.onVoidOrderItems} >Void Order Item</Button>
            </div>
            <div style={{ position: "absolute",top: "340px",left: "50px" }}>
              <Button id="QRDoneButton" icon="cancel" look="outline" className="leftButton" onClick={(e) => this.closePopups(7)} >&nbsp;Done &nbsp;</Button>
            </div>
          </div>
        </Popup>
        <Popup offset={this.showQRPopupOffset} show={this.state.showPayCash} popupClass={'popupQRCode'}>
          <div className="wholeDiv">
            <div className="headerScan" >
              <br />
              <span style={{ fontSize: 22,fontWeight: "bold" }} >&nbsp; &nbsp; &nbsp; PAY CASH</span>
              <br />
              <br />
              <span style={{ fontSize: 18,fontWeight: "bold" }} >&nbsp; &nbsp; {this.state.StoreName}</span>
              <br />
              <br />
              <div style={{ fontSize: 16,position: "relative",height: "25px",left: "10px" }}>
                <span style={{ fontSize: 16,color: "black" }} >Order &nbsp;</span>
                <span style={{ fontSize: 18,fontWeight: "bold" }} >&nbsp;#{SessionInfo.CustomOrderID}</span>
              </div>
              <br />
              <div style={{ fontSize: 16,position: "relative",height: "20px",left: "10px" }}>
                <span style={{ fontSize: 16,color: "black" }} >Pay Amount</span>
                <span style={{ fontSize: 20,fontWeight: "bold",left: "10px" }} >&nbsp; &nbsp;{this.state.ActualPaymentAmountS}</span>
              </div>
              <br />
              <br />
              <div style={{ fontSize: 16,color: SessionInfo.inputTextColor,position: "relative",height: "20px" }}>
                <span >&nbsp;&nbsp;Server will Scan QR</span>
              </div>
              <br />
            </div>
            <div style={{ position: "absolute",top: "184px",left: "25px" }} onClick={(e) => this.closePopups(7)}>
              <QRCode
                value={`${SessionInfo.CustomOrderID}|${SessionInfo.OMUserID}`}
                errorCorrection="M"
              />
            </div>
            <div style={{ position: "absolute",top: "354px",left: "50px" }}>
              <Button id="QRDoneButton" icon="cancel" look="outline" className="leftButton" onClick={this.payCash} >&nbsp;Done &nbsp;</Button>
            </div>
          </div>
        </Popup>
        {/* Item QR Code Popup */}
        <Popup offset={this.showQRPopupOffset} show={this.state.showItemQRCode} popupClass={'popupQRCode'}>
          <div className="wholeDiv">
            <div className="headerScan" >
              <span style={{ fontSize: 14,color: "black",fontWeight: "bold",left: "1px",position: "absolute" }} >VOID ORDER ITEM SCAN</span>
              <br />
              <br />
              <div style={{ fontSize: 16,color: "black",position: "relative",height: "20px" }}>
                <span >Location</span>
              </div>
              <span style={{ fontSize: 18,color: "black",fontWeight: "bold" }} >&nbsp;{this.state.StoreName}</span>
              <br />
              <br />
              <div style={{ fontSize: 16,color: "black",position: "relative",height: "20px" }}>
                <span >Item</span>
              </div>
              <span style={{ fontSize: 14,color: "black",fontWeight: "bold",left: "10px" }} >&nbsp;{this.itemName}</span>
            </div>
            <div style={{ position: "absolute",top: "140px",left: "25px" }} onClick={(e) => this.closePopups(7)}>
              <QRCode
                value={`${this.deleteCustomOrderItemID}|${SessionInfo.CustomOrderID}|${SessionInfo.OMUserID}`}
                errorCorrection="M"
              />
            </div>
            <div style={{ position: "absolute",top: "330px",left: "45px" }}>
              <Button id="QRDoneButton" icon="cancel" look="outline" className="leftButton" onClick={(e) => this.closePopups(7)} >&nbsp;Done &nbsp;</Button>
            </div>
          </div>
        </Popup>
        {/* Item Change Popup */}
        <Popup offset={this.itemChangeOffset} show={this.state.showItemChangePopup} popupClass={'popup-content'} >
          <div id="queryBox">
            <br />
            <span style={{ fontSize: 15,color: "black",fontWeight: "bold" }} >&nbsp;&nbsp;{this.state.chooseItemName}&nbsp;&nbsp;</span>
            <br />
            <br />
            {this.state.ItemsList && this.state.ItemsList.length <= 1 ?
              <div id="div2" className="editField">
                <Button id="changeButton" icon="track-changes" style={{ cursor: 'pointer',fontSize: '14',color: 'black',fontWeight: 'bold',borderRadius: '5px',left: '20px',width: '150px',height: '24px' }} onClick={(e) => { this.changeOrder(this.chooseItem); }} >&nbsp;Change Item &nbsp;</Button>
              </div> :
              <ListView data={this.state.ItemsList} item={this.ItemListFormat} style={{ width: "100%" }} header={this.ItemListHeader} footer={this.ItemListFooter} />}
            <br />
            <div id="div2" className="editField">
              <Button id="deleteButton" icon="delete" style={{ cursor: 'pointer',fontSize: '14',color: 'black',fontWeight: 'bold',borderRadius: '5px',left: '20px',width: '150px',height: '24px' }} onClick={(e) => { this.removeItemFromOrder(this.chooseItem); }} >&nbsp;Remove Item &nbsp;</Button>
            </div>
            <br />
            <div id="div2" className="editField">
              <Button id="cancelButton" icon="close-circle" style={{ cursor: 'pointer',fontSize: '14',color: 'black',fontWeight: 'bold',borderRadius: '5px',left: '20px',width: '150px',height: '24px' }} onClick={(e) => { this.cancelAddChgDel() }} >&nbsp;Cancel &nbsp;</Button>
            </div>
            <br />
          </div>
        </Popup>
        {/* Review Purchase Popup */}
        <Popup offset={this.reviewPurchaseOffset} show={this.state.showReviewPurchasePopup} popupClass={'popupReviewPurchase'} >
          <div className="pageReviewBorder">
            <div className="checkoutTop">
              <div className="checkoutLeft">
                <div onClick={this.onCancel}>&nbsp; {this.backCartPrompt}</div>
              </div>
            </div>
            <div className="checkoutHeaderDetail">
              <div style={{ marginTop: '15px' }}>REVIEW YOUR PURCHASE</div>
              <div style={{ marginTop: '5px',fontSize: '14px' }} >{this.datetimeFormat(this.state.OrderCreationDate)}</div>
            </div>
            <div id="div1" className="listREVContainer">
              <div className="boxREV1">
                <span > ITEM COUNT</span>
                <br />
                <span > PRICE</span>
                <br />
                <span > TAX AMOUNT</span>
                <br />
                <span > TOTAL PRICE</span>
                <br />
                <span > AMOUNT OWING</span>
              </div>
              <div className="boxREV2">
                <span>{this.state.ItemCount}</span>
                <br />
                <span>{moneyFormat(this.state.TotalPrice)}</span>
                <br />
                <span>{moneyFormat(this.state.TotalTaxAmount)}</span>
                <br />
                <span>{moneyFormat(this.state.TotalPriceWithTax)}</span>
                <br />
                <span>{moneyFormat(this.state.AmountOwing)}</span>
              </div>
              <div className="boxREV25">
              </div>
            </div>
            {this.state.hasTip ?
              <>
                <div id="div1" className="listREVContainer2">
                  <h4>SELECT YOUR TIP</h4>
                  <br />
                  <div className="buttonContainer">
                    <div className="buttonLeft">
                      <Tooltip position="top" anchorElement="pointer">
                        <Button icon="arrow-60-up" className="mediumButton" title="Add Tip" onClick={this.onSetTip1}>&nbsp;{this.state.TipRate1}%&nbsp;</Button>
                      </Tooltip>
                    </div>
                    <div className="buttonMiddle">
                      <Tooltip position="right" anchorElement="pointer">
                        <Button icon="arrow-seek-up" className="mediumButton" title="Add Tip" onClick={this.onSetTip2}>&nbsp;{this.state.TipRate2}%&nbsp;</Button>
                      </Tooltip>
                    </div>
                    <div className="buttonRight">
                      <Tooltip position="right" anchorElement="pointer">
                        <Button icon="notification" className="mediumButton" title="Add Tip" onClick={this.onSetTip3}>&nbsp;{this.state.TipRate3}%&nbsp; </Button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div id="div1" className="listREVContainer3">
                  <div className="editField">
                    <span className="editFieldLabelB" style={{ fontSize: '16px' }}> &nbsp; &nbsp; &nbsp; ENTER TIP &nbsp; $</span>
                    <span>&nbsp; &nbsp; <input value={this.state.TipAmountOverDS} name="TipAmountOverD" onChange={evt => this.chgMoneyFldValD(evt)} onFocus={evt => this.focusMoneyFldVal(evt)} onBlur={evt => this.blurTipAmountD(evt)} type='tel' className="editMoneyInputBS" /></span>
                  </div>
                  <div className="editField">
                    <span className="editFieldLabelB" style={{ fontSize: '16px' }}> &nbsp; &nbsp; &nbsp;ENTER TIP &nbsp; %</span>
                    <span>&nbsp; &nbsp; <input value={this.state.TipAmountOverPS} name="TipAmountOverP" onChange={evt => this.chgPercentFldValP(evt)} onFocus={evt => this.focusPercentFldVal(evt)} onBlur={evt => this.blurTipAmountP(evt)} type='tel' className="editMoneyInputBS" /></span>
                  </div>
                </div>
                <div id="div1" className="listREVContainer4">
                  <div className="boxREV1">
                    <span>TIP AMOUNT</span>
                    <br />
                    <span>TOTAL + TIP</span>
                    <br />
                    {SessionInfo.AllowPartial === true ?
                      <><span>AMOUNT PAID</span><br /> </> : null}
                    <span>PAY AMOUNT</span>
                  </div>
                  <div className="boxREV2">
                    <span>{moneyFormat(this.state.TipAmount)}</span>
                    <br />
                    <span>{moneyFormat(this.state.AmountOwingWithTip)}</span>
                  </div>
                  <br />
                  {SessionInfo.AllowPartial === true ?
                    <><span>{moneyFormat(this.state.AmountPaid)}</span><br /> </> : null}
                  {SessionInfo.AllowPartial === true ?
                    <input value={this.state.ActualPaymentAmountS} name="ActualPaymentAmount" style={this.state.PayStyle} onChange={evt => this.chgMoneyFldVal(evt)} onFocus={evt => this.focusPayAmount(evt)} onBlur={evt => this.blurPayAmount(evt)} type='tel' className="editMoneyInputBS" />
                    : <span>{moneyFormat(this.state.AmountOwingWithTip)}</span>}
                </div>
              </> :
              <div id="div1" className="listREVContainer8">
                <br />
                {/*{SessionInfo.AllowPartial === true ?
                  <><span>xx{moneyFormat(this.state.AmountPaid)}</span><br /> </> : null}
                {SessionInfo.AllowPartial === true ?
                  <input value={this.state.ActualPaymentAmountS} name="ActualPaymentAmount" style={this.state.PayStyle} onChange={evt => this.chgMoneyFldVal(evt)} onFocus={evt => this.focusPayAmount(evt)} onBlur={evt => this.blurPayAmount(evt)} type='tel' className="editMoneyInputBS" />
                  : <span>{moneyFormat(this.state.AmountOwingWithTip)}</span>}*/}
              </div>}
            {/*SessionInfo.AllowPartial === true ?
                  <span>{moneyFormat(this.state.AmountOwingWithTip)}</span> :
                  <input value={this.moneyFormat(this.state.AmountOwingWithTip)} style={this.state.PayStyle} name="AmountOwingWithTip" readOnly={true} className="editMoneyInputROB" />*/}
            <div className="boxREV25">
            </div>
            {/*----  Buttons ----*/}
            <div id="div1" className="listREVContainer5">
              <div className="buttonLeft">
                <Tooltip position="right" anchorElement="pointer">
                  <Button icon="track-changes-accept" className="smallButton" title="Pay Order" onClick={this.onPay}>&nbsp;PAY BY CARD</Button>
                </Tooltip>
              </div>
            </div>
            <div id="div1" className="listREVContainer6">
              {/*----  Buttons ----*/}
              <div className="editFieldT">
                {(SessionInfo.AppCfg.HasGiftCards || SessionInfo.AppCfg.HasAccountInfo || SessionInfo.AppCfg.HasCoupons) === true ?
                  <div className="buttonLeft">
                    <Tooltip position="top" anchorElement="pointer">
                      <Button icon="media-manager" className="smallButton" title="Back to Order" onClick={this.onPayGiftCard}>&nbsp;GIFT CARD&nbsp;</Button>
                    </Tooltip>
                  </div> : null}
                {SessionInfo.AppCfg.HasCashPayment === true ?
                  <div className="buttonMidRight">
                    <Tooltip position="right" anchorElement="pointer">
                      <Button icon="currency" className="smallButton" title="Pay Order" onClick={this.onPayCash}>&nbsp;PAY CASH&nbsp;</Button>
                    </Tooltip>
                  </div> : null}
              </div>
            </div>
            <div id="div1" className="listREVContainer7">
              {SessionInfo.AppCfg.HasGooglePay === true ?
                <GooglePayButton environment="TEST" buttonLocale="ca" buttonType="pay" buttonSizeMode="fill" buttonRadius="10"
                  paymentRequest={{
                    "apiVersion": 2,
                    "apiVersionMinor": 0,
                    "merchantInfo": {
                      "merchantName": "Example Merchant"
                    },
                    "offerInfo": {
                      "offers": [
                        {
                          "redemptionCode": "exampleCode",
                          "description": "example description of offer"
                        }
                      ]
                    },
                    "allowedPaymentMethods": [
                      {
                        "type": "CARD",
                        "parameters": {
                          "allowedAuthMethods": ["PAN_ONLY","CRYPTOGRAM_3DS"],
                          "allowedCardNetworks": ["AMEX","DISCOVER","INTERAC","JCB","MASTERCARD","VISA"]
                        },
                        "tokenizationSpecification": {
                          "type": "PAYMENT_GATEWAY",
                          "parameters": {
                            "gateway": "example",
                            "gatewayMerchantId": "exampleGatewayMerchantId"
                          }
                        }
                      }
                    ],
                    "transactionInfo": {
                      "totalPriceStatus": "FINAL",
                      "totalPrice": this.state.ActualPaymentAmount,
                      "currencyCode": "CAD"
                    }
                  }}
                  onLoadPaymentData={(e) => { console.log(`Amount: ${this.state.ActualPaymentAmount}`); this.googlePayProc(e) }}
                /> : null}
            </div>
          </div >
        </Popup>
        {/* Gift Card QR Code Popup */}
        <Popup offset={this.showScannerOffset} show={this.state.showScanQRCode} popupClass={'getQRPopup'} >
          <div className="getQR">
            <div className="barcodeScannerBox">
              <video id="scannerVideo" name="videoScan" width="300px" height="300px" margin='1' padding='1' style={{ border: '1px solid gray',}} />
            </div>
            <div className="scanTitleDiv">
              <span style={{ fontSize: 19,color: 'blue',fontWeight: "bolder" }} >{this.state.ScanTitle}</span>
            </div>
            <Button className="mediumButton" style={{ left: '100px',right: '100px',top: '20px' }} onClick={this.cancelQR}>Cancel QR</Button>
          </div>
        </Popup>

        <br />
      </div>
    );
  }
}

export default withRouter(CheckoutPage);